import { useEffect, useState } from 'react';
import { StripCTA, StripOfferIcon } from '../../Common';
import { getThemeColor } from 'src/utils/getThemeColor';
import TabsContainer from '../../TabsContainer';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import { useDispatch, useSelector } from 'react-redux';
import { applyPromo } from 'src/redux/actions';
import { Snackbar, snackbarContentClasses } from '@mui/material';
import Transition from 'src/components/BasicModal/Transition';
import { RootState } from 'src/redux/reducers';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

export const SectionHeader = ({
  activeTabIndex,
  setActiveTabIndex,
  tabsData,
  hasPaymentOffers = false,
  isCouponApplied = false,
  UnopenedTabText = '',
  openDropDown = false,
  handleDropDown = null,
}) => {
  const showTabs = IS_DESKTOP || openDropDown;

  return (
    (IS_DESKTOP || !isCouponApplied) && (
      <div className={`${showTabs ? 'border-b-grey1' : ''} tw-flex tw-flex-col`}>
        <div
          className="tw-flex tw-items-center tw-justify-between tw-px-[16px] tw-py-[22px] md:tw-px-[18px]"
          onClick={() => !IS_DESKTOP && handleDropDown?.()}
        >
          <div className="tw-flex tw-items-center tw-gap-[10px] tw-text-[16px]/[20px] tw-font-semibold md:tw-text-[14px]/[17px] md:tw-font-medium">
            {IS_DESKTOP && <StripOfferIcon iconSize={18} customClasses="tw-flex" />}
            Available offers
          </div>
          {!IS_DESKTOP && (
            <div className="tw-flex tw-items-center tw-gap-[12px]">
              {UnopenedTabText && !openDropDown && (
                <div className="tw-rounded-[4px] tw-bg-[#D6F4DF] tw-px-[7px] tw-py-[4px] tw-text-[14px]/[17px] tw-text-[#017424]">
                  {UnopenedTabText}
                </div>
              )}
              {openDropDown ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </div>
          )}
        </div>
        {showTabs && hasPaymentOffers && !isCouponApplied && (
          <TabsContainer
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={setActiveTabIndex}
            tabsData={tabsData}
          />
        )}
      </div>
    )
  );
};

export const CustomPromo = ({
  cartData,
  storeId,
  setSuccessPopupState,
  showAuthModal,
}) => {
  const dispatch = useDispatch();
  const [manualPromoCode, setManualPromoCode] = useState('');
  const [errorText, setErrorText] = useState('');
  const { isUserLoggedIn, latestSyncedCart } = useSelector((state: RootState) => ({
    isUserLoggedIn: state.userReducer.isLogin,
    latestSyncedCart: state.commonReducer.latestSyncedCart,
  }));

  useEffect(() => {
    errorText && setErrorText('');
  }, [latestSyncedCart]);

  const onApplyCustomPromo = () => {
    if (!isUserLoggedIn) {
      showAuthModal?.();
      return;
    }
    const payload = {
      cart: {
        ...cartData,
        store_id: storeId,
        store_offer: {
          ...(cartData.store_offer || {}),
          promo_code: manualPromoCode,
        },
      },
    };

    dispatch(
      applyPromo(payload, (resp) => {
        if (resp.data.status) {
          const errorText = resp.data.data?.reason;
          if (!errorText) {
            setSuccessPopupState({
              show: true,
              appliedPromoCode: manualPromoCode,
            });
          } else {
            setErrorText(errorText);
          }
        } else {
          setErrorText('Invalid coupon');
        }
      })
    );
  };
  return (
    <div>
      <div className="tw-flex tw-gap-[24px] tw-rounded-[8px] tw-border tw-border-solid tw-border-[#B4B4B4] tw-p-[16px]">
        <input
          value={manualPromoCode}
          onChange={(e) => {
            errorText && setErrorText('');
            setManualPromoCode(e.target.value);
          }}
          className="tw-flex-1 tw-border-none tw-text-[14px]/[17px] placeholder:tw-text-[#00000099]"
          placeholder="Enter Coupon Code"
        />
        <StripCTA
          ctaTextOrIcon="Apply"
          onClick={onApplyCustomPromo}
          isDisabled={!manualPromoCode || !!errorText}
        />
      </div>
      {!!errorText && (
        <p className="tw-m-0 tw-mt-[8px] tw-text-[14px]/[17px] tw-text-[#F63D60]">
          {errorText}
        </p>
      )}
    </div>
  );
};

export const ExpandOrShrinkButton = ({ show, showAllPromos, setShowAllPromos }) => {
  const themeColor = getThemeColor();
  return (
    show && (
      <p
        className="tw-m-0 tw-cursor-pointer tw-text-center tw-text-[16px]/[20px] tw-font-semibold md:tw-text-left md:tw-text-[15px]/[20px]"
        style={{ color: themeColor }}
        onClick={() => setShowAllPromos(!showAllPromos)}
      >
        View {showAllPromos ? 'less' : 'all'} offers
      </p>
    )
  );
};

export const SuccessMessage = ({ show, message, onClose }) => {
  return (
    <Snackbar
      open={show}
      onClose={onClose}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: IS_DESKTOP ? 'left' : 'center',
      }}
      message={message}
      sx={{
        [`& .${snackbarContentClasses.root}`]: {
          minWidth: 'auto',
          padding: '0px 0px',
          backgroundColor: '#24AC21',
        },
        [`& .${snackbarContentClasses.message}`]: {
          width: '100%',
          padding: '16px 18px',
          borderRadius: '6px',
        },
      }}
      TransitionComponent={Transition}
      transitionDuration={100}
    />
  );
};
