import { useWidgetDndContextData } from '@/context/WidgetDndContext';
import { LogoRenderer } from './components/LogoRenderer';
import { NavLinkRenderer } from './components/NavLinkRenderer';
import { NavIconRenderer } from './components/NavIconRenderer';
// import { cloneDeep } from "lodash";
import { DynamicComponent } from './components/dynamicComponent';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { hexToRgba } from '@/components/DesignSystem/ModernTheme/utils/common';
import { NewHeaderContext } from './newHeaderContext';
import { RenderFullSearch } from './components/RenderFullSearch';
import { twMerge } from 'tailwind-merge';
import { useGetDeviceType } from '@/hooks/useGetDeviceType';
import { logoSectionConfigDetails } from './utils';
import { useDispatch } from 'react-redux';
import { getNavigationPagesList } from 'src/redux/actions';
import { useSSRSelector } from 'src/redux';
import { IS_SERVER } from 'src/utils/checkRenderEnv';

const ABOVE_NAVLINKS_TYPE = ['header3', 'header4'];
const LOGO_CONFIG_DESKTOP = ['header1', 'header4', 'header5'];
const LOGO_CONFIG_MOBILE = ['header1', 'header4'];
const NAV_CONFIG_DESKTOP = ['header2', 'header6'];
const NAV_CONFIG_MOBILE = ['header2', 'header5', 'header6'];

export const NewHeaderComponent = (props: any) => {
  const { isScroll, isBuilder = false, isHomePage } = props;
  const {
    widgetContextActions: { setActiveHeaderData },
    getWidgetSSRState,
  } = useWidgetDndContextData();

  const { storeData, navigationList } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    navigationList: state.additionalPagesReducer.navigationList,
  }));

  const headerRef = useRef();
  const [isScrollable, setIsScrollable] = useState(isScroll);
  const [showSearch, setShowSearch] = useState(false);
  const [headerNodeRef, setHeaderNodeRef] = useState(null);
  const [isHeaderHovered, setIsHeaderHovered] = useState(false);
  const { deviceType } = useGetDeviceType();
  const isMobile = deviceType === 'mobile';
  const { activeHeaderData } = getWidgetSSRState();

  const dispatch = useDispatch();

  if (IS_SERVER && props?.config) {
    activeHeaderData.headerPropsConfig = props.config;
  }

  useEffect(() => {
    if (props?.config) {
      setActiveHeaderData((prevData) => ({
        ...prevData,
        headerPropsConfig: props.config,
      }));
    }
  }, [props?.config]);

  useEffect(() => {
    if (storeData?.store_id && !navigationList?.length) {
      dispatch(
        getNavigationPagesList({
          storeId: storeData?.store_id,
          isPublished: true,
        })
      );
    }
  }, [storeData?.store_id, navigationList]);

  useLayoutEffect(() => {
    if (props?.config) {
      setActiveHeaderData((prevData) => ({
        ...prevData,
        headerPropsConfig: props.config,
      }));
    }
  }, [props?.config]);

  useEffect(() => {
    setIsScrollable(isScroll);
  }, [isScroll]);

  useEffect(() => {
    setShowSearch(false);
  }, [activeHeaderData?.headerPropsConfig?.desktopHeader]);

  const headerWrapRef = useCallback((node) => {
    if (!node) {
      return;
    }
    setHeaderNodeRef(node);
  }, []);

  if (!Object.keys(activeHeaderData?.headerPropsConfig)?.length) {
    return null;
  }

  const generateHeaderDataAccToType = (headerName: string, headerPropsConfig: any) => {
    switch (headerName) {
      case 'header1': {
        const { logo_section, navLinks_section, navIcons_section, dynamic_section } =
          headerPropsConfig;
        logo_section.customClass = 'tw-justify-left';
        navLinks_section.base_config.type = 'flatElement';
        navLinks_section.base_config.customClass = 'tw-w-[100%]';
        dynamic_section.base_config.defaultPosition = 'right';
        return {
          row1: [logo_section, navLinks_section, navIcons_section],
          row2: [],
        };
      }
      case 'header2': {
        const { logo_section, navLinks_section, navIcons_section, dynamic_section } =
          headerPropsConfig;
        logo_section.customClass = 'tw-justify-center';
        navLinks_section.base_config.type = 'flatElement';
        navLinks_section.base_config.customClass = 'tw-w-max';
        navLinks_section.base_config.defaultPosition = 'left';
        dynamic_section.base_config.defaultPosition = 'right';

        return {
          row1: [navLinks_section, logo_section, navIcons_section],
          row2: [],
        };
      }
      case 'header3': {
        const { logo_section, navLinks_section, navIcons_section, dynamic_section } =
          headerPropsConfig;
        logo_section.customClass =
          dynamic_section?.base_config?.defaultPosition === 'left'
            ? `tw-w-[calc(50%+25px)] tw-justify-center`
            : 'tw-w-[calc(62%+25px)] tw-justify-center';
        navLinks_section.base_config.type = 'flatElement';
        navLinks_section.base_config.customClass = 'tw-w-[100%]';
        navLinks_section.base_config.defaultPosition = 'center';
        dynamic_section.base_config.defaultPosition =
          dynamic_section.base_config.defaultPosition === 'center'
            ? 'right'
            : dynamic_section.base_config.defaultPosition;

        return {
          row1: [dynamic_section, logo_section, navIcons_section],
          row2: [navLinks_section],
        };
      }
      case 'header4': {
        const { logo_section, navLinks_section, navIcons_section, dynamic_section } =
          headerPropsConfig;
        logo_section.customClass = 'tw-justify-start'; // tw-w-[50%]
        navLinks_section.base_config.type = 'flatElement';
        navLinks_section.base_config.defaultPosition = 'center';
        navLinks_section.base_config.customClass = 'tw-w-[100%]';
        navLinks_section.base_config.defaultPosition = 'center';
        dynamic_section.base_config.defaultPosition =
          dynamic_section.base_config.defaultPosition === 'left'
            ? 'right'
            : dynamic_section.base_config.defaultPosition;

        return {
          row1:
            dynamic_section.base_config.defaultPosition === 'center'
              ? [logo_section, dynamic_section, navIcons_section]
              : [logo_section, navIcons_section],
          row2: [navLinks_section],
        };
      }
      case 'header5': {
        const { logo_section, navLinks_section, navIcons_section, dynamic_section } =
          headerPropsConfig;
        logo_section.customClass = isMobile ? '' : 'tw-relative tw-left-[30px]';
        navLinks_section.base_config.type = 'hamburger';
        navLinks_section.base_config.customClass = 'tw-w-max';
        navLinks_section.base_config.defaultPosition =
          navLinks_section.base_config.defaultPosition;
        dynamic_section.base_config.defaultPosition = isMobile ? 'right' : 'right';
        dynamic_section.base_config.defaultType = isMobile
          ? 'icon'
          : dynamic_section.base_config.defaultType;
        dynamic_section.base_config.showSearch = dynamic_section.base_config.showSearch;
        // dynamic_section.base_config.defaultPosition = isMobile ? 'icon' : dynamic_section.base_config.defaultPosition
        // dynamic_section.base_config.showSearch = isMobile ? true : dynamic_section.base_config.showSearch

        return {
          row1: [navLinks_section, logo_section, navIcons_section],
          row2: [],
        };
      }
      case 'header6': {
        const { logo_section, navLinks_section, navIcons_section, dynamic_section } =
          headerPropsConfig;
        // logo_section.customClass = dynamic_section?.base_config?.defaultPosition === 'left' ? `tw-mr-[250px]` : 'tw-ml-[300px]';;
        logo_section.customClass = 'tw-justify-center';
        navLinks_section.base_config.type = 'hamburger';
        navLinks_section.base_config.customClass = 'tw-w-max';
        navLinks_section.base_config.defaultPosition =
          navLinks_section.base_config.defaultPosition;
        dynamic_section.base_config.defaultPosition = isMobile
          ? 'left'
          : dynamic_section.base_config.defaultPosition;
        dynamic_section.base_config.defaultType = isMobile
          ? 'icon'
          : dynamic_section.base_config.defaultType;
        dynamic_section.base_config.showSearch = dynamic_section.base_config.showSearch;
        // dynamic_section.base_config.defaultPosition = isMobile ? 'icon' : dynamic_section.base_config.defaultPosition
        // dynamic_section.base_config.showSearch = isMobile ? true : dynamic_section.base_config.showSearch

        return {
          row1: [navLinks_section, logo_section, navIcons_section],
          row2: [],
        };
      }
    }
  };

  const showDynamicSection = (type: string, base_config: any, headerName: string) => {
    switch (type) {
      case 'logo':
        return (
          (isMobile ? LOGO_CONFIG_MOBILE : LOGO_CONFIG_DESKTOP).includes(headerName) &&
          base_config.defaultPosition === 'left'
        );
      case 'link':
        return (
          (isMobile ? NAV_CONFIG_MOBILE : NAV_CONFIG_DESKTOP).includes(headerName) &&
          base_config.defaultPosition === 'left'
        );
      case 'icon':
        return base_config.defaultPosition === 'right';
    }
  };

  const getCurrentComponentName = (
    componentName: string,
    componentData: any,
    base_config: any,
    currentClass: any,
    globalClass: any,
    headerName: string = '',
    dynamicSectionConfig = {} as Record<string, string>,
    headerData = {},
    globalStyleObject = {}
  ) => {
    const isTransparent =
      globalClass?.staticClass?.base_config?.defaultFillColorType === 'transparent';
    switch (componentName) {
      case 'LogoRenderer': {
        return (
          <LogoRenderer
            activeHeaderData={activeHeaderData}
            currentClass={currentClass}
            globalClass={globalClass}
            showDynamicSection={showDynamicSection(
              'logo',
              dynamicSectionConfig,
              headerName
            )}
            isScroll={isScroll}
            globalStyleObject={globalStyleObject}
          />
        );
      }
      case 'NavLinkRenderer': {
        return (
          <NavLinkRenderer
            activeHeaderData={activeHeaderData}
            componentData={componentData}
            base_config={base_config}
            currentClass={isTransparent ? currentClass : globalClass?.staticClass}
            showHamburger={true}
            globalClass={globalClass}
            headerRef={headerRef}
            showDynamicSection={showDynamicSection(
              'link',
              dynamicSectionConfig,
              headerName
            )}
            headerNodeRef={headerNodeRef}
            headerData={headerData}
            headerName={headerName}
            dynamicSectionData={activeHeaderData.headerPropsConfig.dynamic_section}
            globalStyleObject={globalStyleObject}
            isScroll={isScroll}
            isHeaderHovered={isHeaderHovered}
            isMobile={isMobile}
            isBuilder={isBuilder}
          />
        );
      }
      case 'NavIconRenderer': {
        return (
          <NavIconRenderer
            componentData={componentData}
            base_config={base_config}
            currentClass={isTransparent ? currentClass : globalClass?.staticClass}
            globalClass={globalClass}
            showDynamicSection={showDynamicSection(
              'icon',
              dynamicSectionConfig,
              headerName
            )}
            globalStyleObject={globalStyleObject}
            activeHeaderData={activeHeaderData}
          />
        );
      }
      case 'dynamicComponent': {
        return (
          (dynamicSectionConfig.defaultPosition === 'left' ||
            headerName === 'header4') && (
            <DynamicComponent
              currentClass={isTransparent ? currentClass : globalClass?.staticClass}
              globalClass={globalClass}
              globalStyleObject={globalStyleObject}
            />
          )
        );
      }
    }
  };

  function getGlobalStyle(currentClass, staticClass: any = {}, globalClass) {
    const { base_config } = currentClass || {};
    const isTransparent =
      staticClass?.base_config?.defaultFillColorType === 'transparent' && isHomePage;
    const { defaultBackgroundColor } = base_config || {};
    return {
      backgroundColor: !isTransparent
        ? staticClass?.base_config?.defaultBackgroundColor
        : isTransparent
          ? isScroll
            ? defaultBackgroundColor
            : isHeaderHovered
              ? globalClass?.scrollClass?.base_config?.defaultBackgroundColor
              : ''
          : '',
      borderBottom: staticClass?.base_config?.defaultSeparatorLine?.includes(
        'BELOW_HEADER'
      )
        ? `1px solid ${hexToRgba(staticClass?.base_config?.defaultSeparator1Color, staticClass?.base_config?.defaultSeparator1Opacity / 100)}`
        : '',
      borderRadius: isBuilder ? '18px 18px 0 0' : '',
    };
  }

  function getStyleForSeparator(currentClass) {
    const {
      base_config: { defaultSeparator2Color, defaultSeparator2Opacity },
    } = currentClass;
    return {
      borderTop: `1px solid ${hexToRgba(defaultSeparator2Color, defaultSeparator2Opacity / 100)}`,
    };
  }

  function getGlobalClassName(currentHeaderType: string): string {
    const navlinksPosition =
      activeHeaderData?.headerPropsConfig?.navLinks_section?.base_config?.defaultPosition;
    const searchInterfaceType =
      activeHeaderData?.headerPropsConfig?.dynamic_section?.base_config.defaultType;
    switch (currentHeaderType) {
      case 'header1': {
        return twMerge(
          ` [&>:first-child:nth-last-child(2)]:!tw-relative 
                 [&>:first-child:nth-last-child(2)]:!tw-left-[50%] 
                 [&>:first-child:nth-last-child(2)]:!tw-translate-x-[-50%] 
                 [&>:first-child:nth-last-child(2)]:!tw-justify-center`,
          navlinksPosition === 'left' &&
            '[&>*:nth-child(even)]:!tw-max-w-[70%] !tw-justify-start [&>:first-child]:!tw-flex-grow-0 [&>:last-child]:!tw-ml-auto [&>*:nth-child(even)]:!tw-flex-grow-0 [&>:last-child]:!tw-max-w-[50%]',
          navlinksPosition === 'right' &&
            '[&>*:nth-child(even)]:!tw-max-w-[70%] !tw-justify-start [&>:last-child]:!tw-flex-grow-0 [&>:nth-child(2)]:!tw-ml-auto [&>*:nth-child(even)]:!tw-flex-grow-0',
          navlinksPosition === 'right' &&
            searchInterfaceType === 'field' &&
            '[&>:last-child]:!tw-max-w-[48%]'
        );
      }
      case 'header2': {
        return twMerge(
          ' [&>*:nth-child(2)]:!tw-justify-center',
          // ` [&>:last-child]:!tw-overflow-hidden`,
          logoSectionConfigDetails(activeHeaderData, isScroll)?.hidden &&
            ' [&>:last-child]:!tw-max-w-[80%]'
        );
      }
      case 'header5': {
        return ` !tw-justify-start 
                 [&>*:nth-child(1)]:!tw-w-max 
                 [&>:first-child]:!tw-flex-grow-0 
                 [&>:last-child]:!tw-max-w-[50%]
                 [&>:last-child]:!tw-ml-auto
                  `;
      }
      case 'header4': {
        // [&>*:nth-child(odd)]:!tw-w-[24%]
        return ` [&>*:nth-child(odd)]:!tw-w-[45%] 
                 [&>*:nth-child(odd)]:!tw-flex-grow-0 
                 [&>*:nth-child(even)]:!tw-max-w-[50%]`;
      }
      case 'header3': {
        return `[&>:first-child:nth-last-child(2)]:!tw-relative 
                [&>:first-child:nth-last-child(2)]:!tw-left-[calc(50%_-_10px)] 
                [&>:first-child:nth-last-child(2)]:!tw-translate-x-[-50%] 
                [&>:first-child:nth-last-child(2)]:!tw-justify-center
                [&>*:nth-child(even)]:!tw-justify-center
                `;
      }
      case 'header6': {
        return `[&>*:nth-child(2)]:!tw-justify-center`;
      }
      default:
        return '';
    }
  }

  function renderCustomiseHeader() {
    const { headerPropsConfig = {} } = activeHeaderData;
    const { globalClass, desktopHeader, mobileHeader, dynamic_section } =
      headerPropsConfig;

    const currentHeaderType = isMobile ? mobileHeader : desktopHeader;
    const { staticClass, scrollClass } = globalClass || {};
    const headerData = generateHeaderDataAccToType(currentHeaderType, headerPropsConfig);
    const currentClass = isScrollable ? scrollClass : staticClass;
    const globalStyleObject = getGlobalStyle(currentClass, staticClass, globalClass);
    const styleForSeparator = getStyleForSeparator(staticClass);
    const wrapperClassName = getGlobalClassName(currentHeaderType);
    return (
      <NewHeaderContext.Provider value={{ showSearch, setShowSearch }}>
        <div
          onMouseEnter={() => setIsHeaderHovered(true)}
          onMouseLeave={() => setIsHeaderHovered(false)}
          style={globalStyleObject as any}
          ref={headerRef}
          className={twMerge(
            `tw-relative tw-flex tw-transition-all ${isMobile ? 'tw-min-h-[52px]' : 'tw-min-h-[68px]'} tw-w-[100%] tw-flex-col tw-border-[1px] tw-border-[#111C361F]`
          )}
        >
          <div
            className={twMerge(
              `tw-flex tw-w-[100%] tw-items-center tw-justify-between tw-gap-[12px] ${!isMobile ? 'tw-px-[40px]' : 'tw-px-[16px]'} ${ABOVE_NAVLINKS_TYPE?.includes(currentHeaderType) ? 'tw-py-[16px]' : isMobile ? 'tw-py-[14px]' : 'tw-py-[20px]'}`,
              // Odd siblings
              ' [&>*:nth-child(odd)]:!tw-flex-grow [&>:last-child]:tw-max-w-[calc(50%_-_18%)]', // [&>*:nth-child(odd)]:!tw-max-w-[calc(50%_-_18%)]
              // Even siblings
              '[&>*:nth-child(even)]:!tw-flex-grow',
              // Even siblings
              !isMobile
                ? `[&>*:nth-child(even)]:tw-max-w-[33%] `
                : '[&>*:nth-child(even)]:tw-max-w-[54%]',
              // For last elements /3, not consequential to other layout siblings
              '[&>:last-child]:!tw-flex [&>:last-child]:!tw-justify-end',
              // For first element /3, not consequential to other layout siblings
              '[&>:first-child]:!tw-flex [&>:first-child]:!tw-justify-start',
              (() => {
                if (
                  currentHeaderType === 'header3' &&
                  dynamic_section?.base_config?.showSearch &&
                  dynamic_section?.base_config?.defaultPosition !== 'center'
                ) {
                  return '[&>:first-child]:!tw-max-w-[calc(50%_-_18%)]';
                }
                if (currentHeaderType !== 'header4' && currentHeaderType !== 'header3') {
                  return '[&>:first-child]:!tw-max-w-[calc(50%_-_18%)]';
                }
              })(),
              currentHeaderType !== 'header2' &&
                ' [&>:first-child]:!tw-overflow-x-hidden',
              // currentHeaderType !== 'header2' &&
              //   currentHeaderType !== 'header1' &&
              //   ' [&>:last-child]:!tw-overflow-x-hidden',
              currentHeaderType == 'header1' && ' [&>:first-child]:!tw-overflow-hidden',
              wrapperClassName
            )}
          >
            {headerData?.row1?.map(
              ({ componentName, componentData = [], base_config = {} }) => {
                return getCurrentComponentName(
                  componentName,
                  componentData,
                  base_config,
                  currentClass,
                  globalClass,
                  currentHeaderType,
                  headerPropsConfig?.dynamic_section?.base_config,
                  headerData,
                  globalStyleObject
                );
              }
            )}
          </div>
          <div className="tw-relative">
            {staticClass.base_config.defaultSeparatorLine?.includes('ABOVE_NAVLINKS') &&
              ABOVE_NAVLINKS_TYPE.includes(currentHeaderType) && (
                <div
                  style={styleForSeparator}
                  className="tw-absolute tw-mb-[50px] tw-w-[100%]"
                ></div>
              )}
          </div>
          {headerData?.row2?.length > 0 && (
            <div className="tw-px-[40px]">
              <div className="tw-mt-[0px] tw-w-[100%]">
                {headerData?.row2?.map(
                  ({ componentName, componentData, base_config }) => {
                    return getCurrentComponentName(
                      componentName,
                      componentData,
                      base_config,
                      currentClass,
                      globalClass,
                      currentHeaderType,
                      globalStyleObject
                    );
                  }
                )}
              </div>
            </div>
          )}
        </div>
        <div>
          {showSearch &&
            dynamic_section.base_config.defaultType !== 'field' &&
            !isMobile && (
              <RenderFullSearch
                activeHeaderData={activeHeaderData}
                currentClass={currentClass}
                isBuilder={isBuilder}
              />
            )}
        </div>
      </NewHeaderContext.Provider>
    );
  }

  return (
    <header className="tw-relative" ref={headerWrapRef}>
      {renderCustomiseHeader()}
    </header>
  );
};
