import React, { FunctionComponent } from 'react';
import { StoreDetails } from '@/redux/reducers';
import { useSSRSelector } from '@/redux/ssrStore';
import { DEFAULT_SSR_IMAGE_URL } from '@/utils/constants/images';

interface RichSnippetStoreDetailsProps {
  store: StoreDetails;
}
export const RichSnippetStoreDetails: FunctionComponent<RichSnippetStoreDetailsProps> = (
  props
) => {
  const storeFromSelector = useSSRSelector((state) => state.storeReducer.store);
  const store = props.store || storeFromSelector;

  const storeData = {
    logo_image: store?.logo_image || DEFAULT_SSR_IMAGE_URL,
    store_url: store?.store_url,
    description: store?.description || '',
    store_name: store?.store_name,
  };

  const hasValidStoreData = storeData.logo_image && storeData.store_url;
  return hasValidStoreData ? (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'https://www.schema.org',
          '@type': 'OnlineStore',
          logo: storeData.logo_image,
          url: storeData.store_url,
          description: storeData.description,
          name: storeData.store_name,
        }),
      }}
    />
  ) : null;
};
