import React from 'react';
import CustomizedTooltips from '../Tooltip';
import { TextField } from '@mui/material';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';

interface Props {
  textBoxLabel?: string;
  placeholder: string;
  handleChange: any;
  customClass?: string;
  parentClass: string; // e.g. w-[100%], it will be a tailwind class
  value: string;
  rows?: number;
  regexCheck?: any;
  errorMessage?: string;
  isMandatory?: boolean;
  maxLength?: number;
  buttonConfig?: any;
  isDisabled?: boolean;
  iconClass?: string;
  showErrorBox?: boolean;
  isIconPositionRight?: boolean;
  subDescription?: string;
  handleFocusOut?: (e: any) => void;
  labelInfoIcon?: { show: boolean; description: HTMLElement };
  showCharLimit?: boolean;
  onIconClick?: any;
  hideBorder?: boolean;
  hideInputPadding?: boolean;
  isFocus?: boolean;
  inputColor?: string;
  isCapitalize?: boolean;
  iconComponent?: () => JSX.Element;
  borderColor?: string;
  rightIconCompo?: () => JSX.Element;
  customPadding?: string;
  borderRadiusClass?: Record<string, string>;
  inputType?: React.HTMLInputTypeAttribute;
}

const CustomTextField = (props: Props) => {
  const {
    textBoxLabel,
    placeholder,
    handleChange,
    customClass = '',
    parentClass,
    value = '',
    rows = 1,
    regexCheck = '',
    errorMessage = '',
    isMandatory = false,
    maxLength = 100,
    buttonConfig = {},
    isDisabled = false,
    iconClass = '',
    onIconClick,
    showErrorBox = true,
    isIconPositionRight = false,
    subDescription = '',
    labelInfoIcon = { show: false, description: <div></div> },
    handleFocusOut,
    showCharLimit = false,
    hideBorder = false,
    hideInputPadding = false,
    isFocus = false,
    inputColor = '',
    isCapitalize = true,
    iconComponent,
    borderColor,
    rightIconCompo,
    customPadding,
    inputType,
  } = props;
  const {
    widgetContextState: { globalStyle },
  } = useWidgetDndContextData();

  function getInputBoxBorderClass() {
    if (buttonConfig.left && buttonConfig.right)
      return 'tw-rounded-[0px] tw-bg-[#fff] tw-text-center';
    else if (buttonConfig.left) return 'tw-rounded-r-lg tw-bg-[#fff] tw-text-center';
    else if (buttonConfig.right) return 'tw-rounded-l-lg tw-bg-[#fff] tw-text-center';
    return 'tw-rounded-[6px]';
  }

  const handleInputValue = (e: any) => {
    if (regexCheck) {
      handleChange(regexCheck(e.target.value));
    } else {
      handleChange(e.target.value);
    }
  };

  return (
    <section className={`${parentClass} ${isDisabled && 'tw-pointer-events-none'}`}>
      {textBoxLabel ? (
        <div className="tw-mb-10">
          <div className="tw-flex tw-items-center tw-justify-between">
            <p
              className={`tw-my-[0px] tw-text-14 tw-font-medium ${isCapitalize ? 'tw-capitalize' : ''} tw-text-newBlack`}
            >
              {textBoxLabel} {isMandatory && <span className="tw-text-[#9A3131]">*</span>}
            </p>
            {labelInfoIcon?.show && (
              <CustomizedTooltips title={labelInfoIcon?.description}>
                <div className="wb-sprite ic-info-grey"></div>
              </CustomizedTooltips>
            )}
            {showCharLimit && (
              <div className="tw-text-12 tw-font-normal tw-text-newBlack tw-opacity-60">
                {value?.length}/{maxLength}
              </div>
            )}
          </div>
          {subDescription ? (
            <p className="tw-mb-[0px] tw-mt-[6px] tw-text-12 tw-font-normal tw-capitalize tw-text-newBlack tw-opacity-60">
              {subDescription}
            </p>
          ) : null}
        </div>
      ) : null}
      <div className="tw-flex">
        {buttonConfig.left ? (
          <button
            onClick={() => buttonConfig.left?.onClick && buttonConfig.left?.onClick()}
            className={`tw-bg-[#fafafa] tw-py-8 ${buttonConfig.left?.customClass} tw-flex tw-items-center tw-justify-center tw-rounded-l-lg tw-border-y-[1px] tw-border-l-[1px] tw-border-[#EBEBEB] tw-text-16`}
          >
            {buttonConfig.left?.textHtml ? '' : buttonConfig.left.text}
          </button>
        ) : null}
        <div
          className="tw-relative tw-flex tw-grow tw-rounded-[6px]"
          style={{
            border: !hideBorder
              ? `1px solid ${borderColor || 'rgba(17, 28, 54, 0.12)'}`
              : '',
            ...(globalStyle?.inputs?.isEnabled
              ? {
                  borderRadius: globalStyle?.inputs?.style?.borderRadius?.replace(
                    '!important',
                    ''
                  ),
                }
              : {}),
          }}
        >
          {(iconClass && !isIconPositionRight) || iconComponent ? (
            iconClass ? (
              <div
                onClick={onIconClick}
                className={
                  `rista-sprite ${iconClass} tw-absolute tw-left-[10px] tw-top-1/2 tw--translate-y-1/2 ` +
                  (onIconClick ? 'tw-cursor-pointer' : '')
                }
              ></div>
            ) : (
              iconComponent()
            )
          ) : null}
          <TextField
            id="filled-multiline-flexible"
            placeholder={!value && placeholder}
            type={inputType}
            multiline
            autoFocus={isFocus}
            onBlur={handleFocusOut}
            value={value || ''}
            inputProps={{
              style: { fontSize: 15 },
              maxLength: maxLength,
              color: inputColor,
            }}
            rows={rows}
            onChange={(e) => handleInputValue(e)}
            sx={{
              wordBreak: 'keep-all',
              whiteSpace: 'pre-wrap',
              padding: '0.6px',
              boxShadow: 'none',
              '.MuiInputBase-root': {
                padding: hideInputPadding ? '0px' : customPadding || '12px',
                color: inputColor?.length ? inputColor : '',
                border: 'none',
                '&:hover': {
                  boxShadow: 'none',
                  border: 'none',
                },
                '& fieldset': {
                  boxShadow: 'none',
                  border: 'none',
                },
                '&:hover fieldset': {
                  boxShadow: 'none',
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  boxShadow: 'none',
                  border: 'none',
                },
                ' .MuiInputBase-input': {
                  width: isIconPositionRight ? 'calc(100% - 20px)' : '100%',
                  boxShadow: 'none',
                  border: 'none',
                },
                '.MuiOutlinedInput-notchedOutline': {
                  border: hideBorder ? 'none' : '',
                },
              },
            }}
            className={`tw-relative tw-z-10 tw-w-full tw-flex-grow tw-shadow-none ${
              iconClass ? (isIconPositionRight ? 'tw-mr-36' : 'tw-ml-36') : ''
            } ${customClass} tw-resize-none ${
              errorMessage ? 'tw-border-[#9A3131]' : 'tw-border-borderGrey'
            } tw-border-[1px]  tw-font-normal tw-text-newBlack
              ${getInputBoxBorderClass()} tw-overflow-hidden tw-text-ellipsis tw-whitespace-break-spaces `} // hover:tw-shadow-cust2
          />
          {/* <textarea
            name="Text1"
            rows={rows}
            className={`tw-w-full ${
              iconClass ? (isIconPositionRight ? 'tw-pr-36' : 'tw-pl-36') : ''
            } ${customClass} tw-resize-none ${
              errorMessage ? 'tw-border-[#9A3131]' : 'tw-border-borderGrey'
            } tw-border-[1px] tw-text-15 tw-font-normal tw-text-newBlack
              ${getInputBoxBorderClass()} tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-p-12 hover:tw-shadow-cust2`}
            placeholder={placeholder}
            onChange={(e) => handleInputValue(e)}
            value={value || ''}
            maxLength={maxLength}
            onBlur={handleFocusOut}
          ></textarea> */}
          {(iconClass && isIconPositionRight) || rightIconCompo ? (
            iconClass ? (
              <div
                onClick={onIconClick}
                className={
                  `wb-sprite ${iconClass} tw-absolute tw-right-[10px] tw-top-1/2 tw--translate-y-1/2 ` +
                  (onIconClick ? 'tw-cursor-pointer' : '')
                }
              ></div>
            ) : (
              rightIconCompo()
            )
          ) : null}
        </div>
        {buttonConfig.right ? (
          <button
            onClick={() => buttonConfig.right?.onClick && buttonConfig.right?.onClick()}
            className={`tw-bg-[#fafafa] tw-py-8 ${buttonConfig.right?.customClass} tw-flex tw-items-center tw-justify-center tw-rounded-r-lg tw-border-y-[1px] tw-border-r-[1px] tw-border-[#EBEBEB] tw-text-16`}
          >
            {buttonConfig.right?.textHtml ? '' : buttonConfig.right.text}
          </button>
        ) : null}
      </div>

      {showErrorBox ? (
        <p className="tw-mt-4 tw-h-[18px] tw-text-12 tw-font-normal tw-text-[#9A3131]">
          {errorMessage ? errorMessage : ''}
        </p>
      ) : null}
    </section>
  );
};

export default CustomTextField;
