import React from 'react';

export function SEOTags({ productData }) {
  return (
    <>
      {productData?.brand_name && (
        <span rel="schema:brand">
          <span typeof="schema:Brand">
            <meta property="schema:name" content={productData?.brand_name} />
          </span>
        </span>
      )}
      {productData?.barcode && (
        <meta property="schema:gtin" content={productData?.barcode} />
      )}
      <meta property="schema:sku" content={`${productData?.sku_id || productData?.id}`} />
      <meta property="schema:productID" content={`${productData?.id}`} />
    </>
  );
}
