import { toastNotifyError } from '@/components/Common/Toast';
import axios from 'axios';
import { ENDPOINT } from 'src/config/endpoints';
import { getAdditionalPage } from '../../../../src/services';
import { getWidgetType } from '../utils/common';
import { ADMIN_WIDGETS } from 'src/constants/widgets';

const isProdEnv = () => {
  return (
    process.env.NEXT_PUBLIC_CUSTOM_ENV === 'DEV' ||
    process.env.NEXT_PUBLIC_CUSTOM_ENV === 'STAGE-1' ||
    process.env.NEXT_PUBLIC_CUSTOM_ENV === 'STAGE-2'
  );
};

export const saveWidgetDnDData = (
  storeId: number,
  storeThemeId: number,
  widgetData: Record<string, any>,
  authToken: string | string[],
  hasAdminAccess: boolean,
  widgetId?: string
) => {
  const config = {
    headers: {
      auth_token: authToken,
      app_os: 'cfe',
      app_version: '0.1.0',
      'Content-Type': 'application/json',
    },
  };

  if (widgetId) {
    const data = {
      store_id: storeId,
      widget: {
        id: widgetId,
        template: JSON.stringify(widgetData),
      },
    };

    return axios.put(ENDPOINT.STORE.updateThemeWidget, data, config);
  } else {
    const data = {
      store_id: storeId,
      store_theme_id: storeThemeId,
      widgets: [
        {
          type: 18,
          component_type: 'Body',
          ordering: 4,
          // widget_template_id: '63db40fb30ceabf0796737cf',
          widget_template_id: isProdEnv()
            ? '63db40fb30ceabf0796737cf'
            : '63e0fe18c0d21e0a530c29fe',
          template: JSON.stringify(widgetData),
        },
      ],
    };
    return axios.post(ENDPOINT.STORE.addThemeWidget, data, config);
  }
};

export const getWidgetData = async (widgetId: string) => {
  const widgetResponse = axios.get(
    ENDPOINT.ADDITIONAL_PAGES.getStaticWidgetDetails(widgetId)
  );
  const widgetJsonString = await widgetResponse.then((response) => response.data.data);
  return JSON.parse(widgetJsonString);
};

export const getAdditionalPageData = async (slug: string, storeId: number) => {
  const additionalPageResponse = await getAdditionalPage(slug, storeId, false, undefined);

  if (additionalPageResponse?.data?.status) {
    return additionalPageResponse.data.page || null;
  }
  return;
};

interface ISaveNewWidgetDndHeaderFooter {
  storeInfo: any;
  widgetProps: Record<string, any>;
  widgetType: string;
  authToken: string | string[];
  platform?: 'desktop' | 'mobile';
}

export const saveNewWidgetDnDHeaderFooter = ({
  storeInfo,
  widgetProps,
  widgetType,
  authToken,
  platform,
}: ISaveNewWidgetDndHeaderFooter) => {
  const storeId = storeInfo.store_id;
  const storeThemeId = storeInfo.theme.store_theme_id;

  const config = {
    headers: {
      auth_token: authToken,
      app_os: 'cfe',
      app_version: '0.1.0',
      'Content-Type': 'application/json',
    },
  };

  const widgetId = widgetProps.widget_id;
  // Case of Widget Updation
  if (widgetId) {
    const data = {
      store_id: storeId,
      widget: {
        component_type: widgetType,
        ordering: 0,
        name:
          widgetType === 'Footer' ? ADMIN_WIDGETS.FOOTER.name : ADMIN_WIDGETS.HEADER.name,
        id: widgetId,
        platform,
        type: getWidgetType(widgetProps?.sub_type),
        config: widgetProps,
        sub_type: widgetProps?.sub_type,
      },
    };
    return axios.put(ENDPOINT.STORE.updateThemeWidget, data, config);
  } else {
    // Case of widget addition - in case of header footer although this wouldn't be called as when theme gets activated, we get a header footer by default. But still keeping this code as fallback.
    const data = {
      store_id: storeId,
      store_theme_id: storeThemeId,
      widgets: [
        {
          type: widgetType === 'Footer' ? 6 : 5,
          component_type: widgetType,
          ordering: 0,
          name:
            widgetType === 'Footer'
              ? ADMIN_WIDGETS.FOOTER.name
              : ADMIN_WIDGETS.HEADER.name,
          config: {
            platform,
            ...widgetProps,
          },
        },
      ],
    };
    return axios.post(ENDPOINT.STORE.addThemeWidget, data, config);
  }
};

export const saveWidgetDnDHeaderFooter = (
  storeId: number,
  storeThemeId: number,
  widgetProps: Record<string, any>,
  widgetType: string,
  authToken: string | string[],
  hasAdminAccess: boolean
) => {
  const config = {
    headers: {
      auth_token: authToken,
      app_os: 'cfe',
      app_version: '0.1.0',
      'Content-Type': 'application/json',
    },
  };

  const widgetId = widgetProps.widget_id;
  // Case of Widget Updation
  if (widgetId) {
    const data = {
      store_id: storeId,
      widget: {
        ...widgetProps,
        id: widgetId,
        type: getWidgetType(widgetProps?.sub_type),
      },
    };
    return axios.put(ENDPOINT.STORE.updateThemeWidget, data, config);
  } else {
    // Case of widget addition - in case of header footer although this wouldn't be called as when theme gets activated, we get a header footer by default. But still keeping this code as fallback.
    const data = {
      store_id: storeId,
      store_theme_id: storeThemeId,
      widgets: [
        {
          type: widgetType === 'Footer' ? 6 : 5,
          component_type: widgetType,
          ordering: 0,
          name: widgetProps?.name,
        },
      ],
    };
    return axios.post(ENDPOINT.STORE.addThemeWidget, data, config);
  }
};

export const uploadMediaToS3Handler = async ({
  payload,
  authToken,
  callback,
  type,
}: any) => {
  try {
    const forVideo = type === 'video';
    const config = {
      headers: {
        auth_token: authToken,
        app_os: 'cfe',
        app_version: '0.1.0',
        'Content-Type': !forVideo ? 'application/json' : 'multipart/form-data',
      },
    };
    const response = await axios.post(
      ENDPOINT.LOGS[!forVideo ? 'UPLOAD_IMAGE_TO_S3' : 'UPLOAD_MEDIA_TO_S3'],
      payload,
      config
    );
    if (response && response?.data?.status) {
      const imageURL = JSON.parse(response?.data?.data);
      callback && callback(imageURL);
      return imageURL;
    } else {
      toastNotifyError(response?.data?.message || 'Something went wrong!');
      callback && callback({ error: true });
      return { error: true, errorMesage: '' };
    }
  } catch (errorMesage) {
    console.log('errorMesage:  ', errorMesage);
    callback && callback({ error: true });
    return { error: true, errorMesage: '' };
    toastNotifyError('Error uploading data!!');
  }
};
