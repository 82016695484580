import checkIfThemePreview from './checkIfThemePreview';
import { ADDRESS_VISIBILITY } from './constants';
import { getDecryptedContactInfo } from './getDecryptedContactInfo';

const DEFAULT_PHONE = '01244570330';
const DEFAULT_EMAIL = 'abc@xyz.com';
const DEFAULT_ADDRESS =
  '8th Floor, Tower-D, UNITECH CYBER PARK, Durga Colony, Sector 39, Gurugram, Haryana 122003';

const isPreview = checkIfThemePreview();

const getFullStoreAddress = (address) => {
  return (
    address &&
    Object.keys(address).length > 0 &&
    `${address?.address_1 !== '' ? address?.address_1 : ''}${
      address?.city !== '' ? `, ${address?.city}` : ''
    }${address?.state !== '' ? `, ${address?.state}` : ''}${
      address?.pincode !== '' ? `, ${address?.pincode}` : ''
    }`
  );
};

export const getVisibleStoreAddressToShow = (storeData, isFullAddress) => {
  const isAddressVisible = storeData?.address?.visibility !== ADDRESS_VISIBILITY?.HIDDEN;

  const storeActualAddress = isFullAddress
    ? getFullStoreAddress(storeData?.address)
    : storeData?.address?.address_1;

  if (isPreview) {
    return storeActualAddress || DEFAULT_ADDRESS;
  } else {
    return isAddressVisible ? storeActualAddress : '';
  }
};

export const getVisibleStorePhone = (storeData) => {
  const ownerPhone = storeData?.owner?.phone
    ? getDecryptedContactInfo(storeData?.owner?.phone)
    : '';
  const displayPhone = storeData?.display_phone || '';
  const storeActualPhone = displayPhone || ownerPhone;
  return storeActualPhone ? storeActualPhone : isPreview ? DEFAULT_PHONE : '';
};

export const getVisibleStoreEmail = (storeData) => {
  const storeAtualEmail = storeData?.owner?.email_id
    ? getDecryptedContactInfo(storeData?.owner?.email_id)
    : '';
  return storeAtualEmail || (isPreview ? DEFAULT_EMAIL : '');
};

const getDummyOrActualContactDetails = (storeData, isFullAddress = false) => {
  return {
    storeAddress: getVisibleStoreAddressToShow(storeData, isFullAddress),
    storePhone: getVisibleStorePhone(storeData),
    storeEmail: getVisibleStoreEmail(storeData),
  };
};

export default getDummyOrActualContactDetails;
