import { ChangeEvent, FunctionComponent, MouseEvent, useEffect, useRef, useState } from "react";
import { JsonFormBuilder } from "./JsonFormBuilder";
import { get } from 'lodash';
import { Button } from "@dotpe/kui";
// eslint-disable-next-line import/named
import Sortable, { SortableEvent } from "sortablejs";
import { JSON_FORM_ARRAY_CONFIG } from "../widgetDnDConstants";
import styles from './jsonFormBuilder.module.scss';
import { changeArrayIndex, getHash } from "../widgetDnDUtils";
import { WidgetIcon } from "@dotpe/kui/Icon";
import cn from 'classnames';

interface JsonFormBuilderArrayProps {
  widgetId: string;
  config: Record<string, any>;
  widgetProps: Record<string, any>;
  onChangeValues?: (path: string, newValues: Record<string, any>, e?: MouseEvent<HTMLButtonElement> | ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  ancestor?: string;
}

export const getRandomString = () => {
  return getHash(`${Date.now()}.${Math.random()}`);
};

export const JsonFormBuilderArray: FunctionComponent<JsonFormBuilderArrayProps> = (props) => {
  const widgetPropsValues = get(props.widgetProps, props.ancestor, []) as any[];
  const [values, setValues] = useState(widgetPropsValues);
  const formBuilderArrayRef = useRef<HTMLDivElement>(null);
  const formBuilderArraySortableRef = useRef<Sortable>(null);

  const addItem = () => {
    const newValues = [...values];
    newValues.push({_id: getRandomString()});
    setValues(newValues);
  };

  const removeItem = (index: number, e: MouseEvent<HTMLButtonElement>) => {
    const newValues = [ ...values ];
    newValues.splice(index, 1);
    setValues(newValues);
    props.onChangeValues(props.ancestor, newValues, e);
  };

  const handleChange = (changedValues: Record<string, any>, index: number, e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const subFormValues = get(changedValues, [props.ancestor, index].join('.'));
    const newValues = [...values];
    const oldValue = values[index];
    newValues[index] = {...oldValue, ...subFormValues};
    setValues(newValues);
    props.onChangeValues(props.ancestor, newValues, e);
  };

  const handleSort = (e: SortableEvent) => {
    const { oldIndex, newIndex } = e;
    const newValues = changeArrayIndex(values, oldIndex, newIndex);
    setValues(newValues);
    props.onChangeValues(props.ancestor, newValues);
  }

  useEffect(() => {
    if (formBuilderArrayRef.current) {
      formBuilderArraySortableRef.current = new Sortable(formBuilderArrayRef.current, JSON_FORM_ARRAY_CONFIG);
    }
  }, []);

  useEffect(() => {
    if (formBuilderArraySortableRef.current) {
      formBuilderArraySortableRef.current.option('onSort', handleSort);
    }
  }, [handleSort]);

  return <div className="jsonFormBuilderArray">
    <div ref={formBuilderArrayRef}>
      {values.map((val, index) => {
        const _id = val?._id || getHash(JSON.stringify(val) || '');
        const value = get(props.widgetProps, `${props.ancestor}.${index}`);
        return <div key={_id} className={styles.widgetFormGroupArrayItem}>
          <div className={cn('dragHandle', styles.dragHandle)}><WidgetIcon name="drag_handle" /></div>
          <JsonFormBuilder
            widgetId={props.widgetId}
            config={props.config}
            widgetProps={value}
            ancestor={`${props.ancestor}.${index}`}
            onChange={(val, e) => handleChange(val, index, e)}
          />
          <Button variant="tertiary" color="danger" onClick={(e) => removeItem(index, e)}>Delete</Button>
        </div>;
      })}
    </div>
    <Button variant="primary" color="primary" onClick={addItem}>Add</Button>
  </div>
}
