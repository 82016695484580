import React from 'react';
import StarIcon from '@mui/icons-material/Star';
import { ProductRating } from '../muiStyles';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { useFeatureLocks } from 'src/hooks/useFeatureLocks';
import { useSSRSelector } from 'src/redux';
import classNames from 'classnames';
import { getTagBorderRadius } from '../utils';

export function RatingComponent({
  productData,
  insertMarginBottom = false,
  type,
  config = {} as any,
  overrideStyles = { textColor: '' },
}) {
  const ratingData = productData?.store_item_review_rating;
  const reviewSettings = useSSRSelector((state) => state.catalogReducer.reviewSettings);
  const { crrFeatureStatus } = useFeatureLocks();
  const overrideTextColor = overrideStyles?.textColor;

  const hasRating =
    !crrFeatureStatus?.isLocked &&
    reviewSettings?.status &&
    ratingData?.review_rating &&
    ratingData?.review_rating[0]?.avg_rating;

  if (!hasRating || !config?.showRatings) {
    return _RenderMargin();
  }

  // const checkShowForAvgRating =
  //   config?.minAvgRating && ratingData?.review_rating[0]?.avg_rating >= 3.5;
  // const checkForAtleastMinNum =
  //   config?.minRating &&
  //   (ratingData?.review_rating[0]?.ratings_count || ratingData?.ratings_count) >= 3;

  // if (
  //   config?.ratingAppearance === 'avgRating' &&
  //   (!checkShowForAvgRating || !checkForAtleastMinNum)
  // ) {
  //   return null;
  // }
  const borderRadiusStyle = getTagBorderRadius(config?.ratingShape);

  function _RenderMargin() {
    return <>{insertMarginBottom ? <div className="tw-mb-[12px]" /> : null}</>;
  }

  let Component = null;

  switch (config?.ratingAppearance) {
    case 'avgRating':
      Component = (
        <div
          className={classNames(
            'tw-flex tw-w-max tw-items-center tw-gap-[2px] tw-px-[4px] tw-py-[3px]',
            borderRadiusStyle.className
          )}
          style={{
            border: '0.6px solid #D2D9E4',
            background: config?.backgroundColor,
          }}
        >
          <div className="tw-flex tw-h-[10px] tw-items-center">
            <StarIcon
              sx={{
                color: config?.starColor || '#219653',
                fontSize: '12px',
              }}
            />
          </div>
          <p
            className="tw-m-0 tw-inline-flex tw-items-center tw-text-[11px] tw-font-semibold tw-leading-[1]"
            style={{
              color: config?.textColor,
            }}
          >
            {ratingData?.review_rating?.[0]?.avg_rating}
            {config?.showReviewCount ? (
              <span
                className={classNames(
                  'tw-font-semibold',
                  config?.reviewCountAppearance === 'bracket' && 'tw-ml-[2px]'
                )}
                style={{
                  color: config?.reviewCountColor,
                }}
              >
                {config?.reviewCountAppearance !== 'bracket' ? (
                  <>
                    <span className="tw-color-[#666666] tw-relative tw-top-[-0.529px] tw-mx-[4px] tw-text-[9px] tw-font-normal">
                      |
                    </span>
                    <span>{ratingData?.ratings_count}</span>
                  </>
                ) : (
                  <>({ratingData?.ratings_count})</>
                )}
              </span>
            ) : null}
          </p>
        </div>
      );
      break;
    case 'fiveStar':
      Component = (
        <div className="tw-relative tw-left-[-2.9px] tw-top-[-1.9px] tw-flex tw-items-center">
          <div className="ratingReviewComponent tw-flex tw-h-[12px] tw-items-start">
            <ProductRating
              name="customized-color"
              value={ratingData?.review_rating?.[0]?.avg_rating}
              sx={{
                color: config?.starColor || '#FFC107',
              }}
              precision={0.1}
              emptyIconColor={overrideTextColor && 'rgba(255,255,255,0.6)'}
              readOnly
              icon={<StarRoundedIcon fontSize="inherit" />}
              emptyIcon={<StarRoundedIcon fontSize="inherit" />}
            />
            {config?.showReviewCount ? (
              <span
                className={classNames(
                  'tw-relative tw-flex tw-items-center tw-text-[13px] tw-font-normal tw-leading-[1]',
                  config?.reviewCountAppearance === 'bracket'
                    ? 'tw-bottom-[-2px] tw-ml-[4px]'
                    : 'tw-bottom-[-1.497px]'
                )}
                style={{
                  color: overrideTextColor
                    ? 'rgba(255,255,255,0.7)'
                    : config?.reviewCountColor,
                }}
              >
                {config?.reviewCountAppearance !== 'bracket' ? (
                  <>
                    <span className="tw-color-[#666666] tw-relative tw-mx-[4px] tw-text-[14px] tw-font-normal tw-leading-[1]">
                      |
                    </span>
                    <span>{ratingData?.ratings_count}</span>
                  </>
                ) : (
                  <>({ratingData?.ratings_count})</>
                )}
              </span>
            ) : null}
          </div>
        </div>
      );
      break;
  }

  return (
    <>
      {Component}
      {_RenderMargin()}
    </>
  );
}
