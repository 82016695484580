import { useContext, useEffect } from 'react';
import BasicModal from '../BasicModal';
import { CartContext } from 'src/containers/Cart';
import { getThemeColor } from 'src/utils/getThemeColor';

const CartItemDeletionAlert = () => {
  const { deletionAlertState, setDeletionAlertState } = useContext(CartContext);
  const themeColor = getThemeColor() || '#000000';

  useEffect(() => {
    return () => onClose();
  }, []);

  function onClose() {
    setDeletionAlertState?.({
      show: false,
      callback: null,
    });
  }

  function onConfirm() {
    deletionAlertState?.callback?.();
    onClose();
  }

  return (
    <BasicModal
      show={true}
      onClose={onClose}
      heading="Remove Item"
      headingClasses="!tw-border-none !tw-pt-[32px] !tw-px-[16px] md:!tw-px-[24px]"
      customClasses="!tw-max-w-[440px] !tw-min-w-[300px]"
    >
      <div className="tw-flex tw-flex-col tw-gap-[24px] tw-px-[16px] tw-pb-[22px] md:tw-px-[24px]">
        <p className="tw-m-0 tw-text-sm tw-text-[#606060]">
          Are you sure you want to remove this item from the cart? This will also remove
          any associated coupon applied on this item.
        </p>
        <div className="tw-flex tw-justify-end tw-gap-[16px]">
          <button
            onClick={onClose}
            className="tw-cursor-pointer tw-rounded-lg tw-border tw-border-solid !tw-border-[#E3E3E3] tw-bg-transparent tw-p-[12px]"
            style={{
              color: themeColor,
            }}
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="tw-cursor-pointer tw-rounded-lg tw-border tw-border-solid tw-border-[#FF0000] tw-bg-transparent tw-p-[12px] tw-text-[#FF0000]"
          >
            Remove Item
          </button>
        </div>
      </div>
    </BasicModal>
  );
};

export default CartItemDeletionAlert;
