export const ADMIN_WIDGETS = {
  HEADER: { type: 'NewHeader', label: 'Header', name: 'NewHeader' },
  FOOTER: { type: 'NewAdminFooter', label: 'Header', name: 'NewAdminFooter' },
  HERO_BANNER: {
    type: 'NewHeroBanner',
    name: 'NewHeroBanner',
    label: 'Hero Banner',
  },
  RICH_TEXT_WITH_IMAGE: {
    type: 'RichTextWithImage',
    name: 'RichTextWithImage',
    label: 'Rich text with image',
  },
  SLIDESHOW: {
    type: 'Slideshow',
    name: 'Slideshow',
    label: 'Slideshow',
  },
  RICH_TEXT: {
    type: 'RichText',
    name: 'RichText',
    label: 'Rich text',
  },
  COLLECTIONS: {
    type: 'Collections',
    name: 'Collections',
    label: 'Collections',
  },
  INSTAGRAM_EMBED: {
    type: 'InstagramEmbed',
    name: 'InstagramEmbed',
    label: 'InstagramEmbed',
  },
  FEATURED_REELS: {
    type: 'FeaturedReels',
    name: 'FeaturedReels',
    label: 'FeaturedReels',
  },
  FEATURED_COLLECTIONS: {
    type: 'FeaturedCollection',
    name: 'FeaturedCollection',
    label: 'Featured Collection',
  },
  FEATURED_TAGS: {
    type: 'FeaturedTag',
    name: 'FeaturedTag',
    label: 'Featured Tag',
  },
  CATEGORY: {
    type: 'Category',
    name: 'Category',
    label: 'Category',
  },
  MULTI_COLUMN_WITH_ICON: {
    type: 'MultiColumnWithIcon',
    name: 'MultiColumnWithIcon',
    label: 'Multi column with icon',
  },
  MULTI_COLUMN_WITH_IMAGE: {
    type: 'MultiColumnWithImage',
    name: 'MultiColumnWithImage',
    label: 'Multi column with image',
  },
  LOGO_SLIDER: {
    type: 'LogoSlider',
    name: 'LogoSlider',
    label: 'Logo slider',
  },
  BLOG_WIDGET: {
    type: 'NewBlog',
    name: 'NewBlog',
    label: 'New blog',
  },
};
