import { FunctionComponent, MouseEventHandler, PropsWithChildren } from "react";
import styles from './widgetFrame.module.scss';
import cn from 'classnames';
import { WidgetIcon } from "@dotpe/kui/Icon";

interface WidgetFrameProps {
  name?: string;
  widgetId?: string;
  alwaysVisible?: boolean;
  onDelete?: MouseEventHandler<HTMLSpanElement>;
  onSelect?: MouseEventHandler<HTMLSpanElement>;
  onMove?: (widgetId: string, direction: string) => void;
  siblingCount?: number;
  index?: number;
  widget?: any;
  isHeaderFocused?: boolean;
}
export const WidgetFrame: FunctionComponent<PropsWithChildren<WidgetFrameProps>> = (
  props
) => {
  const { widget, isHeaderFocused } = props;
  
  const shouldDisableWidget = () => {
    return props.name === 'Header' || props.name === 'Footer';
  };
  return (
    <div
      className={cn(styles.widgetFrameContainer, {
        [styles.alwaysVisible]: props.alwaysVisible,
      })}
    >
      <div
        className={cn(styles.widgetHandle, styles.centerHandle)}
        style={isHeaderFocused ? { opacity: 1 } : {}}
      >
        {/* {props.name && <div className={styles.widgetName} onClick={props.onSelect}>{props.name}</div>} */}
        {/* <WidgetIcon title="Move" cursor="move" className={cn(styles.widgetHandleIcon, styles.dragIndicator, 'drag-handle')} name="drag_indicator" /> */}
        <WidgetIcon
          title="Move Up"
          cursor="n-resize"
          className={cn(styles.widgetHandleIcon, {
            [styles.disabled]: props.index === 0 || shouldDisableWidget(),
          })}
          name="keyboard_arrow_up"
          onClick={() => props.onMove(props.widgetId, 'up')}
        />
        <WidgetIcon
          title="Move Down"
          cursor="s-resize"
          className={cn(styles.widgetHandleIcon, {
            [styles.disabled]:
              props.index === props.siblingCount - 1 || shouldDisableWidget(),
          })}
          name="keyboard_arrow_down"
          onClick={() => props.onMove(props.widgetId, 'down')}
        />
        <WidgetIcon
          title="Select"
          className={styles.widgetHandleIcon}
          name="edit"
          onClick={() =>  props.onSelect(widget)}
        />
        <WidgetIcon
          title="Delete"
          className={cn(
            styles.widgetHandleIcon,
            {
              [styles.disabled]: shouldDisableWidget(),
            },
            styles.delete
          )}
          name="delete"
          onClick={() => props.onDelete(widget)}
        />
      </div>
    </div>
  );
};
