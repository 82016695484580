import React, { useState } from 'react';
import { FALLBACK_COLOR } from '../../utils/common';
import ImageEnlarge from '../ImageEnlarge/ImageEnlarge';
import useWindowDimensions from '@/utils/getWindowDimension';

const GridComponent = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const { isDesktop } = useWindowDimensions();
  const hanleImageClick = (id) => {
    setSelectedImageIndex(id);
    if (isDesktop) {
      setOpenModal(true);
    }
  };
  const closeModal = () => setOpenModal(false);
  return (
    <>
      {props?.mediaContent?.content && (
        <div className="grid-container">
          {props?.mediaContent?.content.map(({ id, url, imgText, image_href }, idx) =>
            url.length > 0 ? (
              <div className="grid-item" key={idx}>
                <img
                  src={url}
                  alt={`Image ${idx + 1}`}
                  onError={(e) => (e.target.src = url)}
                  onClick={() => hanleImageClick(idx)}
                />
                {
                  <p className="image-text" style={{ height: '24px' }}>
                    {imgText?.length ? imgText : ''}
                  </p>
                }
              </div>
            ) : (
              <div
                key={idx}
                style={{
                  aspectRatio: `${props?.aspectRatio}`,
                  background: `${FALLBACK_COLOR}`,
                }}
              ></div>
            )
          )}
        </div>
      )}
      {openModal ? (
        <ImageEnlarge
          closeModal={closeModal}
          media={props?.mediaContent?.content}
          selectedIndex={selectedImageIndex}
          tabIndex={0}
        />
      ) : null}
    </>
  );
};

export default GridComponent;
