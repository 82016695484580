import classNames from 'classnames';
import React from 'react';

export function OutOfStockStrip({ isMobile, isVisible, isFullWidthCard }) {
  if (!isVisible) {
    return null;
  }
  return (
    <div
      className={classNames(
        isMobile ? 'tw-h-[40px]' : 'tw-h-[44px]',
        'tw-absolute tw-bottom-0 tw-left-0 tw-z-[5] tw-flex tw-w-full tw-items-center tw-justify-center tw-bg-[#ffffff]/[0.9] tw-text-[13px] tw-font-medium tw-text-[#445378]',
        isFullWidthCard &&
          'removeGlobalOverride imgWrapperGlobalStyle globalImgStyleConsumer addTopLeftGlobalRadius addTopRightGlobalRadius'
      )}
    >
      Currently unavailable
    </div>
  );
}
