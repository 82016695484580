import React, { forwardRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import CustomizedButton from '@/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/Button';
import { CURRENT_CURRENCY_SYMBOL } from '@/config/paymentLinks';
import { formatPriceToINR } from '@/utils/formatPricing';
import buttonAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentButtonAnimationStyle.module.scss';
import { getDiscountPercentage } from '@/utils/getDiscountPercentage';
import { getButtonVariantsData } from '../../Carousel/utils';
import { BUTTON_TYPE } from '@/components/WidgetMaker/utils/buttonConstant';
import { useItemClick } from '@/hooks/useItemClick';
import {
  getMappedProductCasing,
  getMappedproductsTextSize,
  getMappedProductTextAlign,
  getMappedProductTextThickness,
} from './utils';
import { hexToRgba } from '@/components/DesignSystem/ModernTheme/utils/common';
import { deviceWidth } from '@/utils/deviceWidth';
import { REEL_CONFIGS } from './Reels';
import contentAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentAnimationStyles.module.scss';
import { getCurrentClass } from '../../CardFrameCollection/utils';
import { GlobalCfeLoader } from '@/components/WidgetMaker/WidgetDnD/GlobalCfeLoader';
import { useWidgetDndContextData } from '@/context/WidgetDndContext';
import { useLoadVisibleReel } from '../hooks/useLoadVisibleReel';

export const ReelsVideoSection = forwardRef<Record<number, HTMLVideoElement>, any>(
  function Reels(props, videoRef) {
    const {
      reelItem,
      reelIndex,
      onClick,
      fromModal = false,
      isModalOpen,
      customStyle = {},
      modalScrolled,
      scrollContainerRef = 0,
      callback,
      isMobile,
      oneGridCellGeometry,
      isBuilder,
      isThisReelFocused,
      cardFrameConfig,
      isLastReel,
      shouldMuteVideo,
      setShouldMuteVideo,
      isMobileFocusPlayEnabled = false,
    } = props;
    const {
      widgetContextState: { globalStyle },
    } = useWidgetDndContextData();
    const mappedProduct = reelItem?.mappedProduct?.[0];
    const containerWidth = !isBuilder ? scrollContainerRef?.clientWidth : deviceWidth;
    const modalAutoPlayConfig = fromModal ? isThisReelFocused : false;
    const layoutAutoPlayconfig = {
      all: cardFrameConfig?.reelDesktopBehaviour === 'autoPlayAll',
      sequential: cardFrameConfig?.reelDesktopBehaviour === 'autoplayOne',
      onHover: cardFrameConfig?.reelDesktopBehaviour === 'playOnHover',
    };
    const [autoPlayConfig, setAutoPlayConfig] = useState({
      play: false,
    });
    const [
      ,
      // loopNumber
      setLoopNumber,
    ] = useState(0);
    const [loadVideo, setLoadVideo] = useState(false);
    const [isVideoDownloaded, setIsVideoDownloaded] = useState(false);

    useLoadVisibleReel({ videoRef, reelIndex, setLoadVideo, loadVideo });

    useEffect(() => {
      if (fromModal && !isThisReelFocused) {
        setLoopNumber(0);
      }
    }, [isThisReelFocused, fromModal]);

    // useEffect(() => {
    //   if (fromModal && loopNumber >= 1) {
    //     callback?.({ currentSelectedReelIdx: isLastReel ? 0 : reelIndex + 1 });
    //   }
    // }, [loopNumber, fromModal, isLastReel, reelIndex]);

    // For desktop this sets the current chosen index
    useEffect(() => {
      if (fromModal) {
        if (!isMobile) {
          const elementRect = videoRef[reelIndex]?.getBoundingClientRect();
          const elementPositionFromLeft = isThisReelFocused
            ? elementRect.left - 20
            : elementRect.left - 20;
          if (
            Math.floor(elementPositionFromLeft) === Math.floor(containerWidth / 2) ||
            Math.floor(elementPositionFromLeft) === Math.ceil(containerWidth / 2) ||
            Math.ceil(elementPositionFromLeft) === Math.floor(containerWidth / 2) ||
            Math.ceil(elementPositionFromLeft) === Math.ceil(containerWidth / 2)
          ) {
            callback?.({ currentSelectedReelIdx: reelIndex });
          }
        }
      }
    }, [modalScrolled]);

    useEffect(() => {
      if (fromModal && isModalOpen) {
        if (isThisReelFocused) {
          if (isMobile) {
            scrollContainerRef?.scrollTo({
              top: scrollContainerRef.clientHeight * reelIndex,
              behavior: 'instant',
            });
          } else {
            scrollContainerRef?.scrollTo({
              left:
                (REEL_CONFIGS.UNFOCUSED_DIMENSIONS.width +
                  REEL_CONFIGS.COLUMN_GAP_DESKTOP_MODAL) *
                reelIndex,
              behavior: 'instant',
            });
          }
          playVideo();
        } else {
          pauseVideo();
        }
      }

      if (!fromModal) {
        if (isModalOpen) {
          pauseVideo();
        } else {
          playVideo();
        }
      }
    }, [isThisReelFocused, scrollContainerRef, isModalOpen, fromModal]);

    useEffect(() => {
      if (fromModal) {
        setAutoPlayConfig({ play: modalAutoPlayConfig });
      } else {
        setAutoPlayConfig({
          play: !isModalOpen ? layoutAutoPlayconfig.all || isMobile : false,
        });
        if (!isModalOpen) {
          if (layoutAutoPlayconfig.all || isMobile) {
            if (!isMobileFocusPlayEnabled) playVideo();
            else isThisReelFocused ? playVideo() : pauseVideo();
          }
        } else {
          isThisReelFocused ? playVideo() : pauseVideo();
        }

        if (layoutAutoPlayconfig.onHover && !isMobile) {
          resetVideo();
          pauseVideo();
        }
      }
    }, [
      isModalOpen,
      ...(fromModal
        ? [modalAutoPlayConfig]
        : [
            isThisReelFocused,
            layoutAutoPlayconfig.all,
            layoutAutoPlayconfig.onHover,
            isMobile,
            isMobileFocusPlayEnabled,
          ]),
    ]);

    function playVideo() {
      videoRef[reelIndex]?.play();
    }

    function pauseVideo() {
      videoRef[reelIndex]?.pause();
    }

    function resetVideo() {
      if (videoRef[reelIndex]) videoRef[reelIndex].currentTime = 0;
    }

    function muteStatus() {
      if (fromModal && isModalOpen && isThisReelFocused) return shouldMuteVideo;
      return true;
    }

    return (
      <div
        className={classNames(
          'tw-relative tw-flex-shrink-0 tw-overflow-hidden',
          !isThisReelFocused && 'tw-cursor-pointer'
        )}
        style={{
          width: `${oneGridCellGeometry?.width}px`,
          height: `${oneGridCellGeometry?.height}px`,
          ...customStyle,
        }}
        onMouseEnter={() => {
          if (layoutAutoPlayconfig.onHover && !fromModal) {
            playVideo();
          }
        }}
        onMouseLeave={() => {
          if (layoutAutoPlayconfig.onHover && !fromModal) {
            resetVideo();
            pauseVideo();
          }
        }}
        onClick={() => {
          if (fromModal && !autoPlayConfig.play) {
            setAutoPlayConfig({ play: !autoPlayConfig.play });
            // !shouldPlayVideo ? videoRef[reelIndex]?.play() : videoRef[reelIndex]?.pause();
          }
          onClick?.();
        }}
      >
        {!modalAutoPlayConfig && !!reelItem.thumbnail && (
          <div className="tw-absolute tw-inset-0 tw-z-[1] tw-h-full tw-w-full">
            <img
              src={reelItem.thumbnail}
              className="tw-h-full tw-w-full tw-object-cover"
            />
          </div>
        )}
        {!isVideoDownloaded && (
          <div className="tw-absolute tw-left-1/2 tw-top-1/2 tw-z-[4] -tw-translate-x-1/2 -tw-translate-y-1/2">
            <GlobalCfeLoader
              type={globalStyle?.appLoader?.loaderId || 'ellipsis'}
              color={globalStyle?.appLoader?.color}
            />
          </div>
        )}
        <video
          ref={(node) => (videoRef[reelIndex] = node)}
          className={classNames(
            'tw-h-full tw-w-full tw-bg-black tw-object-cover',
            !isMobile ||
              (!isMobile &&
                !fromModal &&
                cardFrameConfig?.hoverType !== 'zoomInCard' &&
                contentAnimationStyle[getCurrentClass(cardFrameConfig?.hoverType)])
          )}
          autoPlay={autoPlayConfig.play}
          loop
          muted={muteStatus()}
          playsInline={true}
          onLoadedData={() => setIsVideoDownloaded(true)}
          src={loadVideo ? reelItem.reelUrl : ''}
          onSeeked={() => {
            if (videoRef[reelIndex].currentTime === 0) {
              setLoopNumber((c) => c + 1);
            }
          }}
          onTimeUpdate={(e) => {
            if (!fromModal && !isModalOpen) {
              if (e.currentTarget.currentTime >= 4) {
                if (layoutAutoPlayconfig.all) {
                  resetVideo();
                  playVideo();
                }
                if (layoutAutoPlayconfig.sequential) {
                  callback?.({
                    currentSelectedReelIdx:
                      isLastReel || !reelIndex ? reelIndex : reelIndex + 1,
                  });
                }
              }
            }
          }}
        >
          {/* <source src={reelItem.reelUrl} type={getVideoParts(reelItem.reelUrl)?.mimeType} /> */}
        </video>
        {!!fromModal && isThisReelFocused && (
          <div
            onClick={() => setShouldMuteVideo((v) => !v)}
            className={classNames(
              'tw-pointer wb-sprite tw-absolute  tw-right-[12px] tw-z-[4]',
              !shouldMuteVideo ? 'mute-lg' : 'unmute-lg',
              mappedProduct ? 'tw-bottom-[126px]' : 'tw-bottom-[24px]'
            )}
          />
        )}
      </div>
    );
  }
);

export default function ReelsMetaData({
  reelItem,
  isMobile,
  fromModal = false,
  cardFrameConfig,
  layoutName = '',
  addPadding = true,
}) {
  const [onItemClick] = useItemClick();
  const mappedProduct = reelItem?.mappedProduct?.[0];
  const productNameSize = getMappedproductsTextSize(
    !isMobile ? cardFrameConfig?.productNameSize : cardFrameConfig?.productNameSizeMobile
  );
  const productPriceSize = getMappedproductsTextSize(
    !isMobile
      ? cardFrameConfig?.productPriceSize
      : cardFrameConfig?.productPriceSizeMobile
  );
  const productNameCasing = getMappedProductCasing(cardFrameConfig?.productNameTextCase);
  const productNameTextAlign = getMappedProductTextAlign(cardFrameConfig?.textAlign);
  const productNameThickness = getMappedProductTextThickness(
    cardFrameConfig?.productNameThincness
  );
  const productPriceThickness = getMappedProductTextThickness(
    cardFrameConfig?.productPriceThincness
  );
  const productPriceCasing = getMappedProductCasing(
    cardFrameConfig?.productPriceTextCase
  );
  const discount = getDiscountPercentage(
    mappedProduct?.price,
    mappedProduct?.discounted_price
  );

  const navigateToPdp = () => onItemClick(mappedProduct);

  // For inside the modal reels
  if (fromModal && mappedProduct) {
    return (
      <div className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-px-[8px] tw-pb-[8px]">
        <div
          className="imgWrapperGlobalStyle globalImgStyleConsumer tw-flex tw-cursor-pointer tw-items-center tw-gap-[12px] tw-bg-white/[0.7] tw-p-[12px]"
          onClick={navigateToPdp}
        >
          <div className="imgWrapperGlobalStyle globalImgStyleConsumer tw-h-[58px] tw-w-[58px] tw-flex-shrink-0 tw-border-[1.42px] tw-border-solid tw-border-[#ffffff]">
            <img src={mappedProduct?.image_url} className="tw-object-cover" />
          </div>
          <div className="tw-flex tw-flex-col">
            <p
              className="tw-m-0 tw-line-clamp-2 tw-break-words tw-leading-[16px]"
              style={{
                color: cardFrameConfig?.productNameColor,
                ...productNameSize,
                // ...productNameTextAlign,
                ...productNameThickness,
                ...productNameCasing,
              }}
            >
              {mappedProduct?.name}
            </p>
            <div
              className={classNames(
                'pricingSection tw-mt-[6px] tw-flex tw-flex-wrap tw-items-center tw-gap-x-[8px] tw-gap-y-[6px]'
              )}
            >
              <div className="pricingSection tw-flex tw-flex-wrap tw-items-end tw-gap-x-[4px] tw-gap-y-[6px]">
                <p
                  className="tw-relative tw-m-0 tw-text-[11.4px] tw-leading-[0.88] tw-text-newBlack"
                  // style={{color: overrideStyles?.textColor || config.productPriceColor,...productPriceStyles.style,}}
                >
                  <span property="schema:priceCurrency" content="INR">
                    {CURRENT_CURRENCY_SYMBOL}
                  </span>
                  <span property="schema:price">
                    {formatPriceToINR(mappedProduct?.discounted_price)}
                  </span>
                </p>
                {mappedProduct?.discounted_price !== mappedProduct?.price ? (
                  <p
                    // style={{ color: hexToRgba(overrideStyles?.textColor || config.productPriceColor,0.6), ...productActualPriceStyles.style,}}
                    className="tw-m-0 tw-text-[11.4px] tw-leading-[0.88] tw-text-newBlack/[0.6] tw-line-through"
                  >
                    {CURRENT_CURRENCY_SYMBOL}
                    {formatPriceToINR(mappedProduct?.price)}
                  </p>
                ) : null}
              </div>
              {!!discount && (
                <p
                  // style={{ background:config?.discountStyle !== 'text' && config?.backgroundColor,color: config?.textColor,}}
                  className={classNames(
                    'tw-m-0 tw-break-keep tw-px-[4px] tw-py-[2px] tw-text-center tw-text-[10.45px] tw-font-medium tw-leading-[1] ',
                    'tw-bg-[#2CA861] tw-text-[#ffffff]'
                  )}
                >
                  {`${discount}% OFF`}
                </p>
              )}
            </div>
          </div>
          {cardFrameConfig?.buttonConfig?.map((buttonData, btnIndex) => {
            // const overlayTextStyles: any = getOverlayTextStyles(cardFrameConfig, buttonData);
            const buttonVariant = getButtonVariantsData(buttonData, false);
            const selectedButtonType = buttonVariant[buttonData.type];
            // const isBtnSolid = buttonData.type === BUTTON_TYPE.SOLID;
            const isBtnTextual = buttonData.type === BUTTON_TYPE.TEXTUAL;

            return (
              <CustomizedButton
                key={btnIndex}
                btnType={buttonData.type}
                onClick={navigateToPdp}
                buttonData={buttonData}
                selectedButtonData={selectedButtonType}
                variant={selectedButtonType.variant}
                disableHoverAnimation={isMobile}
                className={classNames(
                  'addToCtaButtonForProduct !tw-pointer-events-auto !tw-ml-auto !tw-max-h-[28px] !tw-min-w-[auto] tw-cursor-pointer !tw-font-medium',
                  '!tw-w-max',
                  !isBtnTextual ? '!tw-p-[6px]' : '!tw-p-0',
                  // disable animations
                  !buttonData?.buttonAnimationConfig?.classKey
                    ? '[&_p]:before:!tw-hidden'
                    : !isMobile &&
                        buttonAnimationStyle[buttonData?.buttonAnimationConfig?.classKey]
                )}
                textWrapperClass={classNames(
                  '!tw-w-full !tw-text-[12px] !tw-relative !tw-flex !tw-justify-center tw-break-keep tw-whitespace-nowrap'
                  // textThicknessStyle.className
                )}
              >
                {buttonData?.text}
              </CustomizedButton>
            );
          })}
        </div>
      </div>
    );
  }

  // For widget layout reels
  switch (layoutName) {
    case 'layout2':
    case 'layout3':
      return (
        !!mappedProduct && (
          <div
            onClick={navigateToPdp}
            className={classNames(
              'removeGlobalOverride imgWrapperGlobalStyle addBottomLeftGlobalRadius addBottomRightGlobalRadius globalImgStyleConsumer tw-flex tw-w-full tw-flex-grow tw-cursor-pointer tw-flex-col tw-items-center tw-bg-[#ffffff]',
              addPadding &&
                (isMobile ? 'tw-px-[10px] tw-pb-[11px]' : 'tw-px-[14px] tw-pb-[15px]')
            )}
          >
            {layoutName !== 'layout3' && (
              <div
                onClick={navigateToPdp}
                className="imgWrapperGlobalStyle globalImgStyleConsumer tw-relative tw-top-[-40px] tw-h-[58px] tw-w-[58px] tw-cursor-pointer tw-border-[1.42px] tw-border-solid tw-border-[#ffffff]"
              >
                <img src={mappedProduct?.image_url} className="tw-object-cover" />
              </div>
            )}
            <div
              className={classNames(
                'tw-flex tw-w-full tw-flex-col',
                cardFrameConfig?.textAlign === 'center' && 'tw-items-center',
                layoutName !== 'layout3' ? 'tw-mt-[-30px]' : 'tw-mt-[16px]'
              )}
            >
              <p
                className="tw-m-0 tw-line-clamp-2 tw-leading-[20px]"
                style={{
                  color: cardFrameConfig?.productNameColor,
                  ...productNameSize,
                  ...productNameTextAlign,
                  ...productNameThickness,
                  ...productNameCasing,
                }}
              >
                {mappedProduct?.name}
              </p>
              <div
                className={classNames(
                  'pricingSection tw-mt-[8px] tw-flex tw-flex-wrap tw-items-center tw-gap-x-[8px] tw-gap-y-[6px]',
                  cardFrameConfig?.textAlign === 'center' && 'tw-justify-center'
                )}
              >
                <div className="pricingSection tw-flex tw-flex-wrap tw-items-end tw-gap-x-[4px] tw-gap-y-[6px]">
                  <p
                    className="tw-relative tw-bottom-[-0.5px] tw-m-0 tw-leading-[0.88]"
                    style={{
                      color: cardFrameConfig?.productPriceColor,
                      ...productPriceSize,
                      ...productNameTextAlign,
                      ...productPriceThickness,
                      ...productPriceCasing,
                      lineHeight: 0.88,
                    }}
                  >
                    <span property="schema:priceCurrency" content="INR">
                      {CURRENT_CURRENCY_SYMBOL}
                    </span>
                    <span property="schema:price">
                      {formatPriceToINR(mappedProduct?.discounted_price)}
                    </span>
                  </p>
                  {mappedProduct?.discounted_price !== mappedProduct?.price ? (
                    <p
                      style={{
                        color: hexToRgba(cardFrameConfig?.productPriceColor, 0.6),
                        ...productPriceSize,
                        ...productNameTextAlign,
                        ...productPriceThickness,
                        lineHeight: 0.88,
                      }}
                      className="tw-m-0 tw-text-[11.4px] tw-leading-[0.88] tw-text-newBlack/[0.6] tw-line-through"
                    >
                      {CURRENT_CURRENCY_SYMBOL}
                      {formatPriceToINR(mappedProduct?.price)}
                    </p>
                  ) : null}
                </div>
                {!!discount && (
                  <p
                    // style={{ background:config?.discountStyle !== 'text' && config?.backgroundColor,color: config?.textColor,}}
                    className={classNames(
                      'tw-m-0 tw-break-keep tw-px-[4px] tw-py-[2px] tw-text-center tw-text-[10.45px] tw-font-medium tw-leading-[1] ',
                      'tw-bg-[#2CA861] tw-text-[#ffffff]'
                    )}
                  >
                    {`${discount}% OFF`}
                  </p>
                )}
              </div>
            </div>
          </div>
        )
      );
    case 'layout4':
      if (reelItem?.description) {
        const descSize = getMappedproductsTextSize(
          !isMobile
            ? cardFrameConfig?.descriptionSize
            : cardFrameConfig?.descriptionSizeMobile
        );
        const descTextAlign = getMappedProductTextAlign(
          cardFrameConfig?.descriptionTextAlign
        );
        const descThickness = getMappedProductTextThickness(
          cardFrameConfig?.descriptionThicness
        );
        const descCasing = getMappedProductCasing(cardFrameConfig?.descriptionTextCase);

        return (
          <div className="tw-mt-[16px] tw-bg-[#FFFFFF]">
            <p
              className={classNames(
                'tw-m-0 tw-w-full',
                addPadding &&
                  (isMobile ? 'tw-px-[10px] tw-pb-[18px]' : 'tw-px-[14px] tw-pb-[15px]')
              )}
              style={{
                color: cardFrameConfig?.descriptionColor,
                ...descSize,
                ...descTextAlign,
                ...descThickness,
                ...descCasing,
              }}
            >
              {reelItem?.description}
            </p>
          </div>
        );
      } else return null;
    default:
      return null;
  }
}
