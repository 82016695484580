import { useEffect } from 'react';

export default function useDisableScroll({ isMobile, scrollRef }) {
  useEffect(() => {
    if (!isMobile && scrollRef) {
      const scrollLock = (event: WheelEvent) => {
        event.preventDefault();
        event.stopPropagation();
      };
      scrollRef.addEventListener('wheel', scrollLock);
      return () => {
        scrollRef.addEventListener('wheel', scrollLock);
      };
    }
  }, [scrollRef]);

  return null;
}
