import { MouseEvent } from 'react';
// eslint-disable-next-line import/named
import { SortableEvent } from 'sortablejs';
import { getWidgetList } from '../WidgetDeveloper/widgetDeveloperApi';
import { WidgetsProjectData } from '../WidgetDeveloper/widgetDeveloperTypes';
import { IconNames } from '@dotpe/kui/Icon';
import { getWidgetData } from './widgetDnDApi';
import { getDSDomain } from '@/utils/getShowroomUrlForStore';
import {
  WidgetOutlineAnnouncementBarIcon,
  WidgetOutlineBlogsIcon,
  WidgetOutlineCategoryIcon,
  WidgetOutlineCollectionIcon,
  WidgetOutlineFooterIcon,
  WidgetOutlineHeaderIcon,
  WidgetOutlineHeroBannerIcon,
  WidgetOutlineHtmlIcon,
  WidgetOutlineOfferIcon,
  WidgetOutlineTestimonialsIcon,
} from './commonSvgs';
import store from '@/redux/store';
import { getRegisteredWidget } from '@dotpe/kui/styles/registerWidgetForDnD';
import { ADMIN_WIDGETS } from 'src/constants/widgets';

export const range = (count: number, step = 1, start = 0) => {
  const arr = new Array(count);
  arr.fill(0);
  return arr.map((_item, index) => index * step + start);
};

export const performDeleteAnimation = (
  e: MouseEvent<HTMLSpanElement>,
  styles: Record<string, string>
) => {
  return new Promise((resolve) => {
    const widget = e.currentTarget.closest('[data-widget]') as HTMLDivElement;
    widget.style.maxHeight = `${widget.clientHeight}px`;

    setTimeout(() => {
      widget.classList.add('removingWidget');
    });

    setTimeout(() => {
      resolve(widget.id);
    }, 250);
  });
};

// cyrb53
export const getHash = (str: string, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed;
  let h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  const hash = 4294967296 * (2097151 & h2) + (h1 >>> 0);
  return hash.toString(16);
};

export const getUniqueIdForWidget = (e: SortableEvent) => {
  return `${e.type}-${e.timeStamp}-${Date.now()}-${e.oldIndex}-${
    e.newIndex
  }-${Math.random()}`;
};

export const addToObj = (obj: Record<string, any>, newProps: Record<string, any>) => {
  return { ...obj, ...newProps };
};

export const getAllWidgets = () => {
  return getWidgetList().then((widgetsListResponse) => {
    const widgetLibrary: Record<string, WidgetsProjectData> = {};
    widgetsListResponse.forEach((widget) => (widgetLibrary[widget.project] = widget));
    return widgetLibrary;
  });
};

export interface AddedWidgetData {
  name: string;
  type: 'template' | 'kui';
  options: Record<string, any>;
  icon?: IconNames;
  thumb?: string;
  manifest?: Record<string, any>;
}

export interface WidgetJSONData {
  id: string;
  name: string;
  props: Record<string, any>;
  children: string[];
  parent: string;
  /**
   * Order in elements, right now used only in insertion
   */
  insertIndex?: number;
}

const getChildColData = (e: SortableEvent, parent: string) => {
  const childColData: WidgetJSONData = {
    id: `col-${getHash(getUniqueIdForWidget(e))}`,
    name: 'col',
    props: {},
    children: [],
    parent: parent,
  };
  return childColData;
};

export const addWidgetToLayout = (
  e: SortableEvent,
  onAdd: (e: SortableEvent) => void
) => {
  const el = e.item;
  const selectedWidgetName = el.dataset.widget;
  const uniqueId = getHash(getUniqueIdForWidget(e));
  const widgetElId = `widget-${uniqueId}`;
  console.log(e);
  el.id = widgetElId;

  const parentWidget = e.to.closest('[data-widget]')?.id;
  // Did not used SSR here as callbacks are exectued client side
  const widgetTemplateLibraryMap =
    store.getState().jsonWidgetsReducer.widgetTemplateLibraryMap;
  const widgetData =
    widgetTemplateLibraryMap[selectedWidgetName] ||
    getRegisteredWidget(selectedWidgetName);
  // const widgetData = getRegisteredWidget(selectedWidgetName);
  let props: Record<string, any> = {};
  if (widgetData.type === 'template') {
    props = { name: widgetData.name, id: widgetElId };
  }
  if (widgetData.name === '2-col') {
    props = { columns: 2 };
  }

  const widgetJSONData: WidgetJSONData = {
    id: widgetElId,
    name: selectedWidgetName,
    props: props,
    children: [],
    parent: parentWidget,
    insertIndex: e.newIndex,
  };
  el.remove();

  if (widgetData.name === '2-col') {
    const widgetJSONArr = [widgetJSONData];
    range(props.columns).forEach(() => {
      const childColData = getChildColData(e, widgetElId);
      // widgetJSONData.children.push(childColData.id);
      widgetJSONArr.push(childColData);
    });

    return widgetJSONArr;
  }

  return widgetJSONData;
};

export const addWidgetToLayoutFromDrawer = (e: any, widget: any) => {
  const selectedWidgetName = widget?.name;
  const uniqueId = getHash(getUniqueIdForWidget(e));
  const widgetElId = `widget-${uniqueId}`;

  const parentWidget = 'page-builder';
  // Did not used SSR here as callbacks are exectued client side
  const widgetTemplateLibraryMap =
    store.getState().jsonWidgetsReducer.widgetTemplateLibraryMap;
  const widgetData =
    widgetTemplateLibraryMap[selectedWidgetName] ||
    getRegisteredWidget(selectedWidgetName);
  // const widgetData = getRegisteredWidget(selectedWidgetName);
  let props: Record<string, any> = widget?.options?.defaultProps || {};
  if (widgetData?.type === 'template') {
    props = { name: widgetData.name, id: widgetElId };
  }
  if (widgetData?.name === '2-col') {
    props = { columns: 2 };
  }

  const widgetJSONData: WidgetJSONData = {
    id: widgetElId,
    name: selectedWidgetName,
    props: props,
    children: [],
    parent: parentWidget,
    // insertIndex: e.newIndex,
  };

  if (widgetData?.name === '2-col') {
    const widgetJSONArr = [widgetJSONData];
    range(props.columns).forEach(() => {
      const childColData = getChildColData(e, widgetElId);
      // widgetJSONData.children.push(childColData.id);
      widgetJSONArr.push(childColData);
    });

    return widgetJSONArr;
  }

  return widgetJSONData;
};

export const changeArrayIndex = <T extends any = any>(
  arr: T[],
  fromIndex: number,
  toIndex: number
) => {
  const itemToMove = arr[fromIndex];
  const newArr = [...arr];

  newArr.splice(fromIndex, 1); // remove item fromIndex
  newArr.splice(toIndex, 0, itemToMove); // add item toIndex
  return newArr;
};

export const getJSONWidgetMetaFromStore = (storeReducer: Record<string, any>) => {
  const bodyComponents = storeReducer?.store?.theme?.components?.Body;
  const jsonWidgetData = bodyComponents.find(
    (component: any) => component.name === 'JsonPageWidget'
  );

  return jsonWidgetData;
};

export const getJSONWidgetDataFromStore = async (storeReducer: Record<string, any>) => {
  const jsonWidgetData = getJSONWidgetMetaFromStore(storeReducer);

  const jsonPageData = await getWidgetData(jsonWidgetData.widget_id);
  const template = JSON.parse(jsonPageData.template);

  return template;
};

const PAGE_JSON_LOCAL_STORAGE = 'pageLayoutJson';

export const saveLayoutLocally = (widgets: Record<string, WidgetJSONData>) => {
  const domain = getDSDomain();
  localStorage?.setItem(`${PAGE_JSON_LOCAL_STORAGE}_${domain}`, JSON.stringify(widgets));
};
export const saveLayoutHeaderLocally = (widgets) => {
  const domain = getDSDomain();
  localStorage?.setItem(
    `${PAGE_JSON_LOCAL_STORAGE}_${domain}_header`,
    JSON.stringify(widgets)
  );
};
export const saveLayoutFooterLocally = (widgets) => {
  const domain = getDSDomain();
  localStorage?.setItem(
    `${PAGE_JSON_LOCAL_STORAGE}_${domain}_footer`,
    JSON.stringify(widgets)
  );
};

export const getLayoutFromLocal = () => {
  const domain = getDSDomain();
  const pageLayoutJsonString = localStorage?.getItem(
    `${PAGE_JSON_LOCAL_STORAGE}_${domain}`
  );
  let pageLayoutJson;
  try {
    pageLayoutJson = JSON.parse(pageLayoutJsonString);
  } catch {
    pageLayoutJson = null;
  }
  return pageLayoutJson;
};

export const getHeaderFooterFromLocal = () => {
  const domain = getDSDomain();
  const headerComponent = localStorage?.getItem(
    `${PAGE_JSON_LOCAL_STORAGE}_${domain}_header`
  );
  const footerComponent = localStorage?.getItem(
    `${PAGE_JSON_LOCAL_STORAGE}_${domain}_footer`
  );

  return {
    header: JSON.parse(headerComponent) || null,
    footer: JSON.parse(footerComponent) || null,
  };
};

const PIPE = '│ ';
const JOIN = '├─';
const END = '└─';
const BLANK = '  ';
const _getStructure = (
  widgetId: string,
  allWidgets: Record<string, WidgetJSONData>,
  initialDepth = 0,
  isParentLastChild = false
): string => {
  const widget = allWidgets[widgetId];
  const childrenStructure = (widget.children || []).map((childId, index, arr) => {
    let indent = PIPE.repeat(initialDepth);

    // If parent is last child, we need to add 1 blank space as parent is terminated by └─ symbol
    if (isParentLastChild) {
      indent = PIPE.repeat(initialDepth - 1).concat(BLANK);
    }
    const isLastChild = index === arr.length - 1;
    const childStr = _getStructure(childId, allWidgets, initialDepth + 1, isLastChild);
    indent += isLastChild ? END : JOIN;
    return `${indent}${childStr}`;
  });
  const structure = [`${widget.name} #${widget.id}`, ...childrenStructure];
  return structure.join('\n');
};

export const getStructure = (widgetData: Record<string, WidgetJSONData>): string => {
  if (!widgetData) {
    return '';
  }
  const structure = _getStructure('page-builder', widgetData);
  return structure;
};

const getAllChildrenList = (
  widgetData: Record<string, WidgetJSONData>,
  root?: WidgetJSONData
) => {
  root = root || widgetData['page-builder'];
  const allChildren = new Set<string>();
  allChildren.add(root.id);
  root.children.forEach((childId) => allChildren.add(childId));
  root.children.forEach((childId) =>
    getAllChildrenList(widgetData, widgetData[childId]).forEach((childrenId) =>
      allChildren.add(childrenId)
    )
  );
  return Array.from(allChildren);
};

const getUnusedWidgets = (widgetData: Record<string, WidgetJSONData>) => {
  const allChildren = getAllChildrenList(widgetData);
  const widgetIds = Object.keys(widgetData);
  const unusedWidgets = widgetIds.filter((widgetId) => !allChildren.includes(widgetId));
  return unusedWidgets;
};

export const printDebug = (widget: Record<string, WidgetJSONData>) => {
  console.log(getStructure(widget));
  const unusedWidgets = getUnusedWidgets(widget);
  if (unusedWidgets.length) {
    console.log(`UNUSED WIDGETS: [${unusedWidgets.join(', ')}]`);
  }
};

export const getWidgetNameForWidgetOutline = (widgetDefaultName) => {
  switch (widgetDefaultName) {
    case 'SliderThumbnailsConditions':
      return 'Testimonials';
    case 'Carousel':
      return 'Carousel';
    case 'CategoryWidget':
      return 'Categories';
    case 'CollectionWidget':
      return 'Collections';
    case 'BlogContent':
      return 'Blogs';
    case 'ModernHeroBanner':
      return 'Banner';
    case 'ModernMiddleBodyGallery':
      return 'Gallery';
    case 'ModernMiddleBodyColumn':
      return 'Column';
    case 'ModernMiddleBodyTabs':
      return 'Tabs';
    case 'ModernMiddleBodyMaps':
      return 'Maps';
    case ADMIN_WIDGETS.HERO_BANNER.name:
      return ADMIN_WIDGETS.HERO_BANNER.label;
    case ADMIN_WIDGETS.RICH_TEXT_WITH_IMAGE.name:
      return ADMIN_WIDGETS.RICH_TEXT_WITH_IMAGE.label;
    case ADMIN_WIDGETS.RICH_TEXT.name:
      return ADMIN_WIDGETS.RICH_TEXT.label;
    default:
      return widgetDefaultName;
  }
};

export const getWidgetOutlineIconForWidgetOutline = (widgetDefaultName) => {
  switch (widgetDefaultName) {
    case 'Header':
      return <WidgetOutlineHeaderIcon />;
    case 'Announcement Bar':
      return <WidgetOutlineAnnouncementBarIcon />;
    case 'Carousel':
      return <WidgetOutlineHeroBannerIcon />;
    case 'Offers':
      return <WidgetOutlineOfferIcon />;
    case 'CategoryWidget':
      return <WidgetOutlineCategoryIcon />;
    case 'CollectionWidget':
      return <WidgetOutlineCollectionIcon />;
    case 'SliderThumbnailsConditions':
      return <WidgetOutlineTestimonialsIcon />;
    case 'BlogContent':
      return <WidgetOutlineBlogsIcon />;
    case 'Footer':
      return <WidgetOutlineFooterIcon />;
    case 'HTML':
      return <WidgetOutlineHtmlIcon />;
    default:
      return <WidgetOutlineHeaderIcon />;
  }
};

export const getBannerDesktopAndMobileImages = (
  theme: Record<string, any>,
  activeWidgetProps: any
) => {
  const bannerObjectResponse = {
    desktopImages: activeWidgetProps.images || [],
    mobileImages: activeWidgetProps.imagesMobile || [],
  };

  const tempBannerObjectResponse = {
    mobileImages: [],
    desktopImages: [],
  };

  // If activeWidgetProps already contains mobile and desktop images then return
  if (
    bannerObjectResponse.mobileImages?.length &&
    bannerObjectResponse.desktopImages?.length
  ) {
    return bannerObjectResponse;
  }

  if (!theme) {
    return bannerObjectResponse;
  }

  const bodyComponents = theme?.components?.Body;
  const heroBannerDataInTheme = bodyComponents.find(
    (component: any) => component.sub_type === 'HeroBanner'
  );

  if (!heroBannerDataInTheme) {
    return bannerObjectResponse;
  }

  for (const obj of heroBannerDataInTheme?.images) {
    if (obj.is_desktop) {
      obj.src = obj.image_url;
      tempBannerObjectResponse.desktopImages.push(obj);
    } else {
      obj.src = obj.image_url;
      tempBannerObjectResponse.mobileImages.push(obj);
    }
  }

  if (!bannerObjectResponse.desktopImages?.length) {
    bannerObjectResponse.desktopImages = tempBannerObjectResponse.desktopImages;
  }

  if (!bannerObjectResponse.mobileImages?.length) {
    bannerObjectResponse.mobileImages = tempBannerObjectResponse.mobileImages;
  }
  return bannerObjectResponse;
};
