/**
 * utility function to check for saved address of logged in user
 * @param {*} userAddress
 * @return {*} Object
 */
export const setUserSavedAddress = (userAddress) => {
  if (userAddress && Object.keys(userAddress).length) {
    return {
      ...userAddress,
      user_name: userAddress?.user_name?.text ?? userAddress?.user_name ?? '',
      landmark: userAddress?.landmark?.text ?? userAddress?.landmark ?? '',
      pincode: userAddress?.pincode?.text ?? userAddress?.pincode ?? '',
      city: userAddress?.city?.text ?? userAddress?.city ?? '',
      state: userAddress?.state?.text ?? userAddress?.state ?? '',
      country: userAddress?.country?.text ?? userAddress?.country ?? '',
      address1: userAddress?.address1?.text ?? userAddress?.address1 ?? '',
      address2: userAddress?.address2?.text ?? userAddress?.address2 ?? '',
      address_id: userAddress?.address_id,
      latitude: userAddress?.latitude ?? 0,
      longitude: userAddress?.longitude ?? 0,
      alternate_phone:
        userAddress?.alternate_phone?.text ?? userAddress?.alternate_phone ?? '',
      email_id: userAddress?.email_id?.text ?? userAddress?.email_id ?? '',
    };
  }
  return {};
};
