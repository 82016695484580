import { saveorderInstruction } from '@/redux/actions';
import useSetCart from '@/utils/customHooks';
import getCustomCtaText from '@/utils/getCustomCtaText';
import { PageIcon } from '@/assets/svgExports/PageIcon';
import React, { useContext } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { CheckoutInfoTabRightContainer } from '../CheckoutInfoTab.styles';
import {
  OrderInstructionInfoTabAddCta,
  OrderInstructionInfoTabSavedText,
  OrderInstructionTextArea,
} from './TabOrderInstruction.styles';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import BasicModal from 'src/components/BasicModal';
import { getThemeColor } from 'src/utils/getThemeColor';
import { CartContext } from 'src/containers/Cart';

function CheckoutInfoTabOrderInstruction(props) {
  const { showAuthModal = null } = useContext(CartContext);

  const themeColor = getThemeColor();
  const dispatch = useDispatch();
  const [toggleTextArea, setToggleTextArea] = useState(false);
  const { storeData, userData, cartData } = useSelector((state) => ({
    storeData: state.storeReducer.store,
    userData: state.userReducer,
    cartData: state.cartReducer,
  }));
  const [orderText, setOrderText] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [cartSavedOrderText, setCartSavedOrderText] = useState(cartData?.instructions);
  const { triggerSetCart } = useSetCart();

  function onOrderInstructionSaveBtnClick() {
    setOrderText(orderText);
    dispatch(saveorderInstruction(orderText));
    triggerSetCart({
      ...cartData,
      user_phone: userData?.data?.phone,
      phone: userData?.data?.phone,
      instructions: orderText,
    });
    setCartSavedOrderText(orderText);
  }

  function handleOrderInstructionInfoTabTypingAddCta() {
    onOrderInstructionSaveBtnClick();
    setIsEdit(false);
    handleToggleTextArea();
  }

  function handleOrderText(e) {
    if (e.target.value.length < 101) {
      setOrderText(e.target.value);
    }
  }
  function handleToggleTextArea() {
    setToggleTextArea(!toggleTextArea);
  }

  function handleOrderInstructionInfoTabEditCta(e) {
    e.stopPropagation();
    handleToggleTextArea();
    setIsEdit(true);
    setOrderText(cartSavedOrderText);
  }

  function handleOrderInstructionInfoTabAddCta() {
    setOrderText('');
    handleToggleTextArea();
  }

  function handleOrderInstructionTypingCancelCta() {
    setIsEdit(false);
    handleToggleTextArea();
  }

  const renderButtonWihtTextArea = () => {
    return (
      toggleTextArea && (
        <OrderInstructionInfoTabAddCta
          className="tw-rounded-lg tw-py-[12px] tw-text-center !tw-text-sm md:tw-p-0 "
          color={IS_DESKTOP ? themeColor : 'white'}
          background={IS_DESKTOP ? 'transparent' : themeColor}
          onClick={handleOrderInstructionInfoTabTypingAddCta}
        >
          Save Instructions
        </OrderInstructionInfoTabAddCta>
      )
    );
  };

  function renderTabCTA() {
    return (
      <CheckoutInfoTabRightContainer className="!tw-m-0">
        {!toggleTextArea && !cartSavedOrderText && (
          <OrderInstructionInfoTabAddCta
            color={themeColor}
            onClick={
              userData?.isLogin ? handleOrderInstructionInfoTabAddCta : showAuthModal
            }
          >
            Add
          </OrderInstructionInfoTabAddCta>
        )}
        {cartSavedOrderText && !toggleTextArea && (
          <OrderInstructionInfoTabAddCta
            color={storeData?.theme?.colors?.primary_color}
            onClick={(e) => handleOrderInstructionInfoTabEditCta(e)}
          >
            Edit
          </OrderInstructionInfoTabAddCta>
        )}
        {IS_DESKTOP && renderButtonWihtTextArea()}
      </CheckoutInfoTabRightContainer>
    );
  }

  const renderTextAreaInnerContent = () => {
    return (
      toggleTextArea && (
        <div className="tw-flex tw-flex-col tw-gap-[24px] tw-px-[16px] tw-py-[24px] md:tw-p-0">
          <OrderInstructionTextArea
            className="tw-h-[125px] !tw-w-full tw-rounded-lg tw-border !tw-border-solid !tw-border-[#E2E2E2] tw-p-[12px] tw-text-sm tw-font-medium tw-text-[#7D7D7D] md:tw-h-fit md:tw-rounded-none md:!tw-border-none md:tw-p-0 md:tw-font-normal"
            placeholder="Type order instrictions here"
            value={orderText}
            onChange={(e) => handleOrderText(e)}
          />

          {IS_DESKTOP && (
            <div className="tw-flex tw-justify-end tw-text-xs tw-opacity-60">
              {orderText.length}/ 100
            </div>
          )}
          {!IS_DESKTOP && renderButtonWihtTextArea()}
        </div>
      )
    );
  };

  const renderTextAreaMobile = () => {
    return (
      <BasicModal
        show={toggleTextArea}
        onClose={handleOrderInstructionTypingCancelCta}
        heading={getCustomCtaText('order_instructions_label')}
      >
        {renderTextAreaInnerContent()}
      </BasicModal>
    );
  };

  return (
    <div className="tw-border-y tw-border-solid tw-border-[#EAEAEC] md:tw-mb-0 md:tw-mt-[16px] md:tw-rounded-lg md:tw-border">
      <div
        className={`tw-flex tw-items-center tw-justify-between tw-px-[16px] tw-py-[24px] tw-text-sm md:tw-pl-[20px] md:tw-pr-[16px] ${!IS_DESKTOP && cartSavedOrderText && !isEdit ? '!tw-pb-[12px]' : ''}`}
      >
        <span className="tw-flex tw-items-center tw-gap-[12px] tw-text-[16px] tw-font-semibold md:tw-text-[14px] md:tw-font-medium">
          {IS_DESKTOP && <PageIcon />} {getCustomCtaText('order_instructions_label')}
        </span>
        {renderTabCTA()}
      </div>
      {cartSavedOrderText && !isEdit && (
        <div className="tw-text-wrap tw-border-t tw-border-none tw-border-[#EAEAEC] tw-px-[16px] tw-pb-[24px] md:tw-border-solid md:tw-py-[16px] md:tw-pl-[46px] md:tw-pr-[32px]">
          <OrderInstructionInfoTabSavedText className="!tw-m-0 !tw-w-full !tw-flex-wrap tw-text-sm">
            {cartSavedOrderText}
          </OrderInstructionInfoTabSavedText>
        </div>
      )}
      {IS_DESKTOP && toggleTextArea ? (
        <div className="tw-border-t tw-border-solid tw-border-[#EAEAEC] tw-pb-[8px] tw-pl-[46px] tw-pr-[8px] tw-pt-[16px] ">
          {renderTextAreaInnerContent()}
        </div>
      ) : (
        renderTextAreaMobile()
      )}
    </div>
  );
}

export default CheckoutInfoTabOrderInstruction;
