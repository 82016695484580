import React from 'react';
import { DISCOUNT_IMAGE_TAG, getDiscountTagImageComponent } from '../utils';
import { getDiscountPercentage } from 'src/utils/getDiscountPercentage';
import classNames from 'classnames';
import { getStoreType } from 'src/utils/getStoreType';
import { STORE_CUSTOMIZATION } from 'src/utils/constants';
import { CURRENCY_SYMBOL } from 'src/config';

export function DiscountTag({ config, productData, isMobile }) {
  const showPercentDiscount = config?.discountType === 'percentage';
  const discount = showPercentDiscount
    ? getDiscountPercentage(productData?.price, productData?.discounted_price)
    : Math.floor(productData?.price - productData?.discounted_price);
  const isB2bStore = getStoreType() === STORE_CUSTOMIZATION.B2B;

  if (!discount || isB2bStore || !config) {
    return null;
  }

  switch (config?.discountStyle) {
    case 'discountOverlay': {
      const TagImageComponent = getDiscountTagImageComponent(config, isMobile);
      const isDiscountTypeRevArched =
        config?.discountTag === DISCOUNT_IMAGE_TAG.REVERSE_ARCH;
      const isDiscountTypeSquare = config?.discountTag === DISCOUNT_IMAGE_TAG.SQUARE;
      const isDiscountTypeCircle = config?.discountTag === DISCOUNT_IMAGE_TAG.CIRCLE;

      const translateOffset = (() => {
        if (isDiscountTypeRevArched) {
          return isMobile ? 4 : 2.2;
        }
        if (isDiscountTypeSquare) {
          return isMobile ? 1.5 : 1.95;
        }
        if (isDiscountTypeCircle) {
          return isMobile ? 1.2 : 1.8;
        }
        return 0;
      })();

      return (
        <div
          className="tw-relative"
          style={{
            transformOrigin: 'top right',
          }}
        >
          {TagImageComponent && <TagImageComponent bgColor={config?.backgroundColor} />}
          <p
            className={classNames(
              'tw-absolute tw-left-[50%] tw-top-[50%] tw-z-[1] tw-m-0 tw-text-center tw-font-medium',
              !isMobile ? 'tw-text-[12px]' : 'tw-text-[10px]'
            )}
            style={{
              color: config?.textColor,
              transform: `translateX(-50%) translateY(calc(-50% - ${translateOffset}px))`,
            }}
          >
            {showPercentDiscount
              ? `${discount}% OFF`
              : `${CURRENCY_SYMBOL + discount} OFF`}
          </p>
        </div>
      );
    }
    default:
      return (
        <p
          style={{
            background: config?.discountStyle !== 'text' && config?.backgroundColor,
            color: config?.textColor,
          }}
          className={classNames(
            'tw-m-0 tw-break-keep tw-text-center tw-text-[11px] tw-font-medium tw-leading-[1]',
            config?.discountStyle !== 'text'
              ? 'tw-px-[4px] tw-py-[2px] tw-leading-[1]'
              : 'tw-leading-[0.94]'
          )}
        >
          {showPercentDiscount ? `${discount}% OFF` : `${CURRENCY_SYMBOL + discount} OFF`}
        </p>
      );
  }
}
