/** MODULES */
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import store from './../redux/store';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';

/** STYLES */
import './../styles/index.scss';
// import 'react-toastify/dist/ReactToastify.css';
// import 'slick-carousel/slick/slick-theme.css';
// import 'slick-carousel/slick/slick.css';
import '@dotpe/additional-pages/dist/styles/index.scss';
import '@/components/DesignSystem';

import { SSRSelectorContext } from '@/redux/useSSRSelector';
import { setRoutes } from '@/redux/actions';
import { WidgetDndContext, useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { GlobalCfeLoader } from 'src/components/WidgetMaker/WidgetDnD/GlobalCfeLoader';

function MyApp(appProps: AppProps<any>) {
  const { query: qqqq } = useRouter();
  const { Component, pageProps } = appProps;
  const query = qqqq || appProps.router.query;
  const router = useRouter();
  const {
    widgetContextState: { globalStyle },
  } = useWidgetDndContextData();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = (route) => {
      store.dispatch(setRoutes({ next: route, current: window.location.pathname }));
      setLoading(true);
    };
    const handleComplete = (url) => setLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, []);

  useEffect(() => {
    // const getAccessToken = async (code) => {
    //   try {
    //     console.log('code:  ', code);
    //     const response = await axios.post(
    //       `https://api.instagram.com/oauth/access_token`,
    //       // `https://api.instagram.com/oauth/access_token?client_id=567699272473995&client_secret=612a9aa53b2ace6a12d6112fdb48bef0&grant_type=authorization_code&redirect_uri=${encodeURIComponent('https://showroom.digitaldukaan.app/')}&code=${code}`,
    //       {
    //         client_id: '567699272473995',
    //         client_secret: '612a9aa53b2ace6a12d6112fdb48bef0',
    //         grant_type: 'authorization_code',
    //         redirect_uri: 'https://showroom.digitaldukaan.app/',
    //         code: code,
    //       }
    //     );
    //     console.log('response: ', response);
    //     // const data = response.data.data;
    //   } catch (err) {
    //     console.log('Insta token get error:  ', err);
    //   }
    // };
    // const { code } = getUrlParams(['code']);
    // if (code) getAccessToken(code);
  }, []);

  if (query.code) {
    return null;
  }

  const domain =
    pageProps?.domain || pageProps?.ssrStore?.storeReducer?.store?.domain || '';
  const userAgent = pageProps?.userAgent || '';

  return (
    <Provider store={store}>
      <SSRSelectorContext.Provider value={{ domain, userAgent }}>
        <WidgetDndContext>
          {loading && (
            <div className="global-loader">
              <GlobalCfeLoader
                type={globalStyle?.appLoader?.loaderId || 'ellipsis'}
                color={globalStyle?.appLoader?.color}
              />
            </div>
          )}
          <Component {...pageProps} />
        </WidgetDndContext>
      </SSRSelectorContext.Provider>
    </Provider>
  );
}

export default MyApp;
