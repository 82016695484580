import store from '../redux/store';
import { deviceWidth } from '@/utils/deviceWidth';

export const specificThemeRender = (mainTheme, alternateTheme, isPremium) => {
  const toggleMobileView = store.getState().commonReducer.toggleMobileView;
  if (deviceWidth > 998) {
    return isPremium || mainTheme?.is_demo === 1 || mainTheme?.brand_website_flag > 0
      ? mainTheme
      : toggleMobileView
      ? mainTheme
      : alternateTheme;
  }
  return mainTheme;
};
