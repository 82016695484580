import { FunctionComponent, MouseEvent, PropsWithChildren } from 'react';
// eslint-disable-next-line import/named
import { SortableEvent } from 'sortablejs';
import { WidgetFrame } from './WidgetFrame';
import cn from 'classnames';
import styles from './widgetDnDBuilder.module.scss';
import { RenderWidget } from './RenderWidget';
import { WidgetJSONData } from './widgetDnDUtils';
import { MainSectionAddIcon } from './commonSvgs';
import { isThemeBrandWebsite } from '@/utils/isThemeBrandWebsite';
// import { useIsThemeBrandWebsite } from '@/hooks/useIsThemeBrandWebsite';

interface CreateHTMLProps {
  jsonTree?: Record<string, WidgetJSONData>;
  onAdd?: (e: SortableEvent) => void;
  onDelete?: (e: MouseEvent<HTMLSpanElement>) => void;
  onSelect?: (e: MouseEvent<HTMLSpanElement>) => void;
  onMove?: (widgetId: string, direction?: string) => void;
  siblingCount?: number;
  index?: number;
  parent?: string;
  dnd?: boolean;
  onAddBtnClickViaMainSection?: any;
  activeWidgetId?: any;
}

export const ROOT_PARENT = 'page-builder';

export const DnDHtmlWrapper: FunctionComponent<
  PropsWithChildren<CreateHTMLProps & { widget: any }>
> = (props) => {
  const { widget, dnd, activeWidgetId } = props;

  return (
    <div
      id={widget.id}
      data-id={widget.id}
      data-widget={widget.name}
      className={cn(styles.widgetBox, `widget-${widget.name}`, 'rendered')}
      style={widget.id === activeWidgetId ? { border: '2px solid blue' } : {}}
    >
      {dnd && (
        <WidgetFrame
          name={widget.name}
          widgetId={widget.id}
          onDelete={props.onDelete}
          onSelect={props.onSelect}
          onMove={props.onMove}
          siblingCount={props.siblingCount}
          index={props.index}
          alwaysVisible={['2-col'].includes(widget.name)}
          widget={widget}
        ></WidgetFrame>
      )}
      {/* The code below is done because we don't want any mouse interaction in Website builder, while in CFE it should have interactions */}
      {dnd ? (
        <div onClick={() => props.onSelect(widget)}>
          <div
          // style={{ pointerEvents: 'none' }}
          >
            {props.children}
          </div>
        </div>
      ) : (
        props.children
      )}
    </div>
  );
};

export const CreateHTML: FunctionComponent<CreateHTMLProps> = (props) => {
  const { jsonTree, dnd, activeWidgetId } = props;
  const childrenIds = jsonTree[props.parent || ROOT_PARENT]?.children || [];

  // const isThemeBrandWebsite = useIsThemeBrandWebsite();
  // const thisParentChildren = Object.values(jsonTree).filter((widget) => widget.parent === (props.parent || ROOT_PARENT));

  return childrenIds.map((widgetId, index) => {
    const widget = jsonTree[widgetId];

    if (!widget) {
      return null;
    }

    return (
      <>
        {dnd && !isThemeBrandWebsite() && (
          <div
            className={styles.addWidgetViaMainSection}
            onClick={() => props.onAddBtnClickViaMainSection(index)}
          >
            <div className={styles.content}>
              <MainSectionAddIcon className={styles.iconImg} />
              <span>Add a section</span>
            </div>
          </div>
        )}
        <DnDHtmlWrapper
          {...props}
          siblingCount={childrenIds.length}
          index={index}
          widget={widget}
          key={widget.id}
          activeWidgetId={activeWidgetId}
        >
          <RenderWidget key={widget.id} widget={widget} onAdd={props.onAdd}>
            <CreateHTML {...props} parent={widget.id} />
          </RenderWidget>
        </DnDHtmlWrapper>
        {dnd && !isThemeBrandWebsite() && (
          <div
            className={styles.addWidgetViaMainSection}
            onClick={() => props.onAddBtnClickViaMainSection(childrenIds.length)}
          >
            <div className={styles.content}>
              <MainSectionAddIcon className={styles.iconImg} />
              <span>Add a section</span>
            </div>
          </div>
        )}
      </>
    );
  });
};
