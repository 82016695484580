import { ROOT_PARENT } from 'src/components/WidgetMaker/WidgetDnD/CreateHTML';
import { IWidgetDndInitialState } from '../types';
import { WIDGET_DND_ACTION_TYPES } from './actionTypes';
import { cloneDeep } from 'lodash';
import { GLOBAL_SETTING_CONFIG } from 'src/components/WidgetMaker/WidgetDnD/GlobalStyle/globalSettingConstant';
import { SECTION_TYPE } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/FooterBuilder/constant';
import { GLOBAL_STYLE } from 'src/constants/globalConfigs';
import { REEL_EMPTY_DATA } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/FeaturedReels/FeaturedReelsConfig';

const DEFAULT_WIDGET = {
  'page-builder': {
    id: 'page-builder',
    name: 'page-builder',
    parent: '',
    children: [],
    props: {},
  },
};

const DEFAULT_HEADER_DATA = {
  headerName: null,
  headerComponent: null,
  headerPropsConfig: {},
};

const DEFAULT_FOOTER_DATA = {
  footerName: null,
  footerComponent: null,
  footerPropsConfig: {},
};

export const WIDGET_DND_INITIAL_STATE: IWidgetDndInitialState = {
  widgets: DEFAULT_WIDGET,
  activeWidgetId: null,
  hasUnsavedChanges: false,
  sidePanelState: {
    showWidgetOutline: true,
    showAddWidgetDrawerSection: false,
    showActiveWidgetProperty: false,
    showStyleLayout: false,
  },
  indexToAddWidgetAtFromMainSection: null,
  activeHeaderData: DEFAULT_HEADER_DATA,
  activeFooterData: DEFAULT_FOOTER_DATA,
  isHeaderFooterPropertyActive: {
    status: false,
    type: null,
  },
  widgetDeleteModalOpen: false,
  widgetIdToDelete: null,
  showPreview: true,
  previewDevice: 'desktop',
  openPostPublishModal: false,
  activeItem: {
    outline: true,
    styleTemplate: false,
  },
  widgetSettingsSectionState: {
    singleBannerSettings: {
      show: false,
      data: {},
      heading: '',
    },
  },
  globalSettings: GLOBAL_SETTING_CONFIG,
  globalStyle: GLOBAL_STYLE,
  footerChild: {
    type: SECTION_TYPE.FOOTER_SELECTION,
    layer: '',
    index: 0,
    displayText: 'Footer',
  },
  isBuilder: false,
  customTags: [],
  variantData: [],
  snackbarConfig: {
    open: false,
    message: '',
  },
  reelsData: REEL_EMPTY_DATA,
};

// By default add page-builder via DEFAULT_WIDGET, which can be overriden if it is already present
export function generateWidgetData(widgets = {}) {
  const isParentBuilderPresent = widgets[ROOT_PARENT];
  let defaultBuilderParent = DEFAULT_WIDGET;
  if (!isParentBuilderPresent) {
    const newParent = cloneDeep(DEFAULT_WIDGET);
    newParent[ROOT_PARENT].children = Object.entries(widgets).reduce(
      (current: any, acc) => {
        const [widgetID, widgetValue] = current;
        if (widgetID && widgetValue.parent === ROOT_PARENT) {
          acc.push(widgetID);
        }
        return acc;
      },
      []
    );
    defaultBuilderParent = newParent;
  }
  return { ...defaultBuilderParent, ...widgets };
}

export const WidgetDndContextReducer = (state = WIDGET_DND_INITIAL_STATE, action) => {
  switch (action.type) {
    case WIDGET_DND_ACTION_TYPES.UPDATE_WIDGETS:
      return { ...state, widgets: generateWidgetData(action.payload) };
    case WIDGET_DND_ACTION_TYPES.SET_ACTIVE_WIDGET:
      return { ...state, activeWidgetId: action.payload };
    case WIDGET_DND_ACTION_TYPES.PAGE_UPDATE_STATUS:
      return { ...state, hasUnsavedChanges: action.payload };
    case WIDGET_DND_ACTION_TYPES.UPDATE_SIDE_PANEL_STATE:
      return { ...state, sidePanelState: { ...state.sidePanelState, ...action.payload } };
    case WIDGET_DND_ACTION_TYPES.ADD_WIDGET_INDEX:
      return { ...state, indexToAddWidgetAtFromMainSection: action.payload };
    case WIDGET_DND_ACTION_TYPES.ADD_WIDGET_INDEX:
      return { ...state, indexToAddWidgetAtFromMainSection: action.payload };
    case WIDGET_DND_ACTION_TYPES.SET_ACTIVE_HEADER_DATA:
      return { ...state, activeHeaderData: action.payload, hasUnsavedChanges: true };
    case WIDGET_DND_ACTION_TYPES.SET_ACTIVE_FOOTER_DATA:
      return { ...state, activeFooterData: action.payload, hasUnsavedChanges: true };
    case WIDGET_DND_ACTION_TYPES.SET_HEADER_FOOTER_PROPERTY_ACTIVE_STATUS:
      return {
        ...state,
        isHeaderFooterPropertyActive: {
          ...state.isHeaderFooterPropertyActive,
          ...action.payload,
        },
      };
    case WIDGET_DND_ACTION_TYPES.SET_WIDGET_DELETE_MODAL_STATUS:
      return { ...state, widgetDeleteModalOpen: action.payload };
    case WIDGET_DND_ACTION_TYPES.SET_WIDGET_ID_TO_DELETE:
      return { ...state, widgetIdToDelete: action.payload };
    case WIDGET_DND_ACTION_TYPES.SET_SHOW_PREVIEW:
      return { ...state, showPreview: action.payload };
    case WIDGET_DND_ACTION_TYPES.TOGGLE_PREVIEW_DEVICE:
      return {
        ...state,
        previewDevice:
          action.payload || (state.previewDevice === 'desktop' ? 'mobile' : 'desktop'),
      };
    case WIDGET_DND_ACTION_TYPES.SET_POST_PUBLISH_MODAL_STATUS:
      return { ...state, openPostPublishModal: action.payload };
    case WIDGET_DND_ACTION_TYPES.SET_ACTIVE_ITEM:
      return { ...state, activeItem: { ...state.activeItem, ...action.payload } };
    case WIDGET_DND_ACTION_TYPES.SET_WIDGET_SETTINGS_SECTION_STATE:
      return {
        ...state,
        widgetSettingsSectionState: {
          ...state.widgetSettingsSectionState,
          ...action.payload,
        },
      };
    case WIDGET_DND_ACTION_TYPES.SET_GLOBAL_SETTING:
      return {
        ...state,
        globalSettings: {
          ...state.globalSettings,
          ...action.payload,
        },
      };
    case WIDGET_DND_ACTION_TYPES.SET_GLOBAL_STYLE:
      return {
        ...state,
        globalStyle: {
          ...state.globalStyle,
          ...action.payload,
        },
      };
    case WIDGET_DND_ACTION_TYPES.FOOTER_WIDGET_SECTION:
      return {
        ...state,
        footerChild: {
          ...state.footerChild,
          ...action.payload,
        },
      };
    case WIDGET_DND_ACTION_TYPES.SET_BUILDER_FLAG:
      return {
        ...state,
        isBuilder: action.payload,
      };
    case WIDGET_DND_ACTION_TYPES.SET_CUSTOM_TAG:
      return {
        ...state,
        customTags: action.payload,
      };
    case WIDGET_DND_ACTION_TYPES.SET_SNACKBAR_CONFIG:
      return {
        ...state,
        snackbarConfig: { ...state.snackbarConfig, ...action.payload },
      };
    case WIDGET_DND_ACTION_TYPES.SET_VARIANT_DATA:
      return {
        ...state,
        variantData: action.payload
      };
    case WIDGET_DND_ACTION_TYPES.SET_REELS_OUTLINE_DATA: 
      return {
        ...state,
        reelsData: {
            ...state.reelsData,
            ...action.payload
        }
      }
    default:
      return state;
  }
};
