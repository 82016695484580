import React from 'react';
import { ProductCard } from '../../ProductCard';
import { Card } from '../../../Card';

export function FeatureProductCards({
  isProductCardEnabled,
  data,
  index,
  oneGridCellGeometry,
  isMobile,
  sectionColorsOverrides,
  customClass,
}) {
  if (!isProductCardEnabled) {
    return (
      <Card
        key={index}
        data={data.data}
        id={`${data.name}-${data.id}`}
        cardWidth={oneGridCellGeometry.width}
        showButton={true}
        noTags={false}
        builderOverrideStyles={sectionColorsOverrides}
      />
    );
  }

  return (
    <ProductCard
      key={index}
      productData={data.data}
      itemIndex={index}
      configs={{
        customWrapperClass: customClass,
        isMobile,
        dimensions: {
          cardWidth: oneGridCellGeometry.width,
          cardHeight: oneGridCellGeometry.height,
        },
        overrideStyles: sectionColorsOverrides,
      }}
    />
  );
}
