import { getDefaultConfig } from '@/components/WidgetMaker/utils/common';

export interface WidgetOptions {
  isVisible: boolean;
  baseConfig: {
    background: string;
    foregroundImage: string;
    bgImage: Record<string, string | number>;
    backgroundType: string;
    marginTop: number;
    marginBottom: number;
    showBorder: boolean;
    makeFullWidth: boolean;
    borderColor: string,
    borderThicness: number
  };
  textSection: {
    backgroundColors: Record<string, string | boolean>[];
    textColor: string;
    selectedIndex: number;
    backgroundColor: string;
    alignment: string;
    position: string;
    mobileAlignment: string;
    bgFillType: string;
    bgImage: Record<string, string | number>;
  };
  imageSection: {
    desktop: {
      height: string;
      width: string;
      imageAlignment: string;
    };
    mobile: {
      height: string;
      width: string;
      imageAlignment: string;
    };
    url: string;
    src: string;
    animationType: string;
  };
  textGroupSection: any;
}

export interface WidgetProps {
  store: any;
  cart: any;
  catalog: any;
  user: any;
  promo: any;
  widgetOptions: WidgetOptions;
  update: number;
}

export const BASE_SECTION_BACKGROUND_TYPES = {
  color: 'color',
  image: 'image',
};

export const SUBTITLE_POSITIONS_DATA = [
  {
    text: 'Top',
    value: 'top',
    itemLevelClass: 'tw-w-[50%]',
  },
  {
    text: 'Bottom',
    value: 'bottom',
    itemLevelClass: 'tw-w-[50%]',
  },
];

export const SUBTITLE_SIZE_LIST = [
  { text: 'Small', value: 'small' },
  { text: 'Medium', value: 'medium' },
  { text: 'Large', value: 'large' },
];

export const TEXT_GROUP_OPTIONS_ITEM = {
  title: '<h2>Talk about your brand</h2>',
  subtitleSize: 'small',
  isVisible: true,
  subtitlePosition: 'top',
  subtitle: `<p>Subtitle</p>`,
  description: `<p>Use this text to share information about your brand with your customers.
   Describe a product, share announcements, or welcome customers to your store.</p>`,
  button: [getDefaultConfig('bannerButton', '#000000', '#FFFFFF')],
};

export const RICH_TEXT_KEY = {
  ALIGNMENT : 'alignment',
  POSITION: 'position',
  MOBILE_ALIGNMENT: 'mobileAlignment',
  BACKGROUND_COLOR: 'backgroundColor',
  TEXT_COLOR: 'textColor',
  BACKGROUND: 'background',
  BORDER_COLOR: 'borderColor',
  SHOW_BORDER: 'showBorder',
  MAKE_FULL_WIDTH: 'makeFullWidth',
}

export const RICH_TEXT_DEFAULT_WIDGET_OPTIONS: Partial<WidgetOptions> = {
  isVisible: true,
  baseConfig: {
    background: '#ffffff',
    foregroundImage: '',
    marginTop: 0,
    marginBottom: 0,
    bgImage: {
      url: '',
      src: '',
      opacity: 70,
    },
    backgroundType: BASE_SECTION_BACKGROUND_TYPES.color,
    showBorder: false,
    makeFullWidth: true,
    borderColor: '#e2e4e7',
    borderThicness: 1
  },
  textSection: {
    backgroundColors: [
      {
        background: '#ffffff',
        text: '#000000',
        isDefault: false,
      },
      {
        background: 'transparent',
        text: '#ffffff',
        isDefault: false,
      },
    ],
    bgFillType: BASE_SECTION_BACKGROUND_TYPES.color,
    bgImage: {
      url: '',
      src: '',
      opacity: 70,
    },
    selectedIndex: 0,
    textColor: '#000000',
    backgroundColor: '#ffffff',
    position: 'center',
    alignment: 'left',
    mobileAlignment: 'left',
  },
  textGroupSection: {
    builderConfig: {
      textSection: {
        subtitlePositions: SUBTITLE_POSITIONS_DATA,
        subtitleSize: SUBTITLE_SIZE_LIST,
      },
    },
    groupItemsList: [TEXT_GROUP_OPTIONS_ITEM],
  },
};

