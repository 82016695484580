import { setCart } from '@/redux/actions';
import { RootState } from '@/redux/reducers';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function useCartAsyncCall(storeData) {
  const dispatch = useDispatch();

  const { cart } = useSelector((state: RootState) => ({
    cart: state.cartReducer,
  }));

  useEffect(() => {
    if (cart.items && cart.items.length > 0) {
      setCartAsyncCall();
    }
  }, [cart.pay_amount]);

  const setCartAsyncCall = () => {
    return new Promise((resolve) => {
      dispatch(
        setCart(
          {
            ...cart,
            order_type: cart.order_type,
            store_id: storeData?.store_id,
            user_phone: cart.phone,
            cart_id: cart.cart_id,
          },
          (status) => {
            resolve(null);
          }
        )
      );
    });
  };

  return null;
}
