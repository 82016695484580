import axios from 'axios';
import config from './../config';
import { v4 as uuidv4 } from 'uuid';

const IS_SERVER = typeof window === 'undefined';

const defaultOptions = {
  baseURL: config.report_api,
  headers: {
    'Content-Type': 'application/json',
  },
};
const api = axios.create(defaultOptions);

api.interceptors.request.use((item) => item);

if (!IS_SERVER) {
  if (!sessionStorage?.getItem('sess-id')) {
    const newSessId = uuidv4();
    sessionStorage.setItem('sess-id', newSessId);
  }
  api.interceptors.request.use((item) => {
    const sessid = sessionStorage?.getItem('sess-id');
    item.headers['session_id'] = sessid ? `${sessid}` : '';
    if (item.url.includes('dotk') || item.url.includes('getAllByUser')) {
      item.headers['auth_token'] = localStorage?.getItem('auth_token');
      item.headers['app_os'] = 'cfe';
      item.headers['app_version'] = '0.1.0';
    }
    if (item.url.includes('getStoreBySubDomain'))
      item.headers['secret'] = '938923c72331801ab55687160a048d5b';
    // if (window.location.pathname.includes('/widgets/')) {
    //   item.headers['request_path'] = window.location.pathname;
    // }
    return item;
  });
}
api.interceptors.response.use(
  (resp) => {
    if (resp?.config?.parseToJson) {
      resp['data']['data'] = JSON.parse(resp?.data?.data);
    }

    return resp;
  },
  (err) => {
    throw err.response;
  }
);

export default api;
