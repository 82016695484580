import dynamic from 'next/dynamic';

const Header1 = dynamic(() => import('@/components/DesignSystem/Headers/Header1'));
const Header2 = dynamic(() => import('@/components/DesignSystem/Headers/Header2'));
const Header3 = dynamic(() => import('@/components/DesignSystem/Headers/Header3'));
const Header7 = dynamic(() => import('@/components/DesignSystem/Headers/Header7'));
const Header8 = dynamic(() => import('@/components/DesignSystem/Headers/Header8'));
const Header9 = dynamic(() => import('@/components/DesignSystem/Headers/Header9'));
const Header10 = dynamic(() => import('@/components/DesignSystem/Headers/Header10'));
const Header11 = dynamic(() => import('@/components/DesignSystem/Headers/Header11'));
const Header12 = dynamic(() => import('@/components/DesignSystem/Headers/Header12'));
const Header14 = dynamic(() => import('@/components/DesignSystem/Headers/Header14'));
// Sub Header ( Hello Bar)
const SubHeader1 = dynamic(
  () => import('@/components/DesignSystem/SubHeader/SubHeader1/SubHeader1')
);
const SubHeader2 = dynamic(
  () => import('@/components/DesignSystem/SubHeader/SubHeader2/SubHeader2')
);
const SubHeader3 = dynamic(
  () => import('@/components/DesignSystem/SubHeader/SubHeader3/SubHeader3')
);
const SubHeader4 = dynamic(
  () => import('@/components/DesignSystem/SubHeader/SubHeader4/SubHeader4')
);
// Hello Bar ( This now replaces all previous subHeaders)
const SubHeader = dynamic(
  () => import('@/components/DesignSystem/SubHeader/SubHeader/SubHeader')
);
// Hero banner
const HeroBanner1 = dynamic(
  () => import('@/components/DesignSystem/HeroBanner/HeroBanner1')
);
const HeroBanner2 = dynamic(
  () => import('@/components/DesignSystem/HeroBanner/HeroBanner2')
);
const HeroBanner3 = dynamic(
  () => import('@/components/DesignSystem/HeroBanner/HeroBanner3')
);
const HeroBanner4 = dynamic(
  () => import('@/components/DesignSystem/HeroBanner/HeroBanner4')
);
const HeroBanner5 = dynamic(
  () => import('@/components/DesignSystem/HeroBanner/HeroBanner5')
);
const HeroBanner6 = dynamic(
  () => import('@/components/DesignSystem/HeroBanner/HeroBanner6')
);
const HeroBanner7 = dynamic(
  () => import('@/components/DesignSystem/HeroBanner/HeroBanner7')
);
const HeroBanner8 = dynamic(
  () => import('@/components/DesignSystem/HeroBanner/HeroBanner8')
);
const HeroBanner9 = dynamic(
  () => import('@/components/DesignSystem/HeroBanner/HeroBanner9')
);
// Middle Body
const MiddleBody1 = dynamic(
  () => import('@/components/DesignSystem/MiddleBody/MiddleBody1')
);
const MiddleBody2 = dynamic(
  () => import('@/components/DesignSystem/MiddleBody/MiddleBody2')
);
const MiddleBody3 = dynamic(
  () => import('@/components/DesignSystem/MiddleBody/MiddleBody3')
);
const MiddleBody4 = dynamic(
  () => import('@/components/DesignSystem/MiddleBody/MiddleBody4')
);
const MiddleBody5 = dynamic(
  () => import('@/components/DesignSystem/MiddleBody/MiddleBody5')
);
const MiddleBody6 = dynamic(
  () => import('@/components/DesignSystem/MiddleBody/MiddleBody6')
);
const PromoList1 = dynamic(
  () => import('@/components/DesignSystem/PromoLists/PromoList1')
);

const PromoList2 = dynamic(
  () => import('@/components/DesignSystem/PromoLists/PromoList2')
);
const PromoList3 = dynamic(
  () => import('@/components/DesignSystem/PromoLists/PromoList3')
);
const PromoList4 = dynamic(
  () => import('@/components/DesignSystem/PromoLists/PromoList4')
);
const PromoList5 = dynamic(
  () => import('@/components/DesignSystem/PromoLists/PromoList5')
);
const Footer = dynamic(() => import('@/components/DesignSystem/Footers/Footer/Footer'));
const Footer1 = dynamic(() => import('@/components/DesignSystem/Footers/Footer1'));
const Footer2 = dynamic(() => import('@/components/DesignSystem/Footers/Footer2'));
const Footer3 = dynamic(() => import('@/components/DesignSystem/Footers/Footer3'));
const CLHaveSomthingInMind = dynamic(
  () => import('@/components/DesignSystem/CLHaveSomthingInMind')
);
const EmptyWidget = dynamic(() => import('@/components/DesignSystem/EmptyWidget'));
// Category Widget
const CategoryWidget1 = dynamic(
  () => import('@/components/DesignSystem/CategoryWidget/CategoryWidget1')
);
const CategoryWidget2 = dynamic(
  () => import('@/components/DesignSystem/CategoryWidget/CategoryWidget2')
);
const CategoryWidget3 = dynamic(
  () => import('@/components/DesignSystem/CategoryWidget/CategoryWidget3')
);
const CategoryWidget4 = dynamic(
  () => import('@/components/DesignSystem/CategoryWidget/CategoryWidget4')
);
const CategoryWidget7 = dynamic(
  () => import('@/components/DesignSystem/CategoryWidget/CategoryWidget7')
);
const CategoryWidget8 = dynamic(
  () => import('@/components/DesignSystem/CategoryWidget/CategoryWidget8')
);
const CategoryWidget9 = dynamic(
  () => import('@/components/DesignSystem/CategoryWidget/CategoryWidget9')
);
const CategoryWidget10 = dynamic(
  () => import('@/components/DesignSystem/CategoryWidget/CategoryWidget10')
);
const CategoryWidget11 = dynamic(
  () => import('@/components/DesignSystem/CategoryWidget/CategoryWidget11')
);
const CategoryWidget12 = dynamic(
  () => import('@/components/DesignSystem/CategoryWidget/CategoryWidget12')
);
const CategoryWidget13 = dynamic(
  () => import('@/components/DesignSystem/CategoryWidget/CategoryWidget13')
);
const CategoryWidget14 = dynamic(
  () => import('@/components/DesignSystem/CategoryWidget/CategoryWidget14')
);
const CategoryWidget15 = dynamic(
  () => import('@/components/DesignSystem/CategoryWidget/CategoryWidget15')
);
const CategoryWidget16 = dynamic(
  () => import('@/components/DesignSystem/CategoryWidget/CategoryWidget16')
);
const CategoryHeader1 = dynamic(
  () => import('@/components/DesignSystem/CategoryHeaderWidget/CategoryHeader1')
);
const CategoryHeader2 = dynamic(
  () => import('@/components/DesignSystem/CategoryHeaderWidget/CategoryHeader2')
);
const CategoryHeader3 = dynamic(
  () => import('@/components/DesignSystem/CategoryHeaderWidget/CategoryHeader3')
);
// CategoryHeader4,
// CategoryHeader5,
const CollectionWidget1 = dynamic(
  () => import('@/components/DesignSystem/CollectionWidget/CollectionWidget1')
);
const CollectionCategoryProductWidget1 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/CollectionCategoryProductWidget/CollectionCategoryProductWidget1'
    )
);

const DisclaimerWidget = dynamic(
  () => import('@/components/DesignSystem/DisclaimerWidget/DisclaimerWidget')
);
const CategoryProducsDisplayWidget = dynamic(
  () => import('@/components/DesignSystem/ProductDisplayWidget')
);
const AdvanceCatalogMiddleBody = dynamic(
  () =>
    import('../components/DesignSystem/MiddleBody/MiddleBody7/AdvanceCatalogMiddleBody')
);
const JsonPageBuilderWidget = dynamic(() =>
  import('@/components/DesignSystem/JsonPageBuilderWidget').then(
    (mod) => mod.JsonPageBuilderWidget
  )
);
const AdditionalPage = dynamic(() => import('../containers/AdditionalPage'));

/**                       Modern Hero Banner Themes                               */

/**  Modern Hero Banners  */

const ModernHeroBanner1 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/HeroBanner/ModernHeroBanner1/ModernHeroBanner1'
    )
);
const ModernHeroBanner2 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/HeroBanner/ModernHeroBanner2/ModernHeroBanner2'
    )
);
const ModernHeroBanner3 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/HeroBanner/ModernHeroBanner3/ModernHeroBanner3'
    )
);
const ModernHeroBanner4 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/HeroBanner/ModernHeroBanner4/ModernHeroBanner4'
    )
);

const ModernHeroBanner5 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/HeroBanner/ModernHeroBanner5/ModernHeroBanner5'
    )
);
const ModernHeroBanner6 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/HeroBanner/ModernHeroBanner6/ModernHeroBanner6'
    )
);

/**  Modern Middle Body 2-Column  */

const ModernMiddleBodyColumn1 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_2_Column/ModernMiddleBody1/ModernMiddleBody1'
    )
);
const ModernMiddleBodyColumn2 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_2_Column/ModernMiddleBody2/ModernMiddleBody2'
    )
);
const ModernMiddleBodyColumn3 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_2_Column/ModernMiddleBody3/ModernMiddleBody3'
    )
);
const ModernMiddleBodyColumn4 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_2_Column/ModernMiddleBody4/ModernMiddleBody4'
    )
);
const ModernMiddleBodyColumn5 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_2_Column/ModernMiddleBody5/ModernMiddleBody5'
    )
);
const ModernMiddleBodyColumn6 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_2_Column/ModernMiddleBody6/ModernMiddleBody6'
    )
);

/**  Modern Middle Body Gallery  */

const ModernMiddleBodyGallery1 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_Gallery/ModernMiddleBody1/ModernMiddleBody1'
    )
);
const ModernMiddleBodyGallery2 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_Gallery/ModernMiddleBody2/ModernMiddleBody2'
    )
);
const ModernMiddleBodyGallery3 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_Gallery/ModernMiddleBody3/ModernMiddleBody3'
    )
);
const ModernMiddleBodyGallery4 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_Gallery/ModernMiddleBody4/ModernMiddleBody4'
    )
);
const ModernMiddleBodyGallery5 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_Gallery/ModernMiddleBody5/ModernMiddleBody5'
    )
);
const ModernMiddleBodyGallery6 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_Gallery/ModernMiddleBody6/ModernMiddleBody6'
    )
);
const ModernMiddleBodyGallery7 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_Gallery/ModernMiddleBody7/ModernMiddleBody7'
    )
);
const ModernMiddleBodyGallery8 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_Gallery/ModernMiddleBody8/ModernMiddleBody8'
    )
);
const ModernHeader1 = dynamic(
  () => import('@/components/DesignSystem/ModernTheme/Header/Header1/Header1')
);

/**  Modern Middle Body Tabs     */
const ModernMiddleBodyTab1 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_Tabs/ModernMiddleBody1/ModernMiddleBody1'
    )
);
const ModernMiddleBodyTab2 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_Tabs/ModernMiddleBody2/ModernMiddleBody2'
    )
);
const ModernMiddleBodyTab3 = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_Tabs/ModernMiddleBody3/ModernMiddleBody3'
    )
);
const ModernFooter1 = dynamic(
  () => import('@/components/DesignSystem/ModernTheme/Footer/Footer1/Footer1')
);
const ModernFooter2 = dynamic(
  () => import('@/components/DesignSystem/ModernTheme/Footer/Footer2/Footer2')
);
const ModernFooter3 = dynamic(
  () => import('@/components/DesignSystem/ModernTheme/Footer/Footer3/Footer3')
);
const ModernFooter4 = dynamic(
  () => import('@/components/DesignSystem/ModernTheme/Footer/Footer4/Footer4')
);
const ModernMiddleBodyMaps = dynamic(
  () =>
    import(
      '@/components/DesignSystem/ModernTheme/MiddleBody/MiddleBody_Maps/ModernMiddleBodyMaps'
    )
);
const NewHeaderComponent = dynamic(() =>
  import('src/components/DesignSystem/Widgets2.0/Header').then(
    (mod) => mod.NewHeaderWrapper
  )
);
const NewHeroBanner = dynamic(() =>
  import('src/components/DesignSystem/Widgets2.0/Carousel').then(
    (mod) => mod.CarouselWidget
  )
);
const RichTextWithImageWidget = dynamic(
  () => import('src/components/DesignSystem/Widgets2.0/RichTextImage')
);

const RichTextWidget = dynamic(
  () => import('src/components/DesignSystem/Widgets2.0/RichText')
);

import { isRedirectedFromWebsiteBuilder } from './commonWidgetFn';
import { ADMIN_WIDGETS } from 'src/constants/widgets';
import NewFooterComponent from 'src/components/DesignSystem/Widgets2.0/Footer/NewFooterComponent';
import ListPresentation from 'src/components/DesignSystem/Widgets2.0/ListPresentation';

function isWrongHeaderConfigPresent(type, name) {
  if (
    type?.includes('CollectionHeaderDropdownWidget') ||
    name?.includes('CollectionHeaderDropdownWidget')
  ) {
    return true;
  }
  return false;
}

export const getThemeComponent = (type, name, props) => {
  // This check is for stores with wrong config set due to an earlier bug, this sets the most used header type
  if (isWrongHeaderConfigPresent(type, name)) {
    return <Header12 {...props} />;
  }
  switch (type) {
    case 'Header':
      if (name === 'Header1') return <Header1 showStoreNameOnMobile={true} {...props} />;
      if (name === 'Header2') return <Header2 showStoreNameOnMobile={true} {...props} />;
      if (name === 'Header3') return <Header3 showStoreNameOnMobile={true} {...props} />;
      if (name === 'Header4') return <Header1 showStoreNameOnMobile={false} {...props} />;
      if (name === 'Header5') return <Header2 showStoreNameOnMobile={false} {...props} />;
      if (name === 'Header6') return <Header3 showStoreNameOnMobile={false} {...props} />;
      if (name === 'Header7') return <Header7 {...props} />;
      if (name === 'Header8') return <Header8 {...props} />;
      if (name === 'Header9') return <Header9 {...props} />; // this is for non premium users in desktop web showcase
      if (name === 'Header10') return <Header10 {...props} />;
      if (name === 'Header11') return <Header11 {...props} />;
      if (name === 'Header12') return <Header12 {...props} />;
      if (name === 'Header13')
        // Custom requirement for a merchant
        // show Categories props is used for either remove or change pages name in navigation header
        return <Header8 showCategories={true} {...props} />;
      if (name === 'Header14') return <Header14 {...props} />;
      if (name === 'NewHeader') return <NewHeaderComponent {...props} />;
      return <></>;
    case 'HeroBanner':
      if (name === 'HeroBanner1') return <HeroBanner1 {...props} />;
      if (name === 'HeroBanner2') return <HeroBanner2 {...props} />;
      if (name === 'HeroBanner3') return <HeroBanner3 {...props} />;
      if (name === 'HeroBanner4') return <HeroBanner4 {...props} />;
      if (name === 'HeroBanner5') return <HeroBanner5 {...props} />;
      if (name === 'HeroBanner6') return <HeroBanner6 {...props} />;
      if (name === 'HeroBanner7') return <HeroBanner7 {...props} />;
      if (name === 'HeroBanner8') return <HeroBanner8 {...props} />;
      if (name === 'HeroBanner9') return <HeroBanner9 {...props} />;
      return <></>;
    case 'Carousel':
      return <NewHeroBanner {...props} forNewCarousel={false} />;
    case ADMIN_WIDGETS.HERO_BANNER.type:
    case ADMIN_WIDGETS.SLIDESHOW.type:
      return (
        (props?.isVisible || props?.isVisible === 'undefined') && (
          <NewHeroBanner {...props} />
        )
      );
    case 'SubHeader':
      if (name === 'SubHeader1') return <SubHeader1 {...props} />;
      if (name === 'SubHeader2') return <SubHeader2 {...props} />;
      if (name === 'SubHeader3') return <SubHeader3 {...props} />;
      if (name === 'SubHeader4') return <SubHeader4 {...props} />;
      return <></>;
    case ADMIN_WIDGETS.RICH_TEXT_WITH_IMAGE.type:
      return props?.isVisible ? <RichTextWithImageWidget {...props} /> : null;
    case ADMIN_WIDGETS.RICH_TEXT.type:
      return <RichTextWidget {...props} />;
    case 'HelloBar':
      return <SubHeader {...props} />;
    case ADMIN_WIDGETS.CATEGORY.name:
    case ADMIN_WIDGETS.COLLECTIONS.name:
    case ADMIN_WIDGETS.INSTAGRAM_EMBED.name:
    case ADMIN_WIDGETS.FEATURED_COLLECTIONS.name:
    case ADMIN_WIDGETS.FEATURED_TAGS.name:
    case ADMIN_WIDGETS.MULTI_COLUMN_WITH_ICON.name:
    case ADMIN_WIDGETS.MULTI_COLUMN_WITH_IMAGE.name:
    case ADMIN_WIDGETS.LOGO_SLIDER.name:
    case ADMIN_WIDGETS.FEATURED_REELS.name:
    case ADMIN_WIDGETS.BLOG_WIDGET.name:
      return <ListPresentation {...props} />;
    case 'MiddleBody':
      if (name === 'MiddleBody1') return <MiddleBody1 {...props} />;
      if (name === 'MiddleBody2') return <MiddleBody2 {...props} />;
      if (name === 'MiddleBody3') return <MiddleBody3 {...props} />;
      if (name === 'MiddleBody4') return <MiddleBody4 {...props} />;
      if (name === 'MiddleBody5') return <MiddleBody5 {...props} />;
      /* This check for Middlebody6 is used as a hack for MiddleBody6 freeze issue in Website Builder */
      if (name === 'MiddleBody6' && isRedirectedFromWebsiteBuilder()) {
        return <MiddleBody6 displayMiddleBodyInWebsiteBuilder={true} {...props} />;
      } else {
        return <MiddleBody6 {...props} />;
      }
    case 'SortingAndFilterWidget':
      if (name === 'SortingAndFilterWidget1')
        return <AdvanceCatalogMiddleBody {...props} />;
      if (name === 'SortingAndFilterWidget2')
        return <MiddleBody5 sortAndFiltersEnabled={true} {...props} />;
      if (name === 'SortingAndFilterWidget3')
        return <MiddleBody6 {...props} sortAndFiltersEnabled={true} />;
      return <></>;
    case 'Footer':
      if (name === 'Footer') return <Footer {...props} />;
      if (name === 'Footer1') return <Footer1 {...props} />;
      if (name === 'Footer2') return <Footer2 {...props} />;
      if (name === 'Footer3') return <Footer3 {...props} />;
      if (name === 'Footer4') return <Footer3 includeCollectionSet={true} {...props} />;
      if (name === ADMIN_WIDGETS.FOOTER.type) return <NewFooterComponent {...props} />;
      return <></>;
    case 'Offers':
      if (name === 'PromoList1') return <PromoList1 {...props} />;
      if (name === 'PromoList2') return <PromoList2 {...props} />;
      if (name === 'PromoList3') return <PromoList3 {...props} />;
      if (name === 'PromoList4') return <PromoList4 {...props} />;
      if (name === 'PromoList5') return <PromoList5 {...props} />;
      return <></>;
    case 'CreateList':
      if (name === 'CreateList') return <CLHaveSomthingInMind {...props} />;
      return <></>;
    case 'StaticWidget':
      if (name === 'StaticWidget') return <EmptyWidget {...props} />;
      if (name === 'DisclaimerStaticWidget') return <DisclaimerWidget {...props} />;
      return <></>;
    case 'CategoryWidget':
      if (name === 'CategoryWidget1') return <CategoryWidget1 {...props} />;
      if (name === 'CategoryWidget2') return <CategoryWidget2 {...props} />;
      if (name === 'CategoryWidget3') return <CategoryWidget3 {...props} />;
      if (name === 'CategoryWidget4') return <CategoryWidget4 {...props} />;
      if (name === 'CategoryWidget5') return <CategoryWidget1 {...props} />;
      if (name === 'CategoryWidget6') return <CategoryWidget2 {...props} />;
      if (name === 'CategoryWidget7') return <CategoryWidget7 {...props} />;
      if (name === 'CategoryWidget8') return <CategoryWidget8 {...props} />;
      if (name === 'CategoryWidget9') return <CategoryWidget9 {...props} />;
      if (name === 'CategoryWidget10') return <CategoryWidget10 {...props} />;
      if (name === 'CategoryWidget11') return <CategoryWidget11 {...props} />;
      if (name === 'CategoryWidget12') return <CategoryWidget12 {...props} />;
      if (name === 'CategoryWidget13') return <CategoryWidget13 {...props} />;
      if (name === 'CategoryWidget14')
        return <CategoryWidget14 {...props} headerFixedToTop />;
      if (name === 'CategoryWidget15') return <CategoryWidget15 {...props} />;
      if (name === 'CategoryWidget16')
        return <CategoryWidget16 {...props} headerFixedToTop />;
      return <></>;
    case 'CategoryProductWidget':
      return <CategoryProducsDisplayWidget {...props} widgetName={name} />;
    case 'CategoryHeaderWidget':
      if (name === 'CategoryHeader1') return <CategoryHeader1 {...props} />;
      if (name === 'CategoryHeader2') return <CategoryHeader2 {...props} />;
      if (name === 'CategoryHeader3') return <CategoryHeader3 {...props} />;
      if (name === 'CategoryHeader4') return <CategoryHeader3 {...props} />;
      if (name === 'CategoryHeader5') return <CategoryHeader3 {...props} />;
      return <></>;

    case 'AdditionalPageWidget':
      return <AdditionalPage {...props} source={'home'}></AdditionalPage>;
    case 'CollectionWidget':
      if (name === 'CollectionWidget1')
        return <CollectionWidget1 {...props} cardType={1} />;
      if (name === 'CollectionWidget2')
        return <CollectionWidget1 {...props} cardType={2} />;
      if (name === 'CollectionWidget3')
        return <CollectionWidget1 {...props} cardType={1} />;
    case 'CollectionCategoryProductWidget':
      if (name === 'CollectionCategoryProductWidget1')
        return <CollectionCategoryProductWidget1 {...props} />;
    case 'JsonPageWidget':
      return <JsonPageBuilderWidget {...props} />;

    /* Modern Theme Components - Brand Websites */

    case 'ModernHeroBanner':
      if (name === 'ModernHeroBanner1') return <ModernHeroBanner1 {...props} />;
      if (name === 'ModernHeroBanner2') return <ModernHeroBanner2 {...props} />;
      if (name === 'ModernHeroBanner3') return <ModernHeroBanner3 {...props} />;
      if (name === 'ModernHeroBanner4') return <ModernHeroBanner4 {...props} />;
      if (name === 'ModernHeroBanner5') return <ModernHeroBanner5 {...props} />;
      if (name === 'ModernHeroBanner6') return <ModernHeroBanner6 {...props} />;
      return <></>;

    case 'ModernMiddleBodyColumn':
      if (name === 'ModernMiddleBodyColumn1')
        return <ModernMiddleBodyColumn1 {...props} />;
      if (name === 'ModernMiddleBodyColumn2')
        return <ModernMiddleBodyColumn2 {...props} />;
      if (name === 'ModernMiddleBodyColumn3')
        return <ModernMiddleBodyColumn3 {...props} />;
      if (name === 'ModernMiddleBodyColumn4')
        return <ModernMiddleBodyColumn4 {...props} />;
      if (name === 'ModernMiddleBodyColumn5')
        return <ModernMiddleBodyColumn5 {...props} />;
      if (name === 'ModernMiddleBodyColumn6')
        return <ModernMiddleBodyColumn6 {...props} />;
      return <></>;

    case 'ModernMiddleBodyGallery':
      if (name === 'ModernMiddleBodyGallery1')
        return <ModernMiddleBodyGallery1 {...props} />;
      if (name === 'ModernMiddleBodyGallery2')
        return <ModernMiddleBodyGallery2 {...props} />;
      if (name === 'ModernMiddleBodyGallery3')
        return <ModernMiddleBodyGallery3 {...props} />;
      if (name === 'ModernMiddleBodyGallery4')
        return <ModernMiddleBodyGallery4 {...props} />;
      if (name === 'ModernMiddleBodyGallery5')
        return <ModernMiddleBodyGallery5 {...props} />;
      if (name === 'ModernMiddleBodyGallery6')
        return <ModernMiddleBodyGallery6 {...props} />;
      if (name === 'ModernMiddleBodyGallery7')
        return <ModernMiddleBodyGallery7 {...props} />;
      if (name === 'ModernMiddleBodyGallery8')
        return <ModernMiddleBodyGallery8 {...props} />;
      return <></>;

    case 'ModernMiddleBodyTabs':
      if (name === 'ModernMiddleBodyTab1') return <ModernMiddleBodyTab1 {...props} />;
      if (name === 'ModernMiddleBodyTab2') return <ModernMiddleBodyTab2 {...props} />;
      if (name === 'ModernMiddleBodyTab3') return <ModernMiddleBodyTab3 {...props} />;
      return <></>;

    case 'ModernHeader':
      return <ModernHeader1 {...props} />;

    case 'ModernFooter':
      if (name === 'ModernFooter1') return <ModernFooter1 {...props} />;
      if (name === 'ModernFooter2') return <ModernFooter2 {...props} />;
      if (name === 'ModernFooter3') return <ModernFooter3 {...props} />;
      if (name === 'ModernFooter4') return <ModernFooter4 {...props} />;
      return <></>;

    case 'ModernMiddleBodyMaps':
      return <ModernMiddleBodyMaps {...props} />;
    default:
      return <></>;
  }
};
