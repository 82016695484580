import { RegisteredWidget } from '@dotpe/kui/styles/registerWidgetForDnD';
import { INITIALIZE_JSON_WIDGETS_DATA } from '../actionTypes';

interface IJSONWidgetsReducer {
  widgets: Record<string, any>;
  isWidgetLoaded: boolean;
  widgetTemplateLibraryMap: Record<string, RegisteredWidget>;
  widgetTemplateLibraryList: Array<RegisteredWidget>;
  widgetsTemplateConfigs: any;
}

const initialState: IJSONWidgetsReducer = {
  widgets: {},
  isWidgetLoaded: false,
  widgetTemplateLibraryMap: {},
  widgetTemplateLibraryList: [],
  widgetsTemplateConfigs: {},
};

const widgetsReducer = (state: IJSONWidgetsReducer = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_JSON_WIDGETS_DATA:
      return { ...state, ...(action.data as IJSONWidgetsReducer) };
    default:
      return state;
  }
};

export default widgetsReducer;
