export const INDIA_COUNTRY_OBJECT = {
  id: 103,
  country: 'India',
  aplha_2_code: '',
  alpha_3_code: '',
};

export const VALIDATION_TYPES = {
  EMAIL: 'email',
  ALPHA_NUMERIC: 'alpha_numeric',
  ALPHA_NUMERIC_INTERNATIONAL_PINCODE: 'alpha_numeric_international_pincode',
  ALPHA_SPACE: 'alpha_space',
  ADDRESS: 'address',
  NUMERIC: 'numeric',
};

export const FIELDS_ID = {
  Pincode: 'pincodeInput',
  Landmark: 'landmarkInput',
  Address1: 'address1Input',
  Address2: 'address2Input',
  Name: 'userNameInput',
  City: 'cityInput',
  State: 'stateInput',
  EmailId: 'emailIdInput',
  AlternatePhone: 'alternatePhone',
  Username: 'userNameInput',
  Age: 'ageInput',
  Gender: 'genderInput',
};

export const ADDRESS_TYPES = {
  HOME: 'home',
  WORK: 'work',
  OTHERS: 'others',
};

export const ADDRESS_NAMES = {
  HOME: 'Home',
  WORK: 'Work',
  OTHERS: 'Others',
};
