import { isEmpty } from 'lodash';
import { GLOBAL_STYLE } from 'src/constants/globalConfigs';
import { checkIfObjectExists } from 'src/utils/checkIfObjectExists';

export const getGLobalStyleEssentials = (storeData) => {
  const globalConfig = storeData?.theme?.style;
  const globalStyles =
    globalConfig && !isEmpty(globalConfig) ? globalConfig : { fonts: GLOBAL_STYLE.fonts };
  const fontStyles = globalStyles?.fonts;
  const fontURls: any = fontStyles
    ? Object.values(fontStyles)?.reduce<any>((acc, item: any) => {
        if (checkIfObjectExists(item)) {
          if (!acc.find((oldList) => oldList.fontCdn === item.fontCdn))
            acc.push({ fontFamily: item.fontFamily, fontCdn: item.fontCdn });
        }
        return acc;
      }, [])
    : [];
  if (!fontURls.length) {
    fontURls.push({
      fontFamily: 'Inter',
      fontCdn:
        'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
    });
  }

  return { fontURls, fontStyles };
};
