import { RegisteredWidget } from '@dotpe/kui/styles/registerWidgetForDnD';
import { HEADER_CONFIG } from './WidgetProperties/HeaderBuilder/NewHeaderConfig';
import { cloneDeep, merge } from 'lodash';
import { NewHeaderComponent } from '../../DesignSystem/Widgets2.0/Header';
import { ADMIN_WIDGETS } from 'src/constants/widgets';
import { GLOBAL_STYLE } from 'src/constants/globalConfigs';

export const getWidgetsGroups = (widgets: RegisteredWidget<Record<string, any>>[]) => {
  const widgetGroups: string[] = [];
  widgets.forEach((widget) => {
    const widgetGroup = widget.group || widget.type;
    if (!widgetGroups.find((group) => group === widgetGroup)) {
      widgetGroups.push(widgetGroup);
    }
  });
  return widgetGroups;
};

export function getBuilderReadyHeaderElement(newHeaderComponent) {
  if (newHeaderComponent?.config) {
    for (const key in HEADER_CONFIG) {
      if (key) {
        if (key === 'globalClass') {
          newHeaderComponent.config[key] = merge(
            HEADER_CONFIG[key],
            newHeaderComponent.config[key]
          );
        } else {
          if (HEADER_CONFIG[key] && !newHeaderComponent?.config[key]) {
            newHeaderComponent.config[key] = HEADER_CONFIG[key];
          }
          if (HEADER_CONFIG[key]?.leftSection_config) {
            newHeaderComponent.config[key].leftSection_config =
              HEADER_CONFIG[key].leftSection_config;
          }
          if (HEADER_CONFIG[key]?.leftSectionConfig) {
            newHeaderComponent.config[key].leftSectionConfig =
              HEADER_CONFIG[key].leftSectionConfig;
          }
          // if (HEADER_CONFIG[key]?.componentData) {
          //   newHeaderComponent.config[key].componentData = HEADER_CONFIG[key].componentData;
          // }
          if (key === 'navIcons_section') {
            newHeaderComponent.config[key].componentData =
              HEADER_CONFIG[key]?.componentData;
          }
        }
      }
    }
  }
  return newHeaderComponent;
}

export function getCFEHeaderElementStructure(headerElProps) {
  const headerElementProps = cloneDeep(headerElProps);
  if (headerElementProps) {
    for (const key in headerElementProps) {
      if (key) {
        if (headerElementProps[key]?.leftSection_config) {
          delete headerElementProps[key].leftSection_config;
        }
        if (headerElementProps[key]?.leftSectionConfig) {
          delete headerElementProps[key].leftSectionConfig;
        }
        // if (headerElementProps[key]?.componentData) {
        //   delete headerElementProps[key].componentData;
        // }
        if (key === 'globalClass') {
          delete headerElementProps[key];
        }
      }
    }
  }
  return headerElementProps;
}

export function generateHeaderPropsConfig(headerComponents): any {
  const currentHeaderOnStore = headerComponents?.[0] || {};
  let headerPropsConfig = {};

  if (currentHeaderOnStore?.name === ADMIN_WIDGETS.HEADER.type) {
    headerPropsConfig = currentHeaderOnStore?.config
      ? {
          ...currentHeaderOnStore?.config,
          widget_id: currentHeaderOnStore?.widget_id,
          sub_type: 'Header',
        }
      : {
          ...HEADER_CONFIG,
          widget_id: currentHeaderOnStore?.widget_id,
          sub_type: 'Header',
        };
  } else {
    headerPropsConfig = {
      ...currentHeaderOnStore,
      ...(currentHeaderOnStore?.config || {}),
    };
  }
  return headerPropsConfig;
}

export function getHeaderElement(headerComponents, headerElement, headerPropsConfig) {
  return headerComponents[0]?.name === ADMIN_WIDGETS.HEADER.type
    ? [
        // eslint-disable-next-line
        <NewHeaderComponent
          {...{
            sub_type: 'Header',
            name: ADMIN_WIDGETS.HEADER.type,
            config: headerPropsConfig,
          }}
        />,
      ]
    : headerElement;
}

export function getUpdatedGlobalSetting(globalStyle, globalSetting) {
  globalSetting.fonts.base_config = {
    ...globalStyle?.fonts,
    selectedId: globalStyle?.fonts?.id || '',
  };
  globalSetting.appearance.base_config = {
    btnType: globalStyle?.buttons?.borderType || '',
    btnRadius: extractNumberFromString(globalStyle?.buttons?.style?.borderRadius),
    inputRadius: extractNumberFromString(globalStyle?.inputs?.style?.borderRadius),
    imageCardRadius: extractNumberFromString(
      globalStyle?.imageCards?.style?.borderRadius
    ),
    isEnabled: globalStyle?.imageCards?.isEnabled,
  };
  globalSetting.loader.base_config = globalStyle?.appLoader;
  globalSetting.chevrons.base_config = {
    ...GLOBAL_STYLE.chevrons,
    chevronId: globalStyle?.chevrons?.selected,
    isEnabled: globalStyle?.chevrons?.isEnabled,
  };
  return globalSetting;
}

function extractNumberFromString(value) {
  if (typeof value === 'number') {
    return value;
  }
  if (typeof value === 'string') {
    const match = value.match(/-?\d+(\.\d+)?/);
    return match ? parseInt(match[0]) : null;
  }

  return null;
}
