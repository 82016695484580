import React from 'react';
import { checkIfObjectExists } from 'src/utils/checkIfObjectExists';
import { getTagsWrapperStyles } from '../utils';
import classNames from 'classnames';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { removeEmojisFromTagName } from 'src/utils/removeEmojis';

export function Tags({ isMobile, productData, config }) {
  const {
    widgetContextState: { customTags, isBuilder },
  } = useWidgetDndContextData();
  const productTagsList = productData?.tags || [];

  if (!productTagsList.length || !config?.showTag) return null;

  const getTagConfig = (tag) => {
    const doesCustomTagExists = checkIfObjectExists(tag.custom_config);
    const fallbackConfig = config?.tagAppearance !== 'default' ? config || tag : tag;

    const tagConfigs =
      config?.tagAppearance === 'default'
        ? tag
        : doesCustomTagExists
          ? tag.custom_config
          : fallbackConfig;
    if (isBuilder) {
      const globalCounterpart = customTags?.find(
        (globalTag) => globalTag?.id === tag?.id
      );
      const doesCustomTagExistsInGlobal = checkIfObjectExists(
        globalCounterpart?.custom_config
      );
      return config?.tagAppearance === 'default'
        ? tagConfigs
        : doesCustomTagExistsInGlobal
          ? globalCounterpart?.custom_config
          : tagConfigs;
    }
    return tagConfigs;
  };

  return (
    <div className="tw-inline-flex tw-w-max tw-flex-col tw-flex-wrap tw-gap-[4px]">
      {productTagsList.map((tag, idx) => {
        const tagsTintConfigs = getTagConfig(tag);
        const tagsWrapperStyles = getTagsWrapperStyles(config, tagsTintConfigs);
        const tagName =
          config?.tagAppearance !== 'default'
            ? removeEmojisFromTagName(tag?.name)
            : tag?.name;

        return (
          <div
            key={tag.id || idx}
            className={classNames(
              'tw-relative tw-w-max tw-px-[6px] tw-py-[4px] tw-text-[11px] tw-font-medium ',
              tagsWrapperStyles.className
            )}
            style={{
              ...tagsWrapperStyles.style,
            }}
          >
            {tagsWrapperStyles.ChildComponent}
            <span>{tagName}</span>
          </div>
        );
      })}
    </div>
  );
}
