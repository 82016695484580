import { DynamicComponent } from './dynamicComponent';
import { btnReset } from '@/utils/constants';
import { useSSRSelector } from '@/redux/ssrStore';
import LoginComponent from '@/components/LoginAndDropdownComponent';
import { getIconSizeAccToConfig } from './getSizeAccType';
import { useGetDeviceType } from '@/hooks/useGetDeviceType';
import { useRouter } from 'next/router';
import { getRoute } from '@/utils/routes';
import classNames from 'classnames';

import { HeaderButtonsLayout } from './subComponents/HeaderButtonsLayout';
import { getWhatAppRedirectionUrlHelp } from '@/utils/getWhatsAppRedirectionUrl';

const getIcon = (type: string, size, color) => {
  switch (type) {
    case 'loginLogout': {
      return (
        <svg
          width={size}
          height={size}
          viewBox={`0 0 28 28`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M23.3332 24.5V22.1667C23.3332 20.929 22.8415 19.742 21.9663 18.8668C21.0912 17.9917 19.9042 17.5 18.6665 17.5H9.33317C8.09549 17.5 6.90851 17.9917 6.03334 18.8668C5.15817 19.742 4.6665 20.929 4.6665 22.1667V24.5M18.6665 8.16667C18.6665 10.744 16.5772 12.8333 13.9998 12.8333C11.4225 12.8333 9.33317 10.744 9.33317 8.16667C9.33317 5.58934 11.4225 3.5 13.9998 3.5C16.5772 3.5 18.6665 5.58934 18.6665 8.16667Z"
            stroke={color}
            stroke-width="1.4"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    }
    case 'cart': {
      return (
        <svg
          width={size}
          height={size}
          viewBox={`0 0 28 28`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.55029 7.7L7.70029 3.5H20.3003L23.4503 7.7M4.55029 7.7V22.4C4.55029 22.957 4.77154 23.4911 5.16537 23.8849C5.5592 24.2787 6.09334 24.5 6.65029 24.5H21.3503C21.9072 24.5 22.4414 24.2787 22.8352 23.8849C23.229 23.4911 23.4503 22.957 23.4503 22.4V7.7M4.55029 7.7H23.4503M18.2003 11.9C18.2003 13.0139 17.7578 14.0822 16.9701 14.8698C16.1825 15.6575 15.1142 16.1 14.0003 16.1C12.8864 16.1 11.8181 15.6575 11.0304 14.8698C10.2428 14.0822 9.80029 13.0139 9.80029 11.9"
            stroke={color}
            stroke-width="1.4"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
        </svg>
      );
    }
    case 'contact': {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={size}
          height={size}
          viewBox="0 0 28 28"
          fill="none"
        >
          <path
            d="M24.4991 13.9666C24.4628 11.206 23.341 8.59728 21.3405 6.62107C19.3031 4.60844 16.6138 3.5 13.7678 3.5C11.0205 3.5 8.45041 4.54427 6.5309 6.44038C4.82756 8.12301 3.78253 10.3672 3.58814 12.7595C3.40378 15.0293 3.98871 17.3263 5.23775 19.2576L3.53214 23.8119C3.46056 24.003 3.51083 24.218 3.66013 24.3587C3.75823 24.4512 3.88674 24.5 4.01726 24.5C4.08532 24.5 4.15399 24.4805 4.2191 24.4532L8.66124 22.5859C10.357 23.6285 12.2961 24.1659 14.2872 24.1659C17.034 24.1659 19.6042 23.134 21.5234 21.2381C23.4786 19.3067 24.5354 16.7323 24.4991 13.9666ZM20.792 20.5281C19.068 22.2312 16.7578 23.169 14.2871 23.169C12.4061 23.1689 10.5761 22.6251 8.9948 21.5961C8.90905 21.5404 8.80997 21.512 8.71032 21.512C8.6419 21.512 8.57323 21.5253 8.50843 21.5525L4.91765 23.057L6.2984 19.3701C6.35616 19.2158 6.33517 19.0434 6.24203 18.9071C3.70414 15.193 4.1332 10.2538 7.26224 7.16287C8.98637 5.45971 11.2967 4.52169 13.7678 4.52169C16.3375 4.52169 18.7671 5.52382 20.6091 7.34351C22.4179 9.13025 23.4321 11.4871 23.4649 13.9799C23.4976 16.4676 22.5484 18.7932 20.792 20.5281Z"
            fill={color}
          />
          <path
            d="M16.5124 15.8314C16.3512 15.8562 16.2481 16.0114 16.0869 16.2535C15.9193 16.5143 15.7259 16.8123 15.3519 16.9116C15.1521 16.9675 14.7781 16.9675 13.8949 16.3591C13.3727 15.999 12.7538 15.4775 12.1993 14.9311C11.3096 14.0557 11.2967 13.8385 11.2967 13.7577C11.2774 13.3977 11.574 13.0997 11.8383 12.8389C11.9995 12.6837 12.1606 12.5223 12.2122 12.3857C12.2316 12.2864 12.0962 11.8393 11.7287 11.2061C11.3612 10.5728 11.0324 10.2252 10.9293 10.1879C10.9164 10.1817 10.8906 10.1817 10.8648 10.1817C10.6778 10.1817 10.2201 10.2872 9.82036 10.6411C9.40775 11.0136 8.99514 11.7338 9.37551 13.0376C10.0782 15.4651 12.2896 17.4269 15.6034 18.5506C16.4802 18.8486 17.2022 18.8486 17.7373 18.5382C18.4852 18.1036 18.7109 17.191 18.7689 16.8185C18.7753 16.7874 18.7624 16.7564 18.7431 16.7316C18.2982 16.2846 16.9186 15.7631 16.5124 15.8314Z"
            fill={color}
          />
          <path
            d="M24.4991 13.9666C24.4628 11.206 23.341 8.59728 21.3405 6.62107C19.3031 4.60844 16.6138 3.5 13.7678 3.5C11.0205 3.5 8.45041 4.54427 6.5309 6.44038C4.82756 8.12301 3.78253 10.3672 3.58814 12.7595C3.40378 15.0293 3.98871 17.3263 5.23775 19.2576L3.53214 23.8119C3.46056 24.003 3.51083 24.218 3.66013 24.3587C3.75823 24.4512 3.88674 24.5 4.01726 24.5C4.08532 24.5 4.15399 24.4805 4.2191 24.4532L8.66124 22.5859C10.357 23.6285 12.2961 24.1659 14.2872 24.1659C17.034 24.1659 19.6042 23.134 21.5234 21.2381C23.4786 19.3067 24.5354 16.7323 24.4991 13.9666ZM20.792 20.5281C19.068 22.2312 16.7578 23.169 14.2871 23.169C12.4061 23.1689 10.5761 22.6251 8.9948 21.5961C8.90905 21.5404 8.80997 21.512 8.71032 21.512C8.6419 21.512 8.57323 21.5253 8.50843 21.5525L4.91765 23.057L6.2984 19.3701C6.35616 19.2158 6.33517 19.0434 6.24203 18.9071C3.70414 15.193 4.1332 10.2538 7.26224 7.16287C8.98637 5.45971 11.2967 4.52169 13.7678 4.52169C16.3375 4.52169 18.7671 5.52382 20.6091 7.34351C22.4179 9.13025 23.4321 11.4871 23.4649 13.9799C23.4976 16.4676 22.5484 18.7932 20.792 20.5281Z"
            stroke={color}
            stroke-width="0.2"
          />
          <path
            d="M16.5124 15.8314C16.3512 15.8562 16.2481 16.0114 16.0869 16.2535C15.9193 16.5143 15.7259 16.8123 15.3519 16.9116C15.1521 16.9675 14.7781 16.9675 13.8949 16.3591C13.3727 15.999 12.7538 15.4775 12.1993 14.9311C11.3096 14.0557 11.2967 13.8385 11.2967 13.7577C11.2774 13.3977 11.574 13.0997 11.8383 12.8389C11.9995 12.6837 12.1606 12.5223 12.2122 12.3857C12.2316 12.2864 12.0962 11.8393 11.7287 11.2061C11.3612 10.5728 11.0324 10.2252 10.9293 10.1879C10.9164 10.1817 10.8906 10.1817 10.8648 10.1817C10.6778 10.1817 10.2201 10.2872 9.82036 10.6411C9.40775 11.0136 8.99514 11.7338 9.37551 13.0376C10.0782 15.4651 12.2896 17.4269 15.6034 18.5506C16.4802 18.8486 17.2022 18.8486 17.7373 18.5382C18.4852 18.1036 18.7109 17.191 18.7689 16.8185C18.7753 16.7874 18.7624 16.7564 18.7431 16.7316C18.2982 16.2846 16.9186 15.7631 16.5124 15.8314Z"
            stroke={color}
            stroke-width="0.2"
          />
        </svg>
      );
    }
    default:
      return null;
  }
};

export const NavIconRenderer = (props: any) => {
  const router = useRouter();
  const {
    componentData,
    showDynamicSection = false,
    currentClass,
    globalClass,
    activeHeaderData,
  } = props;
  const { storeData, items } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    items: state.cartReducer.items,
    // user: state.additionalPagesReducer.navigationList,
  }));
  const {
    base_config: { defaultHoverIconState },
  } = currentClass;
  const canRenderText = componentData?.appearanceType?.includes('text');
  const canRenderImage = componentData?.appearanceType?.includes('icon');
  const { deviceType } = useGetDeviceType();
  const isMobile = deviceType === 'mobile';

  const iconRenderList = componentData?.iconsList?.reduce((total, item) => {
    const { name, visibility } = item;
    const visibilityDeviceKey = isMobile ? 'mobileHeader' : 'desktopHeader';
    // Equality for false is used to handles 'undefined' case, where the visibility config may not be present in initially saved config, so icon will be shown in that case
    let showIcon = visibility?.[visibilityDeviceKey] === false ? false : true;
    // On mobile icon can only be visible in hamburger menu or mobile header at a time
    if (isMobile && visibility?.['hamburger']) showIcon = false;
    if (!showIcon) return total;
    if (name === 'search') return total;
    if (name === 'loginLogout' && isMobile) return total;
    total.push(item);
    return total;
  }, []);

  const navItemClick = (link: string, openInNewTab = false) => {
    if (openInNewTab) {
      window.open(link, '_blank');
    } else {
      router.push(getRoute(link, storeData?.store_info?.domain));
    }
  };

  const getCartNumberPosition = (currentSize) => {
    const updatedCurrSize = Number(currentSize);
    return updatedCurrSize - (updatedCurrSize / 100) * 31;
  };

  const onItemClick = (action) => {
    switch (action.actionType) {
      case 'whatsapp':
        window.open(getWhatAppRedirectionUrlHelp());
        break;
      case 'redirect':
        navItemClick(action.actionData);
        break;
      default:
        break;
    }
  };

  const _RenderImage = (item) => {
    const { ImageComponent: Image, spriteDetail, label, name } = item;
    const desktopIconConfig = globalClass.staticClass.base_config.defaultFontSizeType;
    const mobileIconConfig =
      globalClass.staticClass.base_config.defaultFontSizeTypeMobile;
    const sizeDetails = getIconSizeAccToConfig(
      isMobile ? mobileIconConfig : desktopIconConfig
    );
    // const isTransparent = currentClass.base_config.defaultFillColorType === 'transparent'
    const isFilledWhite =
      currentClass.base_config.defaultBackgroundColor?.toLowerCase() === '#ffffff';

    if (spriteDetail) {
      return (
        <div
          style={{ width: Number(sizeDetails) + 4, height: Number(sizeDetails) + 4 }}
          className={`tw-relative tw-flex tw-items-center tw-justify-center tw-gap-[4px]  ${
            defaultHoverIconState === 'zoomin'
              ? 'tw-transition-transform tw-duration-500 hover:tw-scale-[1.2]'
              : defaultHoverIconState === 'background'
                ? `${!isFilledWhite ? 'hover:tw-bg-[#ffffff4d]' : 'hover:tw-bg-[#111C360F]'} hover:tw-rounded-[5px]`
                : ''
          }`}
        >
          {canRenderImage &&
            getIcon(name, sizeDetails, currentClass?.base_config?.defaultNavLinksColor)}
          {(name === 'cart' && items?.length) > 0 && (
            <div
              style={{
                background: currentClass?.base_config?.defaultNavLinksColor,
                color: currentClass?.base_config?.defaultBackgroundColor,
                bottom: getCartNumberPosition(sizeDetails) + 'px',
                left: getCartNumberPosition(sizeDetails) + 'px',
              }}
              className="tw-absolute tw-h-[20px] tw-w-[20px] tw-rounded-[50%] "
            >
              <span className="tw-text-center tw-text-[12px]">{items?.length}</span>
            </div>
          )}
          {canRenderText && (
            <p className="tw-m-0 tw-text-[10px] tw-uppercase tw-tracking-[1.48px]">
              {label}
            </p>
          )}
        </div>
      );
    }
    if (typeof Image == 'function') {
      return (
        <div
          className={`tw-m-0 tw-flex tw-items-center tw-gap-[4px] tw-p-[5px] ${
            defaultHoverIconState === 'zoomin'
              ? 'tw-transition-transform tw-duration-500 hover:tw-scale-[1.2]'
              : defaultHoverIconState === 'background'
                ? `${!isFilledWhite ? 'hover:tw-bg-[#ffffff4d]' : 'hover:tw-bg-[#111C360F]'} hover:tw-rounded-[5px]`
                : ''
          }`}
        >
          {canRenderImage && (
            <Image width={canRenderText ? 12 : 24} height={canRenderText ? 12 : 24} />
          )}
          {canRenderText && (
            <p className="tw-text-[10px] tw-uppercase tw-leading-[1] tw-tracking-[1.48px]">
              {label}
            </p>
          )}
        </div>
      );
    }
    return (
      <div className={`tw-flex tw-items-center tw-gap-[4px]`}>
        {canRenderImage && (
          <img
            src={Image}
            width={canRenderText ? 12 : 24}
            height={canRenderText ? 12 : 24}
          />
        )}
        {canRenderText && (
          <p className="tw-m-0 tw-text-[10px] tw-uppercase tw-tracking-[1.48px]">
            {label}
          </p>
        )}
      </div>
    );
  };

  return (
    <div
      className={`tw-flex tw-items-center ${isMobile ? 'tw-gap-[16px]' : 'tw-gap-[24px]'}`}
    >
      {showDynamicSection && (
        <div>
          <DynamicComponent currentClass={currentClass} globalClass={globalClass} />
        </div>
      )}
      {!!iconRenderList?.length && (
        <div
          className={
            'tw-flex ' +
            (canRenderText
              ? 'tw-items-center tw-gap-[17px]'
              : isMobile
                ? 'tw-gap-[16px]'
                : 'tw-gap-[24px]')
          }
        >
          {iconRenderList?.map((item, index: number) => {
            const { name, action } = item;
            const StaticComponentRender = (
              <button onClick={() => onItemClick(action)} className={btnReset}>
                {_RenderImage(item)}
              </button>
            );
            return (
              <div key={name + index} className={classNames(`tw-cursor-pointer`)}>
                {action.actionType == 'login-logout' ? (
                  <LoginComponent // headerBgColor={headerBgColor}
                  >
                    {StaticComponentRender}
                  </LoginComponent>
                ) : (
                  StaticComponentRender
                )}
              </div>
            );
          })}
        </div>
      )}
      <HeaderButtonsLayout isMobile={isMobile} activeHeaderData={activeHeaderData} />
    </div>
  );
};
