import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useGetDeviceType } from 'src/hooks/useGetDeviceType';
import { IS_SERVER } from 'src/utils/checkRenderEnv';
import {
  BLOG_SECTION_MAX_WIDTH,
  getArrowMethods,
  getContainerTracks,
  getGridGapsForAxes,
  getOneCellGeometry,
  getRenderCardOverrides,
  INITIAL_SCROLL_PERCENTAGE,
  isLogoSliderAutoScroll,
  isLogoSliderFullWidth,
  MAX_SECTION_WIDTH,
  onCarouselScroll,
} from './utils';
import { useGetIterableItems } from './hooks/useGetIterableItems';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { ListDescriptionSection } from './components/ListDescriptionSection';
import { useGetElementRects } from './hooks/useGetElementRects';
import { useProductCardElements } from '../ProductListWrapper/utils';
import dynamic from 'next/dynamic';
import { FeatureProductCards } from './components/FeatureProductCards';
import { useItemClick } from 'src/hooks/useItemClick';
import eventLogger from 'src/utils/eventLogger';
import { PATH, getRoute } from 'src/utils/routes';
import { EVENT_NAMES } from 'src/constants/common';
import { useSSRSelector } from 'src/redux';
import router from 'next/router';
import { isPLPRequiredSystem } from 'src/utils/isPLPRequiredSystem';
import { Reels } from './components/Reels';
import { RootState, useSelector } from 'src/redux/reducers';
import { BlogComponent, BlogHeroPage } from '../BlogWidget';
import LogoSliderRenderer from './components/LogoSliderComponent';

const CarouselIndicator = dynamic(() =>
  import('../CardFrameCollection/components/CarouselIndicator').then((mod) => mod.default)
);
const CardFrameForCollection = dynamic(() =>
  import('../CardFrameCollection/CardFrameCollection').then((mod) => mod.default)
);
const ViewMoreButton = dynamic(() =>
  import('./components/ViewMoreButton').then((mod) => mod.default)
);
const QuickViewModal = dynamic(() =>
  import('../../QuickViewModal').then((mod) => mod.default)
);
const MultiColumnComponent = dynamic(() =>
  import('../MultiColumn').then((mod) => mod.default)
);

/** Render:
 * 1. Collections card
 * 2. Categories card
 * 3. Featured collection: Product card (This renders Product card, so global styling one is used)
 */
export default function ListPresentation(props) {
  const listPresentationConfig = props?.widgetOptions?.listPresentation;
  const cardFrameConfig = props?.widgetOptions?.cardFrame;
  const sectionContainerRef = useRef(null);
  const itemsContainerRef = useRef(null);
  const {
    widgetContextState: { globalStyle, previewDevice, activeWidgetId, isBuilder },
    widgetContextHelpers: { handleWidgetPropChange },
  } = useWidgetDndContextData();

  const carouselConfigs = globalStyle?.carousel?.config;
  const { blogData } = useSelector((state: RootState) => ({
    blogData:
      state?.additionalPagesReducer?.additionalPageList?.blog?.json_data?.blog?.users_list
        ?.list || props?.blogData,
  }));
  const { deviceType } = useGetDeviceType();
  const { aspectRatio: productCardAspectRatio, isProductCardEnabled } =
    useProductCardElements();
  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));

  const [carouselIndicatorConfigs, setCarouselIndicatorConfigs] = useState({
    scrollPercentage: INITIAL_SCROLL_PERCENTAGE,
    overflowSectionCount: 0,
    currentOverflowSectionIndex: 0,
    direction: 'to-right',
  });
  const [viewAllItemsConfig, setViewAllItemsConfig] = useState({
    showButton: false,
    isViewAllVisible: false,
  });
  const selectedListParent = listPresentationConfig?.contentItems?.listItem?.find(
    (item) => item.isVisible
  );
  const isViewAllBtnToShow = listPresentationConfig?.viewAllItemsButtonConfig?.find(
    (item) => item.isShow
  );

  const contentType = listPresentationConfig?.contentItems?.type;

  const contentTypeConditions = {
    featured: contentType?.includes?.('feature'),
    featuredReels: contentType?.includes?.('featuredReels'),
  };
  const isMobile = isBuilder ? previewDevice === 'mobile' : deviceType === 'mobile';
  const isLayoutCarousel =
    listPresentationConfig?.layoutType === 'carousel' && !cardFrameConfig?.autoScroll;

  // Paddings & margin calculations
  const containerHorizontalPadding = !isMobile ? 40 : !isLayoutCarousel ? 16 : 0;
  const contentHorizontalPadding = !containerHorizontalPadding
    ? !isMobile
      ? 40
      : 20
    : 0;

  let listHorizontalPadding = isLayoutCarousel && isMobile ? 20 : 0;
  let listVerticalPadding = 0;
  const isCardScaleAnimation =
    (cardFrameConfig?.hoverType === 'zoomInCard' && !isMobile) ||
    (cardFrameConfig?.mobileEnlargeAndFocus && isMobile);
  if (isCardScaleAnimation) {
    listVerticalPadding += 20;
    listHorizontalPadding += 20;
  }
  const containerVerticalPadding =
    listPresentationConfig?.[!isMobile ? 'paddingY' : 'mobilePaddingY'] || '65px';
  const containerTopMargin = listPresentationConfig?.marginTop;
  const containerBottomMargin = listPresentationConfig?.marginBottom;
  const iconBasedMultiColumn = listPresentationConfig?.type === 'icon';
  const imageBasedMultiColumn = listPresentationConfig?.type === 'image';
  const isLogoSlider = listPresentationConfig?.type === 'logoSlider';
  const isBlogWidget = listPresentationConfig?.type === 'blog';
  // Section Width calculations
  const SECTION_WIDTH = !IS_SERVER
    ? sectionContainerRef.current?.clientWidth -
      (props?.isBlogListingPage ? 0 : containerHorizontalPadding * 2)
    : isMobile
      ? 480
      : 720;
  const featuredReelConfigs = {
    expandFocusedReel:
      isMobile &&
      contentTypeConditions.featuredReels &&
      cardFrameConfig?.mobileEnlargeAndFocus,
  };

  // Item gaps
  const gridGapAxis = getGridGapsForAxes({
    isMobile,
    cardFrameConfig,
    listPresentationConfig,
    type: listPresentationConfig?.type,
    isLayoutTypeCard: listPresentationConfig?.appearance === 'card',
    expandSelectedReel: featuredReelConfigs.expandFocusedReel,
  });
  const aspectRatio = cardFrameConfig?.aspectRatio || '1:1';
  const sectionColors =
    listPresentationConfig?.colors?.colorSchemes?.[
      listPresentationConfig?.colors?.selectedColorSchemeIndex
    ];

  const showCarouselIndicators =
    isLayoutCarousel &&
    // (itemCountTotal > containerTracks?.numColumns ||
    itemsContainerRef.current?.clientWidth < itemsContainerRef.current?.scrollWidth;
  const showArrow = isLayoutCarousel
    ? isMobile
      ? carouselConfigs?.showArrowInMobile && showCarouselIndicators
      : showCarouselIndicators
    : false;
  const slideOffsetMultiplier = {
    forWidth: isMobile ? 1 : 2,
    forGap: isMobile ? 1 : 2,
  };
  const isMultiColumnWidget =
    listPresentationConfig?.type === 'icon' || listPresentationConfig?.type === 'image';
  const [onItemClick] = useItemClick();

  const { iterableList, moreItemsAvailable } = useGetIterableItems({
    builderCollections: listPresentationConfig?.contentItems?.listItem,
    contentType,
    removeNotVisibleItems: true,
    dependency: [listPresentationConfig?.contentItems],
  });
  //
  const itemCountTotal = getItemCount();
  const shouldAutoScrollSlider = isLogoSliderAutoScroll({
    cardFrameConfig,
    listPresentationConfig,
  });
  const isSliderFullWidth = isLogoSliderFullWidth({
    cardFrameConfig,
    listPresentationConfig,
  });

  // Returns number of columns & rows
  const containerTracks = getContainerTracks({
    isLayoutCarousel,
    itemCount: itemCountTotal,
    isMobile,
    configData: listPresentationConfig,
    expandSelectedReel: featuredReelConfigs.expandFocusedReel,
  });
  const numItemsToShow = containerTracks.numColumns * containerTracks.numRows;

  const [itemContainerLayoutChange] = useGetElementRects({
    elementRef: itemsContainerRef,
  });

  useEffect(() => {
    if (itemsContainerRef.current && iterableList?.length) {
      setTimeout(() => {
        const { clientWidth, scrollWidth } = itemsContainerRef.current || {};

        const sectionScrollWidth = Math.ceil(
          (iterableList?.length - containerTracks.numColumns) /
            slideOffsetMultiplier.forWidth +
            (!isMobile ? 1 : 0)
        );

        if (scrollWidth > clientWidth) {
          setCarouselIndicatorConfigs((data) => ({
            ...data,
            overflowSectionCount: sectionScrollWidth,
            // overflowSectionCount: Math.ceil(scrollWidth / clientWidth),
            currentOverflowSectionIndex: 1,
          }));
        }
      }, 500);
    }
  }, [
    iterableList,
    isLayoutCarousel,
    isMobile,
    listPresentationConfig?.progressIndicator,
    previewDevice,
    containerTracks.numColumns,
    slideOffsetMultiplier.forWidth,
  ]);

  useEffect(() => {
    if (isLayoutCarousel && moreItemsAvailable) {
      setViewAllItemsConfig({
        isViewAllVisible: true,
        showButton: moreItemsAvailable,
      });
    } else if (numItemsToShow < itemCountTotal) {
      setViewAllItemsConfig({ isViewAllVisible: false, showButton: true });
    } else if (isLogoSlider) {
      setViewAllItemsConfig({ isViewAllVisible: false, showButton: true });
    } else {
      setViewAllItemsConfig({ isViewAllVisible: true, showButton: false });
    }
  }, [itemCountTotal, isLayoutCarousel, numItemsToShow, previewDevice]);

  function getItemCount() {
    if (iconBasedMultiColumn || imageBasedMultiColumn) {
      return listPresentationConfig?.numColumnsDesktop;
    } else if (isLogoSlider) {
      const visibleLogo = cardFrameConfig?.multiColumnData?.filter(
        (data) => data?.isVisible
      );
      return visibleLogo?.length;
    } else {
      iterableList?.length;
    }
  }

  // Remaining width from section after removing gaps & x-padding
  const cardContainerWidthForEachCell =
    SECTION_WIDTH +
    gridGapAxis.columnGap -
    containerTracks.numColumns * gridGapAxis.columnGap -
    2 * listHorizontalPadding;

  const widthAdditionals =
    isLayoutCarousel && itemCountTotal > containerTracks.numColumns && isMobile
      ? -containerTracks.numColumns * 6
      : 0;

  // Width for each column (product item)
  const oneGridCellGeometry = getOneCellGeometry({
    cardContainerWidthForEachCell,
    containerTracks,
    aspectRatio:
      !contentTypeConditions.featured || contentTypeConditions.featuredReels
        ? aspectRatio
        : productCardAspectRatio,
    widthAdditionals,
    type: listPresentationConfig?.type || '',
    isMobile,
  });

  const slideOffset =
    oneGridCellGeometry.width * slideOffsetMultiplier.forWidth +
    gridGapAxis.columnGap * slideOffsetMultiplier.forGap;

  const { onNextArrowClick, onPrevArrowClick } = getArrowMethods(
    itemsContainerRef,
    slideOffset
  );

  function handleHeadingClick() {
    if ((isMultiColumnWidget || isLogoSlider) && isBuilder) {
      handleWidgetPropChange(
        {
          subWidget: '',
          subWidgetIndex: -1,
        },
        activeWidgetId
      );
    }
  }

  const onCollectionClick = (type, data, e, openInNewTab) => {
    e.stopPropagation();
    switch (type) {
      case 'product': {
        onItemClick(data, e, openInNewTab);
        break;
      }
      case 'collection': {
        eventLogger(
          EVENT_NAMES.COLLECTION_WIDGET_CARD_CLICK,
          { collectionId: data.id },
          true
        );
        const link = getRoute(PATH.collectionCategories(data.id), storeData?.domain);
        !openInNewTab ? router.push(link) : window.open(link, '_blank');
        break;
      }
      case 'category': {
        const redirectUrl = getRoute(
          `${
            isPLPRequiredSystem(storeData?.theme?.theme_class) ? PATH.SHOP : PATH.PRODUCT
          }?cid=${data?.id || 0}`,
          storeData?.store_info?.domain
        );
        if (openInNewTab) {
          window.open(redirectUrl, '_blank');
        } else {
          router.push(redirectUrl, undefined, { shallow: true });
        }
        break;
      }
    }
  };

  const _RenderViewMoreButton = (isVisble) => {
    return (
      <ViewMoreButton
        listPresentationConfig={listPresentationConfig}
        viewAllItemsConfig={viewAllItemsConfig}
        setViewAllItemsConfig={setViewAllItemsConfig}
        cardFrameConfig={cardFrameConfig}
        selectedListParent={selectedListParent}
        onCollectionClick={onCollectionClick}
        isVisible={isVisble}
      />
    );
  };

  function getBlogData(blogList) {
    const simplifiedBlogData = blogList?.map((data) => {
      return {
        imageDetail: {
          url: data?.image?.url || '',
        },
        title: data?.heading?.display_text || '',
        description: data?.description?.display_text || '',
        date:
          data?.date?.selected_date + ' ' + data?.date?.selected_month?.slice(0, 3) || '',
      };
    });
    return simplifiedBlogData;
  }

  function _RenderCards() {
    if (contentTypeConditions.featuredReels) {
      return (
        <Reels
          cardFrameConfig={cardFrameConfig}
          oneGridCellGeometry={oneGridCellGeometry}
          listPresentationConfig={listPresentationConfig}
          isBuilder={isBuilder}
          isMobile={isMobile}
          featuredReelConfigs={featuredReelConfigs}
          carouselIndicatorConfigs={carouselIndicatorConfigs}
        />
      );
    }
    if (isLogoSlider) {
      return (
        <LogoSliderRenderer
          cardFrameConfig={cardFrameConfig}
          listPresentationConfig={listPresentationConfig}
          gridGapAxis={gridGapAxis}
          sectionContainerRef={sectionContainerRef}
          oneGridCellGeometry={oneGridCellGeometry}
          isMobile={isMobile}
          sectionColors={sectionColors}
          itemCountTotal={itemCountTotal}
          isSliderFullWidth={isSliderFullWidth}
        />
      );
    }
    if (isBlogWidget) {
      // avoid blog at index 0, as it is being used as hero blog
      const simplifiedBlogData = getBlogData(blogData);
      return simplifiedBlogData?.map(
        (columnData, index) =>
          !!index && (
            <BlogComponent
              key={index}
              index={index}
              columnData={columnData}
              oneGridCellGeometry={oneGridCellGeometry}
              listPresentationConfig={listPresentationConfig}
              cardFrameConfig={cardFrameConfig}
              sectionColors={sectionColors}
            />
          )
      );
    }

    if (!(iconBasedMultiColumn || imageBasedMultiColumn)) {
      const listToRender = iterableList?.slice(
        0,
        viewAllItemsConfig?.isViewAllVisible ? itemCountTotal : numItemsToShow
      );
      if (!listToRender?.length) {
        return null;
      }
      return listToRender.map((data, index) => {
        if (['featuredCollection', 'featuredTag'].includes(contentType)) {
          const textColorOverride = getRenderCardOverrides({
            listPresentationConfig,
            sectionColors,
          });
          return (
            <FeatureProductCards
              key={index}
              isProductCardEnabled={isProductCardEnabled}
              data={data}
              index={index}
              oneGridCellGeometry={oneGridCellGeometry}
              isMobile={isMobile}
              sectionColorsOverrides={{ textColor: textColorOverride }}
              customClass={'!tw-h-auto !tw-w-min'}
            />
          );
        }
        return (
          <CardFrameForCollection
            key={index}
            itemConfig={data}
            itemIndex={index}
            containerTracks={containerTracks}
            cardFrameConfig={cardFrameConfig}
            oneGridCellGeometry={oneGridCellGeometry}
            openInNewTab={listPresentationConfig?.openInNewTab}
            isMobile={isMobile}
            onCollectionClick={onCollectionClick}
          />
        );
      });
    }
    // for multi-column
    const listToRender = cardFrameConfig?.multiColumnData;
    if (!listToRender?.length) {
      return null;
    }
    return listToRender?.map((columnData, index) =>
      columnData?.isVisible ? (
        <MultiColumnComponent
          key={index}
          index={index}
          columnData={columnData}
          oneGridCellGeometry={oneGridCellGeometry}
          listPresentationConfig={listPresentationConfig}
          cardFrameConfig={cardFrameConfig}
          sectionColors={sectionColors}
        />
      ) : null
    );
  }

  function getSectionStyles(): React.CSSProperties {
    return {
      padding: props?.isBlogListingPage
        ? 0
        : `${containerVerticalPadding}px ${isSliderFullWidth ? 0 : containerHorizontalPadding}px`,
      margin: `${containerTopMargin}px ${isSliderFullWidth ? '' : 'auto'} ${containerBottomMargin}px ${isSliderFullWidth ? '' : 'auto'}`,
      background: sectionColors?.background,
      maxWidth: isSliderFullWidth
        ? '100vw'
        : `${props?.isBlogListingPage ? BLOG_SECTION_MAX_WIDTH : MAX_SECTION_WIDTH}px`,
    };
  }

  function getCardContainerClass(): string {
    return classNames(
      `no-scrollbar tw-max-w-[100%] tw-overscroll-x-none`,
      cardFrameConfig?.contentAlignment !== 'left' && !props?.isBlogListingPage
        ? !shouldAutoScrollSlider
          ? 'tw-mx-auto'
          : ''
        : '',
      !isLayoutCarousel ? `tw-grid` : `tw-flex tw-items-stretch`,
      listPresentationConfig?.layoutType === 'carousel' &&
        isLogoSlider &&
        !shouldAutoScrollSlider
        ? `!tw-pb-[15px] !tw-pt-[35px] ${isMobile ? '!tw-px-[16px]' : ''}`
        : ''
    );
  }

  function getCardContainerStyles(): React.CSSProperties {
    const style: React.CSSProperties = {};
    if (!isLayoutCarousel) {
      style.gridTemplateColumns = `repeat(${viewAllItemsConfig?.isViewAllVisible ? 'auto-fit' : containerTracks?.numColumns}, ${oneGridCellGeometry.width}px)`;
      if (!viewAllItemsConfig?.isViewAllVisible) {
        style.gridTemplateRows = `repeat(${containerTracks?.numRows}, auto)`;
      }
      for (const key in gridGapAxis) {
        if (key) {
          style[key] = gridGapAxis[key];
        }
      }
    } else {
      style.overflowX = 'auto';
      style.gap = gridGapAxis.columnGap;
    }
    style.overflowY =
      contentTypeConditions.featured && !contentTypeConditions.featuredReels
        ? 'hidden'
        : 'visible';
    style.padding = `${listVerticalPadding}px ${listHorizontalPadding}px`;
    if (isCardScaleAnimation) {
      style.marginBottom = '-20px';
      style.marginTop = '-20px';
    }
    if (featuredReelConfigs.expandFocusedReel) {
      style.paddingLeft = `calc(50% - ${oneGridCellGeometry.width / 2}px)`;
      style.paddingRight = `calc(50% - ${oneGridCellGeometry.width / 2}px)`;
      style.scrollSnapType = 'x mandatory';
    }
    return style;
  }

  if (props.isVisible === false) {
    return null;
  }
  return (
    <div
      className="listPresentationContainer"
      style={{
        background: sectionColors?.background,
      }}
    >
      <section
        ref={sectionContainerRef}
        className={classNames(
          `${cardFrameConfig?.contentAlignment !== 'left' ? (!shouldAutoScrollSlider ? 'tw-items-center' : '') : ''} tw-flex tw-w-[100%] tw-flex-col`
        )}
        style={getSectionStyles()}
        onClick={handleHeadingClick}
      >
        <ListDescriptionSection
          listPresentationConfig={listPresentationConfig}
          isMobile={isMobile}
          containerHorizontalPadding={contentHorizontalPadding}
          showArrow={showArrow}
          sectionColors={sectionColors}
          carouselIndicatorConfigs={carouselIndicatorConfigs}
          onNextArrowClick={onNextArrowClick}
          onPrevArrowClick={onPrevArrowClick}
          isLogoSliderCarousel={
            listPresentationConfig?.layoutType === 'carousel' && isLogoSlider
          }
          isFocusedReelExpandOnMobile={featuredReelConfigs.expandFocusedReel}
          carouselConfigs={carouselConfigs}
        />
        {props?.isBlogListingPage && (
          <BlogHeroPage
            blogData={getBlogData(blogData)}
            listPresentationConfig={listPresentationConfig}
            cardFrameConfig={cardFrameConfig}
          />
        )}
        <div
          className={getCardContainerClass()}
          style={getCardContainerStyles()}
          ref={itemsContainerRef}
          onScroll={(e) =>
            onCarouselScroll({
              e,
              setCarouselIndicatorConfigs,
              slideOffset,
              widthOffset: widthAdditionals,
            })
          }
        >
          {_RenderCards()}
        </div>
        {_RenderViewMoreButton(
          (!isLogoSlider && isViewAllBtnToShow && contentTypeConditions?.featured) ||
            (isBlogWidget && !props?.isBlogListingPage)
        )}
        <CarouselIndicator
          showArrowUI={showArrow}
          isVisible={showCarouselIndicators}
          config={carouselConfigs}
          itemContainerLayoutChange={itemContainerLayoutChange}
          carouselIndicatorConfigs={carouselIndicatorConfigs}
          onPrevArrowClick={onPrevArrowClick}
          onNextArrowClick={onNextArrowClick}
          arrowPosition={carouselConfigs?.arrowPosition}
          isMobile={isMobile}
          containerHorizontalPadding={contentHorizontalPadding}
        />
        <QuickViewModal />
      </section>
    </div>
  );
}
