import React from 'react';
import LogoSliderComponent from '../../LogoSlider';
import style from '../ListPresentation.module.scss';
import { twMerge } from 'tailwind-merge';

export default function LogoSliderRenderer({
  cardFrameConfig,
  listPresentationConfig,
  gridGapAxis,
  sectionContainerRef,
  oneGridCellGeometry,
  isMobile,
  sectionColors,
  itemCountTotal,
  isSliderFullWidth,
}) {
  function renderLogoSliderComp() {
    if (
      cardFrameConfig?.autoScroll &&
      listPresentationConfig?.layoutType === 'carousel'
    ) {
      const groupWidth =
        oneGridCellGeometry.width * itemCountTotal +
        itemCountTotal * gridGapAxis.columnGap;
      const { scrollSpeed } = cardFrameConfig;
      return (
        <div
          style={{
            maxWidth: !isSliderFullWidth
              ? !isMobile
                ? sectionContainerRef.current?.clientWidth - 80
                : sectionContainerRef.current?.clientWidth - 40
              : '100vw',
          }}
          className={twMerge(style.carousel, 'tw-pb-[10px] tw-pt-[35px]')}
        >
          <div
            style={{
              gap: gridGapAxis.columnGap,
              maxWidth: groupWidth,
              width: groupWidth,
              paddingRight: gridGapAxis.columnGap,
              animationDuration:
                scrollSpeed === 0.5 ? '22.5s' : scrollSpeed === 2 ? '7.5s' : '15s',
            }}
            className={style.group}
          >
            {cardFrameConfig?.multiColumnData?.map((columnData, index) =>
              columnData?.isVisible ? (
                <LogoSliderComponent
                  key={index}
                  index={index}
                  columnData={columnData}
                  oneGridCellGeometry={oneGridCellGeometry}
                  listPresentationConfig={listPresentationConfig}
                  cardFrameConfig={cardFrameConfig}
                  sectionColors={sectionColors}
                  multiColumnData={cardFrameConfig?.multiColumnData}
                />
              ) : null
            )}
          </div>
          <div
            style={{
              gap: gridGapAxis.columnGap,
              maxWidth: groupWidth,
              width: groupWidth,
              paddingRight: gridGapAxis.columnGap,
              animationDuration:
                scrollSpeed === 0.5 ? '22.5s' : scrollSpeed === 2 ? '7.5s' : '15s',
            }}
            className={style.group}
          >
            {cardFrameConfig?.multiColumnData?.map((columnData, index) =>
              columnData?.isVisible ? (
                <LogoSliderComponent
                  key={index}
                  index={index}
                  columnData={columnData}
                  oneGridCellGeometry={oneGridCellGeometry}
                  listPresentationConfig={listPresentationConfig}
                  cardFrameConfig={cardFrameConfig}
                  sectionColors={sectionColors}
                  multiColumnData={cardFrameConfig?.multiColumnData}
                />
              ) : null
            )}
          </div>
          <div
            style={{
              gap: gridGapAxis.columnGap,
              maxWidth: groupWidth,
              width: groupWidth,
              paddingRight: gridGapAxis.columnGap,
              animationDuration:
                scrollSpeed === 0.5 ? '22.5s' : scrollSpeed === 2 ? '7.5s' : '15s',
            }}
            className={style.group}
          >
            {cardFrameConfig?.multiColumnData?.map((columnData, index) =>
              columnData?.isVisible ? (
                <LogoSliderComponent
                  key={index}
                  index={index}
                  columnData={columnData}
                  oneGridCellGeometry={oneGridCellGeometry}
                  listPresentationConfig={listPresentationConfig}
                  cardFrameConfig={cardFrameConfig}
                  sectionColors={sectionColors}
                  multiColumnData={cardFrameConfig?.multiColumnData}
                />
              ) : null
            )}
          </div>
        </div>
      );
    }
    return cardFrameConfig?.multiColumnData?.map((columnData, index) =>
      columnData?.isVisible ? (
        <LogoSliderComponent
          key={index}
          index={index}
          columnData={columnData}
          oneGridCellGeometry={oneGridCellGeometry}
          listPresentationConfig={listPresentationConfig}
          cardFrameConfig={cardFrameConfig}
          sectionColors={sectionColors}
          multiColumnData={cardFrameConfig?.multiColumnData}
        />
      ) : null
    );
  }

  return renderLogoSliderComp();
}
