import { merge } from 'lodash';
import { RootState } from './reducers';
import { batch, useSelector as reduxUseSelector, useDispatch } from 'react-redux';
import {
  hydrateCommonReducer,
  saveCatalogCategories,
  saveCatalogItemsBasicsByStoreId,
  saveCatalogTags,
  saveCollections,
  saveCouponsList,
  saveFeatureLocks,
  saveJsonWidgetsData,
  saveStoreInfo,
  saveStorePolicies,
  updateAdditionalPageReducer,
} from './actions';
import { IS_SERVER } from '@/utils/checkRenderEnv';
import { SSRSelectorContext } from './useSSRSelector';
import { useContext } from 'react';

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
      ? RecursivePartial<T[P]>
      : T[P];
};

const ssrStoreFactory = () => {
  let _store: Record<string, RootState> = {};

  const updateReduxStore = (ssrData: RecursivePartial<RootState>, dispatch) => {
    if (!IS_SERVER && dispatch) {
      const {
        storeReducer,
        additionalPagesReducer,
        jsonWidgetsReducer,
        promoReducer,
        catalogReducer,
        storePolicyReducer,
        commonReducer,
      } = ssrData;

      batch(() => {
        if (storeReducer?.store) {
          dispatch(saveStoreInfo(storeReducer.store));
        }
        if (additionalPagesReducer) {
          dispatch(updateAdditionalPageReducer(additionalPagesReducer));
        }
        if (jsonWidgetsReducer?.widgets) {
          dispatch(saveJsonWidgetsData(jsonWidgetsReducer));
        }
        if (promoReducer?.couponsList) {
          dispatch(saveCouponsList(promoReducer.couponsList));
        }
        if (catalogReducer) {
          const { categories, collections, productTags, catalog_items } = catalogReducer;
          if (categories) {
            dispatch(saveCatalogCategories(categories));
          }
          if (collections) {
            dispatch(saveCollections(collections));
          }
          if (productTags) {
            dispatch(saveCatalogTags(productTags));
          }
          if (catalog_items) {
            dispatch(saveCatalogItemsBasicsByStoreId(catalog_items));
          }
        }
        if (storePolicyReducer) {
          dispatch(
            saveStorePolicies(
              storePolicyReducer.data,
              storePolicyReducer.isFetchedFromSSR
            )
          );
        }
        if (commonReducer) {
          if (commonReducer.featureLocksData?.featureLocks) {
            dispatch(saveFeatureLocks(commonReducer.featureLocksData.featureLocks));
          }
          delete commonReducer.featureLocksData;
          dispatch(hydrateCommonReducer(commonReducer));
        }
      });
    }
  };

  /**
   * To be called inside function component, and at top level with out conditions, as it uses hooks.
   * @param store SSR Store data
   */
  // eslint-disable-next-line new-cap
  const useSetSSRStore = (store: RootState) => {
    const { domain } = useContext(SSRSelectorContext);
    const dispatch = useDispatch();
    if (!store) {
      return;
    }
    // eslint-disable-next-line new-cap
    _store[domain] = store;
    if (!IS_SERVER && dispatch) {
      updateReduxStore(store, dispatch);
    }
  };

  const updateSSRStore = (partialStore: RecursivePartial<RootState>, domain: string) => {
    _store[domain] = merge(partialStore, _store[domain]);
  };

  const getSSRStore = (domain: string): RootState => {
    return _store[domain];
  };

  const clearSSRStore = (domain?: string) => {
    if (domain) {
      _store[domain] = null;
    } else {
      _store = {};
    }
  };

  function useSSRSelector<TSelected = unknown>(
    selector: (state: RootState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean
  ): TSelected {
    const { domain } = useContext(SSRSelectorContext);
    if (typeof window === 'undefined') {
      try {
        return selector(getSSRStore(domain));
      } catch {
        return reduxUseSelector<RootState, TSelected>(selector, equalityFn);
      }
    }
    return reduxUseSelector<RootState, TSelected>(selector, equalityFn);
  }

  return {
    useSetSSRStore,
    updateSSRStore,
    getSSRStore,
    clearSSRStore,
    useSSRSelector,
    updateReduxStore,
  };
};

const ssrStore = ssrStoreFactory();
export const useSetSSRStore = ssrStore.useSetSSRStore;
export const updateSSRStore = ssrStore.updateSSRStore;
export const getSSRStore = ssrStore.getSSRStore;
export const clearSSRStore = ssrStore.clearSSRStore;
export const useSSRSelector = ssrStore.useSSRSelector;
