/**
 * Add New Address page
 */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '@/components/Common/Premium/Input';
import OrderConfirmLoader from '@/components/DesignSystem/OrderConfirmLoader';
import {
  fetchCityStateFromPincode,
  saveAddress,
  saveSelectedAddress,
  setCart,
} from '../../redux/actions';
import { addDeliveryInfoInCart } from '@/utils/addDeliveryInfoInCart';
import { isDeliveryPincodeBased } from '@/utils/isDeliveryPincodeBased';
import { jsonEqual } from '@/utils/jsonEqual';
import { isValidEmail } from '@/utils/isValidEmail';
import { SaveAddressCta, SaveAddressCtaContainer } from './AddDelivery.styles';
import { roundnessCalculator } from '@/components/DesignSystem/AtomicComponents/util';
import { DEFAULT_ADDRESS_ID, GENDER_TYPES, ORDER_TYPE } from '@/utils/constants';
import { triggerGetServiceableAddressId } from '@/utils/triggerGetServiceableAddressId';
import { useSSRSelector } from '@/redux/ssrStore';
import { getThemeColor } from 'src/utils/getThemeColor';
import { IS_SERVER } from 'src/utils/checkRenderEnv';
import CountryInput from './Components/CountryInput';
import AgeAndGenderInput from './Components/AgeAndGenderInput';
import {
  ADDRESS_NAMES,
  ADDRESS_TYPES,
  INDIA_COUNTRY_OBJECT,
  FIELDS_ID,
  VALIDATION_TYPES,
} from './Constants';
import AddressTypesAndName from './Components/AddressTypesAndName';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import { CartContext } from '../Cart';
import { toastNotifyError } from 'src/components/Common/Toast';

let oldAddressData = {};
const AddDelivery = (props) => {
  const { showAddressError, setShowAddressError } = useContext(CartContext);

  const dispatch = useDispatch();
  // const { edit_id } = router.query;
  const themeColor = getThemeColor();

  const [state, setState] = useState({
    user_name: '',
    landmark: '',
    pincode: '',
    city: '',
    state: '',
    country: '',
    address1: '',
    address2: '',
    addressName: ADDRESS_NAMES.HOME,
    isLocationOn: false,
    addressType: ADDRESS_TYPES.HOME,
    email_id: '',
    alternate_phone: '',
    deliveryType: IS_SERVER ? '' : cart?.order_type,
    showOptionalFields: false,
    selectedAddress: null,
    age: '',
    gender: '',
  });

  const [orderConfirmLoaderToggle, setOrderConfirmLoaderToggle] = useState(false);

  const [excludedStoreId, setExcludedStoreId] = useState([]);

  const [countriesData, setCountriesData] = useState({
    countries: [],
    defaultCountry: INDIA_COUNTRY_OBJECT,
  });

  const [invalidInputIdsMap, setInvalidInputIdsMap] = useState({});

  const storeInfo = useSSRSelector((state) => state.storeReducer.store);
  const cart = useSelector((state) => state.cartReducer);

  const { userAddressList, userData, defaultAddress } = useSelector((state) => ({
    userData: state.userReducer.data,
    userAddressList: state.userReducer.addressList,
    defaultAddress: state.userReducer.defaultAddress,
  }));

  const isIndiaSelected =
    (state.country || '').toLowerCase() === INDIA_COUNTRY_OBJECT.country.toLowerCase() &&
    countriesData.defaultCountry.id === INDIA_COUNTRY_OBJECT.id;

  // for opening in editing mode
  useEffect(() => {
    if (props.edit_id && Array.isArray(userAddressList)) {
      const selectedAddFromUsersAddList = userAddressList?.find(
        (el) => el.address_id === Number(props.edit_id)
      );
      setState((state) => ({
        ...state,
        user_name: selectedAddFromUsersAddList.user_name.text,
        landmark: selectedAddFromUsersAddList.landmark.text,
        pincode: selectedAddFromUsersAddList.pincode.text,
        city: selectedAddFromUsersAddList.city.text,
        address1: selectedAddFromUsersAddList.address1.text,
        address2: selectedAddFromUsersAddList.address2.text,
        addressType: selectedAddFromUsersAddList.address_type,
        addressName: selectedAddFromUsersAddList.address_name || 'Home',
        user_id: selectedAddFromUsersAddList.user_id,
        address_id: selectedAddFromUsersAddList.address_id,
        state: selectedAddFromUsersAddList.state.text,
        country:
          selectedAddFromUsersAddList.country?.text || INDIA_COUNTRY_OBJECT.country,
        email_id: selectedAddFromUsersAddList.email_id.text,
        age: selectedAddFromUsersAddList.age.text,
        gender: selectedAddFromUsersAddList.gender.text,
        alternate_phone:
          selectedAddFromUsersAddList.alternate_phone.text || userData?.phone,
        selectedAddress: selectedAddFromUsersAddList,
      }));

      if (state.selectedAddress) {
        validate();
      }
    } else {
      setState((state) => ({
        ...state,
        user_name: '',
        landmark: '',
        pincode: '',
        city: '',
        state: '',
        country: countriesData.defaultCountry.country,
        address1: '',
        address2: '',
        addressName: 'Home',
        isLocationOn: false,
        addressType: 'home',
        email_id: '',
        age: '',
        gender: '',
        deliveryType: IS_SERVER ? '' : cart?.order_type,
        showOptionalFields: false,
        alternate_phone: userData?.phone,
        selectedAddress: defaultAddress,
      }));
    }
    setInvalidInputIdsMap({});
  }, [props.edit_id, defaultAddress, state.selectedAddress]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const filePath =
      'https://docs.google.com/spreadsheets/d/1ZLV7S5VvdHznmEFNz5AwasSjJlUjyAy0sbl-fTqax_Q/edit#gid=0';
    try {
      const response = await fetch(filePath);
      if (!response.ok) {
        throw new Error('Failed to fetch file');
      }
      const fileContent = await response.text();
      const integers = fileContent
        ?.split('\n')
        .map((row) => parseInt(row.trim(), 10))
        .filter((value) => !isNaN(value));
      if (Array.isArray(integers)) {
        setExcludedStoreId(integers);
      }
    } catch (error) {
      console.error('Error fetching file:', error.message);
    }
  };

  const isStoreExcluded = excludedStoreId.includes(storeInfo?.store_id);

  function getRegexByValidationType(validationType) {
    switch (validationType) {
      case VALIDATION_TYPES.EMAIL:
        return /[^\w\@\-\.]/gi;
      case VALIDATION_TYPES.ALPHA_NUMERIC:
        return /[^\w/]/gi;
      case VALIDATION_TYPES.ALPHA_NUMERIC_INTERNATIONAL_PINCODE:
        return /^(?![a-zA-Z0-9\s,-]{0,15}$).*$/;
      case VALIDATION_TYPES.ALPHA_SPACE:
        return /[^a-z ]/gi;
      case VALIDATION_TYPES.ADDRESS:
        return /[^\w\s\-\,.(){}|*&#@!`";=/]/gi;
      case VALIDATION_TYPES.NUMERIC:
        return /[^0-9]/g;
      default:
        return /[^\d]/gi;
    }
  }

  const handleInputChange = (key, value, validationType, minLength, maxLength) => {
    const newState = { ...state };
    if (!isStoreExcluded || key !== 'pincode') {
      if (value.length > maxLength) value = value.substring(0, maxLength);
    }
    value = value.replace(getRegexByValidationType(validationType), '');

    if (isIndiaSelected && key === 'pincode' && value.length === 6) {
      dispatch(
        fetchCityStateFromPincode(value, (res) => {
          setState((state) => ({ ...state, state: res.state, city: res.city }));
        })
      );
    }
    if (key === 'gender' && !Object.values(GENDER_TYPES).includes(value)) {
      value = '';
    }
    const id = getIdByKey(key);
    if (invalidInputIdsMap[id] && value.trim()) {
      setInvalidInputIdsMap({
        ...invalidInputIdsMap,
        [id]: false,
      });
    }
    newState[key] = value;
    setState(newState);
  };

  function getIdByKey(key) {
    switch (key) {
      case 'user_name':
        return FIELDS_ID.Username;
      case 'landmark':
        return FIELDS_ID.Landmark;
      case 'pincode':
        return FIELDS_ID.Pincode;
      case 'city':
        return FIELDS_ID.City;
      case 'state':
        return FIELDS_ID.State;
      case 'address1':
        return FIELDS_ID.Address1;
      case 'address2':
        return FIELDS_ID.Address2;
      case 'email_id':
        return FIELDS_ID.EmailId;
      case 'age':
        return FIELDS_ID.Age;
      case 'gender':
        return FIELDS_ID.Gender;
      case 'alternate_phone':
        return FIELDS_ID.AlternatePhone;
      default:
        return '';
    }
  }

  function validateEmailError() {
    const emailID = state.email_id.trim();
    if (state.selectedAddress.email_id.is_mandatory) {
      if (!emailID) {
        return true;
      }
      if (!isValidEmail(emailID)) {
        return true;
      }
      return false;
    } else if (emailID) {
      if (!isValidEmail(emailID)) {
        return true;
      }
    }
    return false;
  }

  function validateAlternatePhoneError() {
    if (state.selectedAddress.alternate_phone.is_mandatory) {
      if (state.alternate_phone.trim() === '') {
        return true;
      } else if (isIndiaSelected && state.alternate_phone?.length < 10) {
        return true;
      }
      return false;
    } else return false;
  }

  const validate = () => {
    const idsMap = {};
    if (state.selectedAddress.user_name.is_mandatory && state.user_name.trim() === '') {
      idsMap[FIELDS_ID.Username] = true;
    }
    if (state.selectedAddress.address1.is_mandatory && state.address1.trim() === '') {
      idsMap[FIELDS_ID.Address1] = true;
    }
    if (state.selectedAddress.address2.is_mandatory && state.address2.trim() === '') {
      idsMap[FIELDS_ID.Address2] = true;
    }
    if (
      !isStoreExcluded &&
      ((state.selectedAddress.pincode.is_mandatory && state.pincode.trim() === '') ||
        (state.pincode.trim() !== '' &&
          isIndiaSelected &&
          state.pincode.trim()?.length !== 6))
    ) {
      idsMap[FIELDS_ID.Pincode] = true;
    }
    if (state.selectedAddress.city.is_mandatory && state.city.trim() === '') {
      idsMap[FIELDS_ID.City] = true;
    }
    if (state.selectedAddress.state.is_mandatory && state.state.trim() === '') {
      idsMap[FIELDS_ID.State] = true;
    }
    if (state.selectedAddress.landmark.is_mandatory && state.landmark.trim() === '') {
      idsMap[FIELDS_ID.Landmark] = true;
    }
    if (validateEmailError()) {
      idsMap[FIELDS_ID.EmailId] = true;
    }
    if (validateAlternatePhoneError()) {
      idsMap[FIELDS_ID.AlternatePhone] = true;
    }
    if (state.selectedAddress.age.is_mandatory && state.age.trim() === '') {
      idsMap[FIELDS_ID.Age] = true;
    }
    if (state.selectedAddress.gender.is_mandatory && state.gender.trim() === '') {
      idsMap[FIELDS_ID.Gender] = true;
    }
    if (Object.keys(idsMap).length) {
      setInvalidInputIdsMap(idsMap);
      return false;
    }
    return true;
  };

  const submitPlaceOrder = () => {
    let addressData = {
      address_id: state.address_id,
      user_id: userData?.user_id,
      address_type: state.addressType,
      address1: state.address1,
      address2: state.address2,
      latitude: state.latitude || 0,
      longitude: state.longitude || 0,
      phone_number: userData?.phone,
      city: state.city,
      google_address: '',
      pincode: state.pincode,
      landmark: state.landmark,
      user_name: state.user_name,
      alternate_phone: state.alternate_phone,
      email_id: state.email_id.trim(),
      state: state.state,
      country: state.country,
      gender: state.gender,
      age: state.age,
      address_name: state.addressName,
    };
    let orderData = addDeliveryInfoInCart(cart, addressData);
    if (!jsonEqual(oldAddressData, addressData)) {
      dispatch(
        saveAddress(addressData, (status) => {
          if (status) {
            setShowAddressError?.(false);
            oldAddressData = addressData;
            addressData = { ...addressData, address_id: DEFAULT_ADDRESS_ID };
            orderData = addDeliveryInfoInCart(cart, addressData);
            dispatch(setCart(orderData));
            if (isDeliveryPincodeBased()) triggerGetServiceableAddressId();
            props.toggleAddDeliveryForm(false);
          }
        })
      );
    } else {
      props.toggleAddDeliveryForm(false);
    }
  };

  const onDeliverHereHandle = () => {
    const isValidated = validate();

    if (!isValidated) {
      toastNotifyError('Please fill all mandatory fields!');
      return;
    }
    dispatch(saveSelectedAddress(state));
    submitPlaceOrder();
  };

  function renderSaveButton() {
    return (
      <SaveAddressCtaContainer>
        <SaveAddressCta
          color={themeColor}
          onClick={onDeliverHereHandle}
          borderRadius={roundnessCalculator(storeInfo?.theme?.roundness)}
        >
          Save & Deliver here
        </SaveAddressCta>
      </SaveAddressCtaContainer>
    );
  }

  function setGender(selectedGender) {
    setState({ ...state, gender: selectedGender });
  }

  function setCountry(selectedCountry) {
    setState({
      ...state,
      country: selectedCountry?.country,
      pincode: '',
      city: '',
      state: '',
      alternate_phone: '',
    });
  }

  const showError = !IS_DESKTOP && showAddressError;

  return (
    <>
      <div className="premium-add-delivery-page">
        {showError && (
          <p className="tw-m-0 tw-mb-[16px] tw-text-xs tw-text-[#F63D60] md:tw-text-sm">
            Please add a delivery address before placing the order
          </p>
        )}
        <div
          className={`tw-overflow-hidden tw-rounded-lg tw-border tw-border-solid ${showError ? 'tw-border-[#F63D60]' : 'tw-border-[#E2E2E2]'} md:tw-overflow-visible md:tw-border-x-0 md:tw-border-b-0 md:tw-border-t`}
        >
          {/* Main Body */}
          <article className="premium-address-container" id="address-section">
            <div className="premium-address-container__wrap premium-wrapper-around ">
              {state?.selectedAddress?.user_name?.visibility && (
                <Input
                  type="text"
                  label={`Enter Recipient Name ${
                    !state?.selectedAddress?.user_name?.is_mandatory ? '(optional)' : '*'
                  }`}
                  id={FIELDS_ID.Username}
                  className={
                    invalidInputIdsMap[FIELDS_ID.Username] ? '!tw-border-b-[#F63D60]' : ''
                  }
                  labelClasses={
                    invalidInputIdsMap[FIELDS_ID.Username] ? '!tw-text-[#F63D60]' : ''
                  }
                  value={state.user_name}
                  disabled={state.deliveryType === ORDER_TYPE.PICKUP}
                  onChange={(e) => {
                    handleInputChange(
                      'user_name',
                      e.target.value,
                      VALIDATION_TYPES.ADDRESS,
                      0,
                      60
                    );
                  }}
                />
              )}
              {state?.selectedAddress?.address1?.visibility && (
                <Input
                  label={`Enter Flat/ House No. / Floor ${
                    !state?.selectedAddress?.address1?.is_mandatory ? '(optional)' : '*'
                  }`}
                  type="text"
                  id={FIELDS_ID.Address1}
                  className={
                    invalidInputIdsMap[FIELDS_ID.Address1] ? '!tw-border-b-[#F63D60]' : ''
                  }
                  labelClasses={
                    invalidInputIdsMap[FIELDS_ID.Address1] ? '!tw-text-[#F63D60]' : ''
                  }
                  value={state.address1}
                  disabled={state.deliveryType === ORDER_TYPE.PICKUP}
                  onChange={(e) =>
                    handleInputChange(
                      'address1',
                      e.target.value,
                      VALIDATION_TYPES.ADDRESS,
                      0,
                      100
                    )
                  }
                />
              )}
              {state?.selectedAddress?.address2?.visibility && (
                <Input
                  label={`Enter Colony/ Street / Locality ${
                    !state?.selectedAddress?.address2?.is_mandatory ? '(optional)' : '*'
                  }`}
                  type="text"
                  id={FIELDS_ID.Address2}
                  className={
                    invalidInputIdsMap[FIELDS_ID.Address2] ? '!tw-border-b-[#F63D60]' : ''
                  }
                  labelClasses={
                    invalidInputIdsMap[FIELDS_ID.Address2] ? '!tw-text-[#F63D60]' : ''
                  }
                  value={state.address2}
                  disabled={state.deliveryType === ORDER_TYPE.PICKUP}
                  onChange={(e) =>
                    handleInputChange(
                      'address2',
                      e.target.value,
                      VALIDATION_TYPES.ADDRESS,
                      0,
                      100
                    )
                  }
                />
              )}
              <article className="premium-form-flex-row">
                {state?.selectedAddress?.pincode?.visibility && (
                  <Input
                    label={`Pincode ${
                      !state?.selectedAddress?.pincode?.is_mandatory ? '(optional)' : '*'
                    }`}
                    type="text"
                    id={FIELDS_ID.Pincode}
                    className={
                      invalidInputIdsMap[FIELDS_ID.Pincode]
                        ? '!tw-border-b-[#F63D60]'
                        : ''
                    }
                    labelClasses={
                      invalidInputIdsMap[FIELDS_ID.Pincode] ? '!tw-text-[#F63D60]' : ''
                    }
                    value={state.pincode}
                    disabled={state.deliveryType === ORDER_TYPE.PICKUP}
                    onChange={(e) =>
                      handleInputChange(
                        'pincode',
                        e.target.value,
                        isIndiaSelected
                          ? VALIDATION_TYPES.NUMERIC
                          : VALIDATION_TYPES.ALPHA_NUMERIC_INTERNATIONAL_PINCODE,
                        0,
                        isIndiaSelected ? 6 : 15
                      )
                    }
                  />
                )}
                {state?.selectedAddress?.city?.visibility && (
                  <Input
                    label={`City ${
                      !state?.selectedAddress?.state?.is_mandatory ? '(optional)' : '*'
                    }`}
                    type="text"
                    id={FIELDS_ID.City}
                    value={state.city}
                    className={
                      invalidInputIdsMap[FIELDS_ID.City] ? '!tw-border-b-[#F63D60]' : ''
                    }
                    labelClasses={
                      invalidInputIdsMap[FIELDS_ID.City] ? '!tw-text-[#F63D60]' : ''
                    }
                    disabled={state.deliveryType === ORDER_TYPE.PICKUP}
                    onChange={(e) =>
                      handleInputChange(
                        'city',
                        e.target.value,
                        VALIDATION_TYPES.ADDRESS,
                        0,
                        40
                      )
                    }
                  />
                )}
              </article>
              <article className="premium-form-flex-row">
                {state?.selectedAddress?.state?.visibility && (
                  <Input
                    label={`State ${
                      !state?.selectedAddress?.state?.is_mandatory ? '(optional)' : '*'
                    }`}
                    type="text"
                    id={FIELDS_ID.State}
                    className={
                      invalidInputIdsMap[FIELDS_ID.State] ? '!tw-border-b-[#F63D60]' : ''
                    }
                    labelClasses={
                      invalidInputIdsMap[FIELDS_ID.State] ? '!tw-text-[#F63D60]' : ''
                    }
                    value={state.state}
                    disabled={state.deliveryType === ORDER_TYPE.PICKUP}
                    onChange={(e) =>
                      handleInputChange(
                        'state',
                        e.target.value,
                        VALIDATION_TYPES.ADDRESS,
                        0,
                        50
                      )
                    }
                  />
                )}
                {state.selectedAddress?.country?.visibility && (
                  <CountryInput
                    country={state.country}
                    INDIA_COUNTRY_OBJECT={INDIA_COUNTRY_OBJECT}
                    setCountry={setCountry}
                    countriesData={countriesData}
                    setCountriesData={setCountriesData}
                  />
                )}
              </article>
              {state?.selectedAddress?.landmark?.visibility && (
                <Input
                  label={`Landmark ${
                    !state?.selectedAddress?.landmark?.is_mandatory ? '(optional)' : '*'
                  }`}
                  type="text"
                  id={FIELDS_ID.Landmark}
                  className={
                    invalidInputIdsMap[FIELDS_ID.Landmark] ? '!tw-border-b-[#F63D60]' : ''
                  }
                  labelClasses={
                    invalidInputIdsMap[FIELDS_ID.Landmark] ? '!tw-text-[#F63D60]' : ''
                  }
                  value={state.landmark}
                  disabled={state.deliveryType === ORDER_TYPE.PICKUP}
                  onChange={(e) =>
                    handleInputChange(
                      'landmark',
                      e.target.value,
                      VALIDATION_TYPES.ADDRESS,
                      0,
                      50
                    )
                  }
                />
              )}
              {state?.selectedAddress?.email_id?.visibility && (
                <Input
                  label={`Email ID ${
                    !state?.selectedAddress?.email_id?.is_mandatory ? '(optional)' : '*'
                  }`}
                  type="text"
                  id={FIELDS_ID.EmailId}
                  className={
                    invalidInputIdsMap[FIELDS_ID.EmailId] ? '!tw-border-b-[#F63D60]' : ''
                  }
                  labelClasses={
                    invalidInputIdsMap[FIELDS_ID.EmailId] ? '!tw-text-[#F63D60]' : ''
                  }
                  value={state.email_id}
                  disabled={state.deliveryType === ORDER_TYPE.PICKUP}
                  onChange={(e) =>
                    handleInputChange(
                      'email_id',
                      e.target.value,
                      VALIDATION_TYPES.ADDRESS,
                      0,
                      80
                    )
                  }
                />
              )}
              {state?.selectedAddress?.alternate_phone?.visibility && (
                <Input
                  label={`Mobile Number ${
                    !state?.selectedAddress?.alternate_phone?.is_mandatory
                      ? '(optional)'
                      : '*'
                  }`}
                  type="text"
                  id={FIELDS_ID.AlternatePhone}
                  className={
                    invalidInputIdsMap[FIELDS_ID.AlternatePhone]
                      ? '!tw-border-b-[#F63D60]'
                      : ''
                  }
                  labelClasses={
                    invalidInputIdsMap[FIELDS_ID.AlternatePhone]
                      ? '!tw-text-[#F63D60]'
                      : ''
                  }
                  value={state.alternate_phone}
                  disabled={state.deliveryType === ORDER_TYPE.PICKUP}
                  onChange={(e) =>
                    handleInputChange(
                      'alternate_phone',
                      e.target.value,
                      VALIDATION_TYPES.NUMERIC,
                      0,
                      isIndiaSelected ? 12 : 15
                    )
                  }
                />
              )}
              <AgeAndGenderInput
                state={state}
                handleInputChange={handleInputChange}
                setGender={setGender}
                invalidInputIdsMap={invalidInputIdsMap}
              />
            </div>
            <AddressTypesAndName state={state} setState={setState} />
            {renderSaveButton()}
          </article>
          {/* Footer */}
        </div>
      </div>
      {orderConfirmLoaderToggle && (
        <OrderConfirmLoader
          isVisible={orderConfirmLoaderToggle}
          closeModal={() => setOrderConfirmLoaderToggle(!orderConfirmLoaderToggle)}
          submitAction={submitPlaceOrder}
          deliveryType={0}
        />
      )}
    </>
  );
};

export default AddDelivery;
