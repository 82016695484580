import {
  GET_WIDGET_THEME_INFO,
  SAVE_PAGE_LAYOUT_WIDGETS,
  SAVE_WIDGET_THEME_INFO,
  ADD_WIDGET,
  UPDATE_WIDGET,
  SUBMIT_FORM_DATA,
  SAVE_FLOATING_BUTTON_DATA,
  SET_ACTIVE_STYLE_TEMPLATE,
  SAVE_ACTIVE_STYLE_TEMPLATE,
  SAVE_ACTIVE_WIDGET_CHANGE_DESIGN_TEMPLATE,
  SAVE_FORM_DATA,
  ADD_MEDIA_TO_STORE,
  UPDATE_MEDIA_TO_STORE,
  DELETE_MEDIA_FROM_STORE,
  UPLOAD_PDF_TO_S3,
  UPDATE_THEME_BUILDER_DATA,
  CREATE_THEME,
  UPDATE_THEME,
  PUBLISH_THEME,
  SET_THEME_BUILDER_FLOW,
  SAVE_CURRENT_THEME_DATA,
} from './../actionTypes';

export function getWidgetThemeInfo(callback) {
  return {
    type: GET_WIDGET_THEME_INFO,
    callback,
  };
}

export function saveWidgetThemeInfo(data, callback) {
  return {
    type: SAVE_WIDGET_THEME_INFO,
    data,
    callback,
  };
}

export function savePageLayoutWidgets(data, callback) {
  return {
    type: SAVE_PAGE_LAYOUT_WIDGETS,
    data,
    callback,
  };
}

export function addNewWidget(data, callback) {
  return {
    type: ADD_WIDGET,
    data,
    callback,
  };
}

export function updateWidget(data, callback) {
  return {
    type: UPDATE_WIDGET,
    data,
    callback,
  };
}

export function submitFormData(data, callback) {
  return {
    type: SUBMIT_FORM_DATA,
    data,
    callback,
  };
}

export function saveFloatingWidgetData(data, callback) {
  return {
    type: SAVE_FLOATING_BUTTON_DATA,
    data,
    callback,
  };
}

export function saveFormWidgetData(data, callback) {
  return {
    type: SAVE_FORM_DATA,
    data,
    callback,
  };
}

export function setActiveStyleTemplate(data, callback) {
  return {
    type: SET_ACTIVE_STYLE_TEMPLATE,
    data,
    callback,
  };
}

export function saveStoreStyleTemplate(data, callback) {
  return {
    type: SAVE_ACTIVE_STYLE_TEMPLATE,
    data,
    callback,
  };
}

export function setActiveWidgetChangeDesignTemplates(data, callback) {
  return {
    type: SAVE_ACTIVE_WIDGET_CHANGE_DESIGN_TEMPLATE,
    data,
    callback,
  };
}

export function addMediaToStore(data, callback) {
  return {
    type: ADD_MEDIA_TO_STORE,
    data,
    callback,
  };
}

export function updateMediaToStore(data, callback) {
  return {
    type: UPDATE_MEDIA_TO_STORE,
    data,
    callback,
  };
}

export function deleteMediaFromStore(data, callback) {
  return {
    type: DELETE_MEDIA_FROM_STORE,
    data,
    callback,
  };
}

export function uploadPdfToS3(data, callback) {
  return {
    type: UPLOAD_PDF_TO_S3,
    data,
    callback,
  };
}

export function updateThemeBuilderData(data, callback) {
  return {
    type: UPDATE_THEME_BUILDER_DATA,
    data,
    callback,
  };
}

export function createTheme(data, callback) {
  return {
    type: CREATE_THEME,
    data,
    callback,
  };
}

export function updateTheme(data, callback) {
  return {
    type: UPDATE_THEME,
    data,
    callback,
  };
}

export function publishTheme(data, callback) {
  return {
    type: PUBLISH_THEME,
    data,
    callback,
  };
}

export function setThemeBuilderFlow(data) {
  return {
    type: SET_THEME_BUILDER_FLOW,
    data,
  };
}

export function saveCurrentThemeData(data) {
  return {
    type: SAVE_CURRENT_THEME_DATA,
    data,
  };
}
