export const UPLOAD_STATUS = {
  PENDING: 1,
  PROGRESS: 2,
  COMPLETE: 3,
};

export const BUTTON_TYPE = {
  REDIRECT: { type: 1, name: 'Redirect to' },
  FORM: { type: 2, name: 'Open form' },
  SCROLL: { type: 3, name: 'Scroll to' },
  DOWNLOAD: { type: 4, name: 'Download file' },
};

export const BANNER_REDIRECTION_OPTIONS = {
  link: { value: 'link', text: 'Link' },
  scrollto: { value: 'scrollto', text: 'Scroll to section' },
};

export const REDIRECTION_TYPES = {
  link: "link",
  scrollto: "scrollto"
}

export const HEADER_NAVLINK_TYPE = {
  REDIRECT: { type: 1, name: 'Redirect to' },
  FORM: { type: 2, name: 'Open form' },
  SCROLL: { type: 3, name: 'Scroll to' },
  DOWNLOAD: { type: 4, name: 'Download file' },
  DROPDOWN: { type: 5, name: 'Open drop down' },
};

export const ALLOWED_MEDIA_TYPE_FOREGROUND = {
  IMAGE: 'image',
  VIDEO: 'video',
  GIF: 'gif',
  SLIDESHOW: 'slider',
} as const;

export const ALLOWED_MEDIA_TYPE_BACKGROUND = {
  IMAGE: 'image',
  VIDEO: 'video',
} as const;

export const MAX_SUPPORTED_CONTENT = {
  FOREGROUND_SLIDESHOW: 5,
  BUTTONS: 2,
  MIDDLEBODY_GALLERY_IMAGES: 8,
  MIDDLEBODY_TABS: 4,
  MIDDLEBODY_TABS_IMAGE: 6,
  HEADER_NAV_LINKS: 5,
  HEADER_SUB_NAV_LINKS: 10,
} as const;

export const WIDGET_TYPE_LIST = {
  WIDGET_TYPE_HEADER: 5,
  WIDGET_TYPE_FOOTER: 6,
  WIDGET_TYPE_MODERN_HEROBANNER: 19,
  WIDGET_TYPE_MODERN_MIDDLEBODY_COLUMN: 20,
  WIDGET_TYPE_MODERN_MIDDLEBODY_TAB: 21,
  WIDGET_TYPE_MODERN_MIDDLEBODY_GALLERY: 22,
  WIDGET_TYPE_MODERN_HEADER: 23,
  WIDGET_TYPE_MODERN_FOOTER: 24,
  WIDGET_TYPE_FORM: 25,
  WIDGET_TYPE_FLOATING_BUTTON: 26,
} as const;

export const TEXT_LIMIT = {
  HEADER_LINK: 22,
  TEXT_HEADING: 60,
  TEXT_DESCRIPTION: 350,
  BUTTON_TEXT: 20,
  PHONE_NUMBER: 10,
  ADDRESS: 100,
  TAB_NAME: 25,
};
