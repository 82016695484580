export const cartItemsSplit = (items) => {
  const { inCompleteItems, completeItems, catalogItems } = items.reduce(
    (acc, curr) => {
      if (curr.item_type === 'list') {
        if (curr.item_name === '' || curr.quantity === '') {
          acc.inCompleteItems.push(curr);
        } else {
          acc.completeItems.push(curr);
        }
      } else {
        acc.catalogItems.push(curr);
      }
      return acc;
    },
    { inCompleteItems: [], completeItems: [], catalogItems: [] }
  );

  return { inCompleteItems, completeItems, catalogItems };
};
