import React from 'react';
import { DISCOUNT_IMAGE_TAG } from '../utils';
import classNames from 'classnames';

export function OverlayItemsAligner({
  coordinates,
  isMobile = false,
  children = null,
  customStyles = {},
  customClass = '',
  config = undefined,
}) {
  function getHorizontalPosition() {
    if (config?.discountStyle === 'discountOverlay') {
      switch (config?.discountTag) {
        case DISCOUNT_IMAGE_TAG.CIRCLE:
          return isMobile ? 4 : 6;
        case DISCOUNT_IMAGE_TAG.REVERSE_ARCH:
          return isMobile ? 6 : 8;
        case DISCOUNT_IMAGE_TAG.SQUARE:
          return 0;
        default:
          return isMobile ? 6 : 10;
      }
    }
    return isMobile ? 8 : 10;
  }
  function getVerticalPosition() {
    if (config?.discountStyle === 'discountOverlay') {
      switch (config?.discountTag) {
        case DISCOUNT_IMAGE_TAG.CIRCLE:
          return isMobile ? 4 : 6;
        case DISCOUNT_IMAGE_TAG.REVERSE_ARCH:
          return 0;
        case DISCOUNT_IMAGE_TAG.SQUARE:
          return 0;
        default:
          return isMobile ? 6 : 10;
      }
    }
    return isMobile ? 8 : 10;
  }

  const style: React.CSSProperties = {
    [coordinates.x]: getHorizontalPosition(),
    [coordinates.y]: getVerticalPosition(),
    ...customStyles,
  };

  return (
    <div className={classNames('tw-absolute tw-z-[1]', customClass)} style={style}>
      {children}
    </div>
  );
}
