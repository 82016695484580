import { IS_SERVER } from './checkRenderEnv';

// function to remove all anchor tags from string
export function removeHTMLTagsFromString(str) {
  if (IS_SERVER) {
    return str;
  }
  const tmp = document.createElement('div');
  // add string to created div as html
  tmp.innerHTML = str;
  // get all anchor tags added to created element
  const anchor_tag_collection = tmp.getElementsByTagName('a');
  // iterate over all the tags and remove them from the HTML collection
  if (anchor_tag_collection.length) {
    // convert HTML collection to array
    Array.from(anchor_tag_collection).forEach(function (tag) {
      // remove the tags
      if (tag) {
        tag.parentNode.removeChild(tag);
      }
    });
  }
  return tmp.textContent || tmp.innerText || '';
}
