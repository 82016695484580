import {
  SAVE_PHONE_NUMBER,
  GENERATE_OTP,
  VERIFY_OTP,
  AUTHENTICATE_USER,
  SAVE_USER_DETAILS,
  FETCH_ADDRESS_LIST,
  SAVE_ADDRESS_LIST,
  SAVE_ADDRESS,
  SAVE_SELECTED_ADDRESS,
  REMOVE_SELECTED_ADDRESS,
  AUTH_TRUECALLER_DETAILS,
  GENERATE_OTP_FOR_EMAIL,
  VERIFY_OTP_FOR_EMAIL,
  SAVE_EMAIL_ID,
  LOGIN_WITH_GMAIL,
} from '../actionTypes';

export const savePhone = (data, router) => {
  return {
    type: SAVE_PHONE_NUMBER,
    data,
    router,
  };
};

export const saveEmail = (data, router) => {
  return {
    type: SAVE_EMAIL_ID,
    data,
    router,
  };
};

export const generateOTP = (data, router, mode, queryParams, callback) => {
  return {
    type: GENERATE_OTP,
    data,
    router,
    mode,
    queryParams,
    callback,
  };
};

export const verifyOTP = (
  data,
  router,
  redirection = false,
  callback,
  isLoginAttempt = true,
  useOldOTPService = true
) => {
  return {
    type: VERIFY_OTP,
    data,
    router,
    redirection,
    callback,
    isLoginAttempt,
    useOldOTPService,
  };
};

export const generateOTPforEmail = (data, router, mode, queryParams, callback) => {
  return {
    type: GENERATE_OTP_FOR_EMAIL,
    data,
    router,
    mode,
    queryParams,
    callback,
  };
};

export const verifyOTPforEmail = (
  data,
  router,
  redirection = false,
  callback,
  isLoginAttempt = true
) => {
  return {
    type: VERIFY_OTP_FOR_EMAIL,
    data,
    router,
    redirection,
    callback,
    isLoginAttempt,
  };
};

export const loginWithGmail = (data, router, redirection = false, callback) => {
  return {
    type: LOGIN_WITH_GMAIL,
    data,
    router,
    redirection,
    callback,
  };
};

export const authenticateUser = (data) => {
  return {
    type: AUTHENTICATE_USER,
    data,
  };
};

// saving user details
export const saveUserDetails = (data) => {
  return {
    type: SAVE_USER_DETAILS,
    data,
  };
};

// fetching address list
export const fetchAddressList = (data) => {
  return {
    type: FETCH_ADDRESS_LIST,
    data,
  };
};

// for saving fetch address list
export const saveAddressList = (data) => {
  return {
    type: SAVE_ADDRESS_LIST,
    data,
  };
};

// for saving a user address
export const saveAddress = (data, callback) => {
  return {
    type: SAVE_ADDRESS,
    data,
    callback,
  };
};

export const saveSelectedAddress = (data) => {
  return {
    type: SAVE_SELECTED_ADDRESS,
    data,
  };
};

export const removeSelectedAddress = (data) => {
  return {
    type: REMOVE_SELECTED_ADDRESS,
    data,
  };
};

export const authTruecallerDetails = (data) => {
  return {
    type: AUTH_TRUECALLER_DETAILS,
    data,
  };
};
