import { isMobileDevice } from '@/components/WidgetMaker/WidgetDnD/isMobileDevice';
import { useSSRSelector } from 'src/redux';
import { IS_SERVER } from 'src/utils/checkRenderEnv';
import { isMobileByUA } from 'src/utils/operatingSystem';

// interface IProps {
//   customDeviceCheck?: (...args) => void;
// }

interface IDeviceType {
  deviceType: 'mobile' | 'desktop';
  isMobile: boolean;
}

export function useGetDeviceType(props = undefined): IDeviceType {
  // eslint-disable-next-line
  // const { customDeviceCheck } = (props || {}) as IProps;
  const userAgent = useSSRSelector((state) => state.commonReducer.userAgent);
  let isInitialRequestByMobile = isMobileDevice();
  if (IS_SERVER && userAgent) {
    isInitialRequestByMobile = isMobileByUA({
      skipServerCheck: true,
      uAgent: userAgent,
    });
  } else {
    isInitialRequestByMobile = isMobileDevice();
  }
  const isMobile = isInitialRequestByMobile;
  return { deviceType: isMobile ? 'mobile' : 'desktop', isMobile };
}
