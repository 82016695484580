import { getDefaultConfig } from 'src/components/WidgetMaker/utils/common';
import { DESCRIPTION_POSITION } from '../FeaturedListPresentation/FeaturedListPresentationConfig';
import { IMAGE_WIDTH_DATA } from '../../GlobalStyle/globalSettingConstant';
import { HEADING_ALIGNMENT } from '../MultiColumn/MultiColumnStaticValues';
import {
  ASPECT_RATIO_MAP,
  TEXT_CASE,
  TEXT_THICKNESS,
} from '../ListPresentation/ListPresentationConfig';
import { PRODUCT_APPEARANCE_DATA } from '../../GlobalStyle/globalSettingConstant';
import { ADMIN_WIDGETS } from 'src/constants/widgets';

const getCardShare = (text, spiteDetails) => (
  <div className="tw-flex tw-items-center tw-gap-[10px]">
    {spiteDetails && <div className={`wb-sprite ${spiteDetails}`}></div>}
    <div>{text}</div>
  </div>
);

export const REEL_EMPTY_DATA = {
  layout1: {},
  layout2: {},
  layout3: {},
  layout4: {},
};

export const HOVER_EFFECT_DROPDOWN = [
  {
    text: 'Expand',
    value: 'zoomInCard',
  },
  {
    text: 'Zoom in',
    value: 'zoomIn',
  },
  {
    text: 'None',
    value: 'none',
  },
];

const TEXT_SIZE_DROPDOWN = [
  {
    text: 'Extra small (12px)',
    value: 'extraSmall',
    itemLevelClass: { fontSize: '12px !important' },
  },
  {
    text: 'Small (13px)',
    value: 'small',
    itemLevelClass: { fontSize: '13px !important' },
  },
  {
    text: 'Regular (14px)',
    value: 'regular',
    itemLevelClass: { fontSize: '14px !important' },
  },
  {
    text: 'Medium (16px)',
    value: 'medium',
    itemLevelClass: { fontSize: '16px !important' },
  },
  {
    text: 'Large (18px)',
    value: 'large',
    itemLevelClass: { fontSize: '18px !important' },
  },
  {
    text: 'Extra Large (20px)',
    value: 'extraLarge',
    itemLevelClass: { fontSize: '20px !important' },
  },
];

const CARD_SHAPE_DROPDOWN = [
  {
    value: 'default',
    renderData: () => getCardShare('Reels standard (9:16)', ''),
  },
  {
    value: 'portraitTall',
    renderData: () => getCardShare('Portrait tall (2:3)', 'ic-PortraitTall'),
  },
  {
    value: 'portraitShort',
    renderData: () => getCardShare('Portrait short (3:4)', 'ic-PortraitShort'),
  },
];

export const FEATURED_REELS_DEFAULT_DATA = {
  reelUrl: '',
  thumbnail: '',
  mappedProduct: [],
  description: '',
};

// Default data for layout and cards
export const FEATURED_REELS_PRESENTATION_CONFIG = {
  layoutName: 'layout2',
  viewLabel: 'Reels',
  widgetName: ADMIN_WIDGETS.FEATURED_REELS.type,
  layoutType: 'carousel',
  heading: '<h2>Shop through videos</h2>',
  descriptionPosition: 'below',
  textAlign: 'center',
  headingCharCount: 0,
  instagramAccount: '',
  description: '',
  descriptionCharCount: 0,
  type: 'featuredReels',
  colors: {
    selectedColorSchemeIndex: 0,
    colorSchemes: [
      {
        background: '#ffffff',
        text: '#000000',
        isDefault: true,
      },
      {
        background: '#000000',
        text: '#ffffff',
        isDefault: false,
      },
    ],
  },
  contentItems: {
    isAllSelected: false,
    type: 'featuredReels',
    listItem: [],
  },
  appearance: 'standard',
  imageWidth: 'fullWidth',
  backgroundColor: '#FFFFFF',
  borderColor: '#E2E4E7',
  numColumnsDesktop: 4,
  numColumnsMobile: 2,
  mobilePaddingY: 40,
  paddingY: 40,
  marginTop: 0,
  marginBottom: 0,
};

export const DEFAULT_FEATURED_REEL_CARD_CONFIG = {
  cardShape: 'portraitTall',
  aspectRatio: ASPECT_RATIO_MAP.portraitTall,
  hoverEffect: 'zoomIn',
  textAlign: 'left',
  productNameSize: 'regular',
  productPriceSize: 'extraSmall',
  productNameSizeMobile: 'small',
  productPriceSizeMobile: 'extraSmall',
  productNameTextCase: 'none',
  productPriceTextCase: 'none',
  productNameColor: '#111C36',
  productPriceColor: '#111C36',
  productNameThincness: 'medium',
  productPriceThincness: 'regular',
  descriptionTextAlign: 'left',
  descriptionSize: 'regular',
  descriptionSizeMobile: 'small',
  descriptionColor: '#111C36',
  descriptionThicness: 'medium',
  descriptionTextCase: 'none',
  hoverType: 'zoomIn',
  reelDesktopBehaviour: 'autoPlayAll',
  mobileEnlargeAndFocus: false,
  reelsData: [
    {
      ...FEATURED_REELS_DEFAULT_DATA,
    },
    {
      ...FEATURED_REELS_DEFAULT_DATA,
    },
    {
      ...FEATURED_REELS_DEFAULT_DATA,
    },
    {
      ...FEATURED_REELS_DEFAULT_DATA,
    },
  ],
  buttonConfig: [
    {
      ...getDefaultConfig('bannerButton', '#111C36', '#FFFFFF', 'Shop now'),
    },
  ],
};

export const FeaturedReelsBuilderConfig = {
  sectionContent: {
    sectionHeading: 'Section content',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Heading',
        element: 'inputEditor',
        accessKey: ['heading'],
        charCountKey: 'headingCharCount',
        placeholder: 'Enter title',
        maxLength: 100,
      },
      {
        forType: 'layout',
        componentLabel: 'Description',
        element: 'inputEditor',
        placeholder: 'Enter description',
        charCountKey: 'descriptionCharCount',
        accessKey: ['description'],
        textColor: '#808080',
        maxLength: 100,
      },
      {
        forType: 'layout',
        componentLabel: 'Description position',
        element: 'multiselect',
        accessKey: ['descriptionPosition'],
        componentData: DESCRIPTION_POSITION,
      },
      {
        forType: 'layout',
        componentLabel: 'Heading alignment',
        element: 'multiselect',
        accessKey: ['textAlign'],
        componentData: HEADING_ALIGNMENT,
      },
      {
        forType: 'layout',
        componentLabel: 'Section colour',
        editLabel: 'Edit colour',
        uniqueKey: 'selectionColors',
        element: 'colorsCombination',
        accessKey: ['colors'],
      },
    ],
  },
  layoutAndDesign: {
    sectionHeading: 'Layout & design',
    description: '',
    tooltipContent: '',
    builder: [
      {
        componentLabel: 'Layout/design',
        element: 'reelLayout',
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns - Desktop',
        element: 'slider',
        accessKey: ['numColumnsDesktop'],
        min: 4,
        max: 8,
        suffix: '',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns - Mobile',
        element: 'slider',
        accessKey: ['numColumnsMobile'],
        min: 1,
        max: 2,
        suffix: '',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Product appearance',
        element: 'multiselect',
        accessKey: ['appearance'],
        componentData: PRODUCT_APPEARANCE_DATA,
        renderConditional: true,
        isVisible: (data, list) => list?.layoutName !== 'layout2',
      },
      {
        forType: 'layout',
        componentLabel: 'Reel width',
        element: 'multiselect',
        renderConditional: true,
        isVisible: (data, list) =>
          list?.appearance === 'card' && list?.layoutName !== 'layout2',
        accessKey: ['imageWidth'],
        componentData: IMAGE_WIDTH_DATA,
      },
      {
        element: 'colorDetails',
        componentLabel: 'Colour',
        isVisible: (data) => data?.appearance === 'card',
        conditionalRender: true,
        forType: 'layout',
        colorsData: [
          {
            forType: 'layout',
            text: 'backgroundColor',
            isVisible: (data) => data?.appearance === 'card',
            renderConditional: true,
            displayVal: 'Background colour',
            accessKey: ['backgroundColor'],
            shouldUpdateData: false,
            data: [],
          },
          {
            forType: 'layout',
            text: 'borderColor',
            isVisible: (data) => data?.appearance === 'card',
            renderConditional: true,
            displayVal: 'Border colour',
            accessKey: ['borderColor'],
            shouldUpdateData: false,
            data: [],
          },
        ],
      },
    ],
  },
  productInfo: {
    sectionHeading: 'Product info',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'card',
        componentLabel: 'Text alignment',
        element: 'multiselect',
        accessKey: ['textAlign'],
        componentData: HEADING_ALIGNMENT,
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Product name size - Desktop',
        accessKey: ['productNameSize'],
        dropdownData: TEXT_SIZE_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Product name size - Mobile',
        accessKey: ['productNameSizeMobile'],
        dropdownData: TEXT_SIZE_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Product price size - Desktop',
        accessKey: ['productPriceSize'],
        dropdownData: TEXT_SIZE_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Product price size - Mobile',
        accessKey: ['productPriceSizeMobile'],
        dropdownData: TEXT_SIZE_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
      {
        componentLabel: 'Advanced settings',
        componentSubLabel: 'Customise Text casing, content thickness, colour.',
        element: 'customModal',
        modalHeading: 'Advance settings',
        componentData: [
          {
            forType: 'card',
            componentLabel: 'Product name text case',
            element: 'multiselect',
            accessKey: ['productNameTextCase'],
            componentData: TEXT_CASE,
          },
          {
            forType: 'card',
            componentLabel: 'Product price text case',
            element: 'multiselect',
            accessKey: ['productPriceTextCase'],
            componentData: TEXT_CASE,
          },
          {
            element: 'separator',
          },
          {
            element: 'colorDetails',
            componentLabel: 'Colours',
            forType: 'card',
            colorsData: [
              {
                forType: 'card',
                text: 'productNameColor',
                displayVal: 'Product name',
                accessKey: ['productNameColor'],
                shouldUpdateData: false,
                data: [],
              },
              {
                forType: 'card',
                text: 'productPriceColor',
                displayVal: 'Product price',
                accessKey: ['productPriceColor'],
                shouldUpdateData: false,
                data: [],
              },
            ],
          },
          {
            element: 'separator',
          },
          {
            forType: 'card',
            element: 'dropdown',
            componentLabel: 'Product name thickness',
            accessKey: ['productNameThincness'],
            dropdownData: TEXT_THICKNESS,
            customChildRender: false,
            placeholder: '',
          },
          {
            forType: 'card',
            element: 'dropdown',
            componentLabel: 'Product price thickness',
            accessKey: ['productPriceThincness'],
            dropdownData: TEXT_THICKNESS,
            customChildRender: false,
            placeholder: '',
          },
        ],
      },
    ],
  },
  descriptionInfo: {
    sectionHeading: 'Reel description text',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'card',
        componentLabel: 'Text alignment',
        element: 'multiselect',
        accessKey: ['descriptionTextAlign'],
        componentData: HEADING_ALIGNMENT,
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Description size - Desktop',
        accessKey: ['descriptionSize'],
        dropdownData: TEXT_SIZE_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Description size - Mobile',
        accessKey: ['descriptionSizeMobile'],
        dropdownData: TEXT_SIZE_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
      {
        componentLabel: 'Advanced settings',
        componentSubLabel: 'Customise Text casing, content thickness, colour.',
        element: 'customModal',
        modalHeading: 'Advance settings',
        componentData: [
          {
            forType: 'card',
            componentLabel: 'Description text case',
            element: 'multiselect',
            accessKey: ['descriptionTextCase'],
            componentData: TEXT_CASE,
          },
          {
            element: 'separator',
          },
          {
            element: 'colorDetails',
            componentLabel: 'Colours',
            forType: 'card',
            colorsData: [
              {
                forType: 'card',
                text: 'descriptionColor',
                displayVal: 'Description',
                accessKey: ['descriptionColor'],
                shouldUpdateData: false,
                data: [],
              },
            ],
          },
          {
            element: 'separator',
          },
          {
            forType: 'card',
            element: 'dropdown',
            componentLabel: 'Description thickness',
            accessKey: ['descriptionThicness'],
            dropdownData: TEXT_THICKNESS,
            customChildRender: false,
            placeholder: '',
          },
        ],
      },
    ],
  },
  productShape: {
    sectionHeading: 'Reel display',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Video shape',
        accessKey: ['cardShape'],
        dropdownData: CARD_SHAPE_DROPDOWN,
        customChildRender: true,
        placeholder: '',
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Hover effect',
        accessKey: ['hoverType'],
        dropdownData: HOVER_EFFECT_DROPDOWN,
        dynamicData: true,
        dynamicDataList: (listPresentation) => {
          if (listPresentation?.appearance === 'card') {
            return HOVER_EFFECT_DROPDOWN;
          }
          return HOVER_EFFECT_DROPDOWN.slice(1);
        },
        customChildRender: false,
        placeholder: '',
      },
    ],
  },
  reelBehaviour: {
    sectionHeading: 'Reel behaviour',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'card',
        element: 'radioButton',
        componentLabel: 'Desktop behaviour',
        accessKey: ['reelDesktopBehaviour'],
        componentData: [
          {
            label: 'Autoplay all reels',
            id: 'autoPlayAll',
            value: 'autoPlayAll',
          },
          {
            label: 'Play reel on hover',
            id: 'playOnHover',
            value: 'playOnHover',
          },
          // {
          //   label: 'Autoplay one reel at a time',
          //   id: 'autoplayOne',
          //   value: 'autoplayOne',
          // },
        ],
        // extraContent: 'For mobile autoplay is best optimised for user experience.',
        placeholder: '',
      },
      {
        element: 'renderWithHeading',
        heading: 'Mobile behaviour',
        child: [
          {
            forType: 'card',
            element: 'checkbox',
            componentLabel: 'Enlarge and focus the playing reel',
            accessKey: ['mobileEnlargeAndFocus'],
          },
        ],
      },
    ],
  },
  viewAllButton: {
    sectionHeading: 'Shop button inside reels',
    builder: [
      {
        forType: 'card',
        element: 'button',
        buttonHeading: 'Button',
        subHeading: '',
        showColorCombination: true,
        showDeleteIcon: false,
        showRedirectionField: false,
        keepSameColor: true,
        showOnOffToggle: false,
        accessKey: ['buttonConfig'],
      },
    ],
  },
  sectionSetting: {
    sectionHeading: 'Section settings',
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Desktop padding top and bottom',
        element: 'slider',
        accessKey: ['paddingY'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Mobile padding top and bottom',
        element: 'slider',
        accessKey: ['mobilePaddingY'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Margin top',
        element: 'slider',
        accessKey: ['marginTop'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Margin bottom',
        element: 'slider',
        accessKey: ['marginBottom'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
    ],
  },
  configReset: {
    builder: [
      {
        forType: 'layout',
        element: 'reset',
        heading: 'Reset to defaults',
        subHeading:
          'All settings of this section - layout, design etc. will be reset to default.',
      },
    ],
  },
};

export const INSTA_LAYOUT_SELECTABLES = [
  {
    name: 'layout1',
    heading: 'Showing reels only',
    cdn: '/assets/images/widgetImages/FeaturedReels/1.png',
    // These are the base configs meapped with each layout
    list: {
      layoutName: 'layout1',
      appearance: 'standard',
    },
    card: {},
  },
  {
    name: 'layout2',
    heading: 'Reels with Item details ',
    cdn: '/assets/images/widgetImages/FeaturedReels/2.png',
    // These are the base configs mapped with each layout
    list: {
      layoutName: 'layout2',
      appearance: 'card',
    },
    card: {},
  },
  {
    name: 'layout3',
    heading: 'Reels with Item details ',
    cdn: '/assets/images/widgetImages/FeaturedReels/3.png',
    // These are the base configs mapped with each layout
    list: {
      layoutName: 'layout3',
      appearance: 'standard',
    },
    card: {},
  },
  {
    name: 'layout4',
    heading: 'Reels with brief text',
    cdn: '/assets/images/widgetImages/FeaturedReels/4.png',
    // These are the base configs mapped with each layout
    list: {
      layoutName: 'layout4',
      appearance: 'standard',
    },
    card: {},
  },
];
