import router from 'next/router';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import {
  CardHeaderInner,
  CartHeader,
  CartHeaderBack,
  CartHeaderData,
  CartHeaderText,
} from '../../cart.styles';
import { isPLPRequiredSystem } from 'src/utils/isPLPRequiredSystem';
import { getRoute, PATH } from 'src/utils/routes';
import { specificThemeRender } from 'src/utils/specificThemeRender';
import { getThemeComponent } from 'src/utils/themeImports';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import { BackArrow } from 'src/assets/svgExports/BackArrow';

const PageHeader = ({ isEmptyCart }) => {
  const { storeInfo } = useSelector((state: RootState) => ({
    storeInfo: state.storeReducer.store,
  }));

  return !IS_DESKTOP ? (
    <CartHeader>
      <CartHeaderBack
        onClick={() => {
          if (isPLPRequiredSystem(storeInfo?.theme?.theme_class)) {
            router.push(getRoute(PATH.SHOP, storeInfo?.store_info?.domain));
          } else {
            router.push(getRoute(PATH.PRODUCT, storeInfo?.store_info?.domain));
          }
        }}
      >
        <BackArrow width={16} height={16} />
      </CartHeaderBack>
      <CartHeaderData>
        {!isEmptyCart ? (
          <CardHeaderInner>
            <CartHeaderText>Cart</CartHeaderText>
          </CardHeaderInner>
        ) : null}
      </CartHeaderData>
    </CartHeader>
  ) : (
    specificThemeRender(
      storeInfo?.theme,
      storeInfo?.store_premium_theme,
      storeInfo?.premium
    )?.components?.Header?.map((layout) => {
      return getThemeComponent(layout.sub_type, layout.name, layout);
    })
  );
};

export default PageHeader;
