import { getThemeColor } from 'src/utils/getThemeColor';
import BasicPromoStrip from '../BasicPromoStrip';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import OfferIcon from 'src/assets/svgExports/OfferIcon';
import CartPromoStrip from '../CartPromoStrip';

export const StripOfferIcon = ({ iconSize = null, customClasses = '' }) => {
  const offerIconSize = iconSize || (IS_DESKTOP ? 18 : 14);
  return (
    <span className={customClasses}>
      <OfferIcon height={offerIconSize} width={offerIconSize} />
    </span>
  );
};

export const Tab = ({ text, isActive, onClick, customClasses = '' }) => {
  const themeColor = getThemeColor();
  return (
    <button
      className={`removeGlobalOverride tw-m-0 tw-cursor-pointer tw-border-x-0 tw-border-b-[3px] tw-border-t-0 tw-border-solid tw-bg-transparent tw-px-[12px] tw-pb-[9px] tw-text-[13px]/[20px] ${isActive ? 'tw-font-semibold' : 'tw-font-medium'} tw-transition-colors tw-duration-500 md:tw-pb-[13px] md:tw-text-[14px]/[20px] ${customClasses}`}
      onClick={onClick}
      style={{
        borderColor: isActive ? themeColor : '#ffffff',
        color: isActive ? themeColor : '#000000',
      }}
    >
      {text}
    </button>
  );
};

export const StripIconInfoWrapper = (props) => {
  return (
    <div className={`tw-flex tw-gap-[8px] ${props.customClasses || ''}`}>
      {props.children}
    </div>
  );
};

export const StripContainer = (props) => {
  return (
    <div
      className={`tw-flex tw-flex-col tw-items-start tw-gap-[8px] tw-border-b tw-border-dashed tw-border-[#EAEAEA]  ${props.showHoverEffect ? 'hover:tw-bg-[#F5F5F5]' : ''} md:tw-flex-row md:tw-justify-between md:tw-border-none ${props.customClasses || ''}`}
      style={{
        backgroundColor: props.bgColor,
      }}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};

export const StripCTA = ({
  ctaTextOrIcon = 'Details',
  onClick,
  customClasses = '',
  isDisabled = false,
}) => {
  const themeColor = getThemeColor();
  const handleCTAClick = (e) => {
    e?.stopPropagation();
    onClick();
  };
  return (
    <button
      className={`tw-ml-[20px] tw-w-fit ${isDisabled ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer'} tw-border-none tw-bg-transparent tw-p-0 tw-text-[14px]/[20px] tw-font-semibold md:tw-ml-0 md:tw-text-[16px]/[20px] ${isDisabled ? 'tw-opacity-40' : ''} ${customClasses}`}
      style={{ color: themeColor }}
      onClick={handleCTAClick}
      disabled={isDisabled}
    >
      {ctaTextOrIcon}
    </button>
  );
};

export const PromosContainer = ({
  promos,
  onPromoClick,
  customClasses = '',
  primaryTextClasses = '',
  secondaryTextClasses = '',
  offerStripContainerClasses = '',
  offerStripCtaTextOrIcon = null,
  isStorePromos = true,
}) => {
  return (
    <div className={customClasses || ''}>
      {isStorePromos ? (
        <>
          {promos.map(({ id, description, code }, index) => (
            <BasicPromoStrip
              key={index}
              primaryText={description}
              secondaryText={code}
              ctaTextOrIcon={offerStripCtaTextOrIcon || 'Details'}
              onCtaClick={onPromoClick}
              promoId={id}
              primaryTextClasses={primaryTextClasses}
              secondaryTextClasses={secondaryTextClasses}
              customClasses={'tw-p-[16px] ' + offerStripContainerClasses}
            />
          ))}
        </>
      ) : (
        <>
          {promos.map(({ promo_id, promo_code, description }, index) => (
            <BasicPromoStrip
              key={index}
              primaryText={description}
              secondaryText={promo_code}
              ctaTextOrIcon={offerStripCtaTextOrIcon || 'Details'}
              onCtaClick={onPromoClick}
              promoId={promo_id}
              primaryTextClasses={primaryTextClasses}
              secondaryTextClasses={secondaryTextClasses}
              customClasses={'tw-p-[16px] ' + offerStripContainerClasses}
            />
          ))}
        </>
      )}
    </div>
  );
};

export const CartPromosContainer = ({
  promos,
  customClasses = '',
  onDetailClick = null,
  onApplyClick = null,
  isStorePromos = true,
  cartPayAmount = null,
}) => {
  return (
    !!promos?.length && (
      <div
        className={
          'tw-flex tw-flex-col tw-gap-[20px] tw-transition-all md:tw-gap-[16px]' +
          customClasses
        }
      >
        {isStorePromos ? (
          <>
            {promos.map(({ id, code, description, error_text, discount }, index) => (
              <CartPromoStrip
                key={index}
                primaryText={code}
                secondaryText={description}
                promoId={id}
                onDetailClick={onDetailClick}
                onApplyOrRemoveClick={onApplyClick}
                errorText={error_text}
                savingAmount={discount}
              />
            ))}
          </>
        ) : (
          <>
            {promos.map(
              (
                { promo_id, promo_code, offer_callout, description, min_order_price },
                index
              ) => (
                <CartPromoStrip
                  key={index}
                  primaryText={promo_code || offer_callout}
                  secondaryText={description}
                  promoId={promo_id}
                  onApplyOrRemoveClick={onApplyClick}
                  onDetailClick={onDetailClick}
                  isApplyButtonDisabled={min_order_price > cartPayAmount}
                />
              )
            )}
          </>
        )}
      </div>
    )
  );
};
