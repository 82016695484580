import { checkIfRoutesAreValid } from './404Redirect';
import { PATH } from './routes';

/**
 * @param {import('next').NextPageContext} ctx
 * @returns {{domain: string; isCustomDomain: boolean;}}
 */
export const fetchDomainFromHeaders = (ctx) => {
  let domain = '';
  let isCustomDomain = false;

  if (ctx.req.headers?.domain) {
    domain = ctx.req.headers?.domain;
    isCustomDomain = true;
    checkIfRoutesAreValid(isCustomDomain, ctx);
    return { domain, isCustomDomain };
  }

  /** If request is from 'd-' domain */
  if (ctx.req.headers.host.startsWith('d-')) {
    const isStageEnv =
      ctx.req.headers.host.includes('.stage1.dotnu') ||
      ctx.req.headers.host.includes('.stage2.dotnu');

    let targetIndex = 0;

    if (isStageEnv) {
      targetIndex = ctx.req.headers.host.lastIndexOf('.stage') - 2;
    } else {
      targetIndex = ctx.req.headers.host.lastIndexOf('dot') - 3;
    }

    domain = ctx.req.headers.host.substr(
      ctx.req.headers.host.indexOf('d-') + 2,
      targetIndex
    );
    checkIfRoutesAreValid(isCustomDomain, ctx);
    return { domain, isCustomDomain };
  }
  if (
    /** If request is from showroom related subdomains */
    /** prevew1 is on dashy and preview 2 on app */
    ctx.req.headers.host.includes('showroom.dot') ||
    ctx.req.headers.host.includes('showroom.stage') ||
    ctx.req.headers.host.includes('showroom-preview1.dot') ||
    ctx.req.headers.host.includes('showroom-preview2.dot') ||
    ctx.req.headers.host.includes('showroom-preview1.stage') ||
    ctx.req.headers.host.includes('showroom-preview2.stage') ||
    ctx.req.headers.host.includes('showroom.digitaldukaan.app') ||
    ctx.req.headers.host.includes('localhost')
  ) {
    const urlSplitedArray = ctx.req.url.split('/');
    if (ctx.req.url.includes(PATH.WEBSITE_BUILDER) && ctx.query?.domain) {
      domain = ctx.query.domain;
    } else if (ctx.req.url.includes(PATH.STORE)) {
      domain = urlSplitedArray[urlSplitedArray.length - 1];
    } else {
      domain = urlSplitedArray[1];
    }
  } else {
    /** If request is from custom domain */
    domain = ctx.req.headers.host.split(':')[0];
  }

  /** In case of custom domain */
  if (domain.includes('.')) {
    isCustomDomain = true;
  }
  checkIfRoutesAreValid(isCustomDomain, ctx);

  return {
    domain,
    isCustomDomain,
  };
};
