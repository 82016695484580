import { isMobileDevice } from 'src/components/WidgetMaker/WidgetDnD/isMobileDevice';
import { getButtonVariantsData } from '../Carousel/utils';
import CustomizedButton from 'src/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/Button';
import {
  BUTTON_SIZE_TYPE,
  BUTTON_TYPE,
} from 'src/components/WidgetMaker/utils/buttonConstant';
import classNames from 'classnames';
import { getCurrentClass, getShapeStyles } from '../CardFrameCollection/utils';
import contentAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentAnimationStyles.module.scss';
import {
  getDateStyle,
  getDescriptionStyle,
  getTitleStyles,
} from '../MultiColumn/MultiColumnHelper';
import { useRouter } from 'next/router';
import { getRoute } from 'src/utils/routes';
import { useSSRSelector } from 'src/redux/ssrStore';
import { useRef } from 'react';

const REMOVE_GLOBAL_STYLE = ['circle', 'arch', 'blob'];

export const BlogComponent = (props) => {
  const {
    listPresentationConfig,
    cardFrameConfig,
    oneGridCellGeometry,
    columnData,
    index,
  } = props;
  const { store } = useSSRSelector((state) => ({
    store: state.storeReducer.store,
  }));
  const isMobile = isMobileDevice();
  const router = useRouter();
  const headingRef = useRef(null);
  const {
    showDate,
    showDescription,
    buttonConfig,
    textAlignment,
    imageShape,
    isShow,
    textPosition,
    datePosition,
  } = cardFrameConfig;
  const { appearance, borderColor, backgroundColor, mobileImagePosition, imageWidth } =
    listPresentationConfig;
  const isMobileLeftAlignImage = isMobile && mobileImagePosition === 'left';
  const isFullWidthImage = imageWidth === 'fullWidth';

  function handleClick(index) {
    router.push(getRoute(`/p/blog/${index}`, store?.store_info?.domain));
  }

  function renderImage(data, index) {
    const url = data?.imageDetail?.url;

    const shapeStyleCalculated = getShapeStyles(imageShape, oneGridCellGeometry?.width);
    const shapeStyle = shapeStyleCalculated[0];

    return (
      <div
        className={classNames(
          `tw-relative tw-flex-shrink-0 tw-cursor-pointer tw-overflow-hidden ${textPosition === 'below' && !isMobileLeftAlignImage ? ' tw-mb-[20px] ' : ''} 
          ${
            !REMOVE_GLOBAL_STYLE?.includes(imageShape)
              ? appearance === 'card' && isFullWidthImage && !isMobileLeftAlignImage
                ? 'removeGlobalOverride imgWrapperGlobalStyle globalImgStyleConsumer addTopLeftGlobalRadius addTopRightGlobalRadius'
                : appearance === 'card' && isFullWidthImage && isMobileLeftAlignImage
                  ? 'removeGlobalOverride imgWrapperGlobalStyle globalImgStyleConsumer addTopLeftGlobalRadius addBottomLeftGlobalRadius'
                  : 'imgWrapperGlobalStyle'
              : ''
          }`,
          !isMobileLeftAlignImage ? shapeStyle.className : ''
        )}
        style={{
          width: !isMobileLeftAlignImage
            ? `${oneGridCellGeometry?.width - (appearance === 'card' && !isFullWidthImage ? 30 : 2)}px`
            : '42%',
          height: !isMobileLeftAlignImage
            ? `${oneGridCellGeometry?.height - (appearance === 'card' && !isFullWidthImage ? 30 : 0)}px`
            : buttonConfig?.[0]?.isShow
              ? appearance === 'card'
                ? '203px'
                : '175px'
              : '144px',
          ...(!isMobileLeftAlignImage ? shapeStyle.style || {} : {}),
          justifyContent: textAlignment,
        }}
      >
        <img
          src={url || '/assets/images/richTextWithImage.png'}
          className={classNames(
            'tw-absolute tw-h-[100%] tw-w-[100%] tw-object-cover tw-transition-all tw-duration-500',
            !isMobileLeftAlignImage ? shapeStyle.className : '',
            !isMobile
              ? contentAnimationStyle[getCurrentClass(cardFrameConfig?.hoverType)]
              : '',
            appearance === 'card' && isFullWidthImage && !isMobileLeftAlignImage
              ? 'removeGlobalOverride imgWrapperGlobalStyle globalImgStyleConsumer addTopLeftGlobalRadius addTopRightGlobalRadius'
              : appearance === 'card' && isFullWidthImage && isMobileLeftAlignImage
                ? 'removeGlobalOverride imgWrapperGlobalStyle globalImgStyleConsumer addTopLeftGlobalRadius addBottomLeftGlobalRadius'
                : ''
          )}
        />
        {textPosition !== 'below' ? (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
              background:
                'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)',
              transition: 'transform 0.5s ease',
            }}
            className={
              !isMobile
                ? contentAnimationStyle[getCurrentClass(cardFrameConfig?.hoverType)]
                : ''
            }
          ></div>
        ) : null}
      </div>
    );
  }

  function renderIconAndImage(columnData, index) {
    return isShow && renderImage(columnData, index);
  }

  function renderCustomHtml(content) {
    return <span>{content}</span>;
  }

  function renderTitleAndDescription(columnData) {
    const { title, description, date } = columnData;
    const titleStyle = getTitleStyles(cardFrameConfig, isMobile);
    const descriptionStyle = getDescriptionStyle(cardFrameConfig, isMobile);
    const dateStyle = getDateStyle(cardFrameConfig, isMobile);
    return (
      <div
        className={`tw-flex tw-flex-col ${isMobile ? 'tw-gap-[6px]' : 'tw-gap-[6px]'}`}
      >
        {showDate && datePosition === 'top' && (
          <div
            style={{
              color: cardFrameConfig?.dateColor,
              ...dateStyle?.style,
              textAlign: textAlignment,
            }}
          >
            {renderCustomHtml(date)}
          </div>
        )}
        <div
          className="tw-line-clamp-2 !tw-leading-[1.4]"
          style={{
            color: cardFrameConfig?.titleColor || '',
            ...titleStyle?.style,
            textAlign: textAlignment,
          }}
        >
          {renderCustomHtml(title)}
        </div>
        {showDescription && (
          <div
            style={{
              color: cardFrameConfig?.descriptionColor,
              ...descriptionStyle?.style,
              textAlign: textAlignment,
            }}
            className={`tw-line-clamp-2 !tw-leading-[1.4]`}
          >
            {renderCustomHtml(description)}
          </div>
        )}
        {showDate && datePosition === 'bottom' && (
          <div
            style={{
              color: cardFrameConfig?.dateColor,
              ...dateStyle?.style,
              textAlign: textAlignment,
            }}
          >
            {renderCustomHtml(date)}
          </div>
        )}
      </div>
    );
  }

  function renderButton(columnData, index) {
    return (
      <div className={`tw-flex`}>
        {buttonConfig?.map((buttonData, btnIndex) => {
          const buttonVariant = getButtonVariantsData(buttonData, false);
          const selectedButtonType = buttonVariant[buttonData.type];
          return buttonData.isShow ? (
            <CustomizedButton
              key={btnIndex}
              size={BUTTON_SIZE_TYPE.LARGE}
              btnType={buttonData.type}
              buttonData={buttonData}
              selectedButtonData={selectedButtonType}
              variant={selectedButtonType.variant}
              onClick={() => handleClick(index)}
              className={`!tw-py-[12px] tw-text-[14px]  ${buttonData.type !== BUTTON_TYPE.TEXTUAL ? '!tw-px-[24px]' : isMobile && isMobileLeftAlignImage ? ' !tw-py-[0]' : ' !tw-pt-[0px]'} 
              ${buttonData.type === BUTTON_TYPE.TEXTUAL ? (textAlignment === 'left' ? '!tw-justify-start' : '!tw-justify-center') : ''}
              ${buttonData.type !== BUTTON_TYPE.TEXTUAL ? 'tw-w-auto !tw-min-w-[100px] !tw-max-w-[250px]' : ''}
              `}
            >
              {buttonData?.text}
            </CustomizedButton>
          ) : null;
        })}
      </div>
    );
  }

  return (
    <div
      onClick={() => handleClick(index)}
      className={`tw-flex tw-w-[100%] tw-cursor-pointer`}
    >
      <div
        style={{
          ...(appearance === 'card'
            ? {
                border: `1px solid ${borderColor}`,
                background: backgroundColor,
                padding: !isFullWidthImage ? (isMobile ? '14px' : '14px') : '',
              }
            : {}),
          width: oneGridCellGeometry?.width,
          display: isMobileLeftAlignImage ? 'flex' : '',
        }}
        className={`tw-relative tw-flex-col
          ${appearance === 'card' ? 'imgWrapperGlobalStyle' : ''}
          ${isMobile && mobileImagePosition === 'left' ? `!tw-flex-row ${appearance === 'card' && isFullWidthImage ? 'tw-gap-[8px]' : 'tw-gap-[20px]'}` : ''}
          `}
      >
        {renderIconAndImage(columnData, index)}
        <div
          ref={headingRef}
          style={{
            top:
              oneGridCellGeometry?.height -
              (headingRef.current?.clientHeight +
                20 +
                (appearance === 'card' && textPosition !== 'below' ? 20 : 0)),
            ...(appearance === 'card' && isFullWidthImage
              ? { padding: isMobile ? '0 14px 14px 14px' : '0 14px 14px 14px' }
              : {}),
          }}
          className={`tw-flex tw-flex-col tw-gap-[20px] ${isMobileLeftAlignImage ? 'tw-h-[100%] tw-w-[58%] tw-justify-center' : ''}  ${textPosition !== 'below' && !isMobileLeftAlignImage ? 'tw-absolute tw-px-[16px] tw-pt-[20px]' : ''}
           ${isMobileLeftAlignImage && isMobile && appearance === 'card' ? ' !tw-pt-[14px]' : ''}
          `}
        >
          {renderTitleAndDescription(columnData)}
          {buttonConfig?.[0]?.isShow && renderButton(columnData, index)}
        </div>
      </div>
    </div>
  );
};

export function BlogHeroPage(props) {
  const { cardFrameConfig, blogData, index, listPresentationConfig } = props;
  const isMobile = isMobileDevice();
  const router = useRouter();
  const { store } = useSSRSelector((state) => ({
    store: state.storeReducer.store,
  }));

  const columnData = blogData?.[0] || {};
  const { appearance } = listPresentationConfig;
  const {
    showDate,
    showDescription,
    buttonConfig,
    textAlignment,
    isShow,
    textPosition,
    datePosition,
  } = cardFrameConfig;
  function handleClick(index) {
    router.push(getRoute(`/p/blog/0`, store?.store_info?.domain));
  }

  function renderImage(data) {
    const url = data?.imageDetail?.url;
    return (
      <div
        onClick={() => handleClick(index)}
        className={classNames(
          `tw-relative tw-flex-shrink-0 tw-cursor-pointer tw-overflow-hidden`
        )}
        style={{
          width: '100%',
          height: '360px',
        }}
      >
        <img
          src={url || '/assets/images/richTextWithImage.png'}
          className={classNames(
            'tw-absolute tw-h-[100%] tw-w-[100%] tw-object-cover tw-transition-all tw-duration-500'
          )}
        />
      </div>
    );
  }

  function renderCustomHtml(content) {
    return <span>{content}</span>;
  }

  function renderTitleAndDescription(columnData) {
    const { title, description, date } = columnData;
    const titleStyle = getTitleStyles(cardFrameConfig, isMobile);
    const descriptionStyle = getDescriptionStyle(cardFrameConfig, isMobile);
    const dateStyle = getDateStyle(cardFrameConfig, isMobile);
    return (
      <div
        className={`tw-flex tw-flex-col ${isMobile ? 'tw-gap-[6px]' : 'tw-gap-[6px]'}`}
      >
        {showDate && datePosition === 'top' && (
          <div
            style={{
              color: '#555D70',
              ...dateStyle?.style,
              textAlign: textAlignment,
            }}
          >
            {renderCustomHtml(date)}
          </div>
        )}
        <div
          className="tw-line-clamp-2 !tw-text-[32px] !tw-leading-[1.4]"
          style={{
            color: '#111C36',
            ...titleStyle?.style,
            textAlign: textAlignment,
          }}
        >
          {renderCustomHtml(title)}
        </div>
        {showDescription && (
          <div
            style={{
              color: '#555D70',
              ...descriptionStyle?.style,
              textAlign: textAlignment,
            }}
            className={`${textPosition === 'below' ? 'tw-line-clamp-3' : 'tw-line-clamp-2'} !tw-leading-[1.4]`}
          >
            {renderCustomHtml(description)}
          </div>
        )}
        {showDate && datePosition === 'bottom' && (
          <div
            style={{
              color: '#555D70',
              ...dateStyle?.style,
              textAlign: textAlignment,
            }}
          >
            {renderCustomHtml(date)}
          </div>
        )}
      </div>
    );
  }

  function renderButton(columnData, index) {
    return (
      <div className={`tw-flex ${appearance !== 'card' ? 'tw-mb-[20px]' : ''}`}>
        {buttonConfig?.map((buttonData, btnIndex) => {
          const buttonVariant = getButtonVariantsData(buttonData, false);
          const selectedButtonType = buttonVariant[buttonData.type];
          return buttonData.isShow ? (
            <CustomizedButton
              key={btnIndex}
              size={BUTTON_SIZE_TYPE.LARGE}
              btnType={buttonData.type}
              buttonData={buttonData}
              selectedButtonData={selectedButtonType}
              variant={selectedButtonType.variant}
              onClick={() => handleClick(index)}
              className={`!tw-py-[12px] tw-text-[14px]
              ${buttonData.type === BUTTON_TYPE.TEXTUAL ? (textAlignment === 'center' ? '!tw-justify-center' : '!tw-justify-start') : ''}
              ${buttonData.type !== BUTTON_TYPE.TEXTUAL ? ' !tw-min-w-[100px] !tw-max-w-[150px]' : ''}
              `}
            >
              {buttonData?.text}
            </CustomizedButton>
          ) : null;
        })}
      </div>
    );
  }

  return (
    <div className="tw-mb-[60px] tw-flex tw-w-[100%] tw-items-center tw-gap-[48px]">
      <div className="tw-w-[60%]">{isShow && renderImage(columnData)}</div>
      <div className={`tw-flex tw-w-[40%] tw-flex-col tw-gap-[20px]`}>
        {renderTitleAndDescription(columnData)}
        {buttonConfig?.[0]?.isShow && renderButton(columnData, index)}
      </div>
    </div>
  );
}
