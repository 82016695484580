import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { RightArrowIcon } from './svg';
import { merge } from 'lodash';
import { hexToRgba } from 'src/components/DesignSystem/ModernTheme/utils/common';
import { INITIAL_SCROLL_PERCENTAGE } from '../../ListPresentation/utils';
import { useGetElementRects } from '../../ListPresentation/hooks/useGetElementRects';

const GAP = 36;

export default function CarouselIndicator({
  config = {} as any,
  isVisible = false,
  carouselIndicatorConfigs,
  onNextArrowClick,
  onPrevArrowClick,
  itemContainerLayoutChange,
  arrowPosition,
  showArrowUI,
  isMobile,
  containerHorizontalPadding,
}) {
  const {
    direction,
    scrollPercentage = 0,
    overflowSectionCount = 0,
    currentOverflowSectionIndex = 0,
  } = carouselIndicatorConfigs || {};
  const {
    progressIndicator,
    arrowShape,
    progressIndicatorColor = '#111C36',
  } = config || {};
  const layoutWrapperRef = useRef(null);
  const [layoutWrapperLayoutChange] = useGetElementRects({
    elementRef: layoutWrapperRef,
  });

  const { LeftArrow, RightArrow } = useGetChevronComponent({
    type: arrowShape,
    onNextArrowClick,
    onPrevArrowClick,
    scrollPercentage: scrollPercentage,
    isMobile,
    colorConfig: {
      arrowColor: config.arrowColor,
      shapeColor: config.shapeColor,
    },
  });

  if (!isVisible) {
    return null;
  }
  const arrowPositionKT = {
    isTop: !isMobile ? arrowPosition === 'top' : false,
    isCenter: !isMobile ? arrowPosition === 'center' : false,
    isBottom: !isMobile ? arrowPosition === 'bottom' : true,
  };

  function _RenderSlider() {
    switch (progressIndicator) {
      case 'fullWidthBar': {
        return (
          <SliderComponent
            width={'100%'}
            scrollPercentage={scrollPercentage}
            progressIndicatorColor={progressIndicatorColor}
          />
        );
      }
      case 'shortProgressBar':
        return (
          <SliderComponent
            height="5px"
            width={'132px'}
            scrollPercentage={scrollPercentage}
            progressIndicatorColor={progressIndicatorColor}
          />
        );
      case 'dashAndDots':
        return (
          <DotIndicatorComponent
            overflowSectionCount={overflowSectionCount}
            currentOverflowSectionIndex={currentOverflowSectionIndex}
            progressIndicatorColor={progressIndicatorColor}
            direction={direction}
          />
        );
      default:
        return null;
    }
  }

  const Slider = _RenderSlider();
  const GAP_APPLIED = Slider || arrowPositionKT.isBottom ? 36 : 0;

  function _RenderArrows() {
    const showArrow = showArrowUI && !arrowPositionKT.isTop;
    const arrowVerticalPositionOffset =
      layoutWrapperLayoutChange.elementRect?.clientHeight +
      (!Slider ? -GAP : 0) +
      itemContainerLayoutChange?.elementRect?.clientHeight / 2;

    return {
      LeftArrow: (
        <LeftArrow
          isVisible={showArrow}
          className={
            arrowPositionKT.isCenter
              ? 'tw-absolute tw-left-[-20px] tw-z-10 tw-translate-y-[-50%]'
              : ''
          }
          style={{
            bottom: arrowVerticalPositionOffset,
          }}
        />
      ),
      RightArrow: (
        <RightArrow
          isVisible={showArrow}
          className={
            arrowPositionKT.isCenter
              ? 'tw-absolute tw-right-[-20px] tw-z-10 tw-translate-y-[-50%]'
              : ''
          }
          style={{
            bottom: arrowVerticalPositionOffset,
          }}
        />
      ),
    };
  }

  const Arrows = _RenderArrows();

  return (
    <div
      ref={layoutWrapperRef}
      className="tw-relative tw-mx-auto tw-flex tw-w-[100%] tw-select-none tw-items-center tw-justify-center tw-gap-[20px]"
      style={{
        marginTop: `${GAP_APPLIED}px`,
        padding: `0px ${containerHorizontalPadding}px`,
      }}
    >
      {Arrows.LeftArrow}
      {Slider}
      {Arrows.RightArrow}
    </div>
  );
}

function SliderComponent({
  height = '2px',
  width,
  scrollPercentage,
  progressIndicatorColor,
}) {
  return (
    <div
      className="tw-relative tw-rounded-[6px] tw-bg-[#111C361F]"
      style={{
        width,
        height,
        transform: `translateY(${-height / 2})`,
      }}
    >
      <div
        className="tw-absolute tw-h-[100%] tw-rounded-[6px]"
        style={{
          width: `${scrollPercentage}%`,
          background: progressIndicatorColor,
        }}
      />
    </div>
  );
}

const CONTAINER_WIDTH = 120;
export function DotIndicatorComponent({
  overflowSectionCount = 0,
  currentOverflowSectionIndex,
  progressIndicatorColor,
  direction,
  containerWidth = CONTAINER_WIDTH,
  styles = {} as React.CSSProperties,
  dimensions = {
    width: 'tw-w-[5px]',
    widthOfSelected: 'tw-w-[30px]',
    height: 'tw-h-[5px]',
  },
}) {
  const dotsContainerRef = useRef(null);
  const dotsRef = useRef([]);

  useEffect(() => {
    const selectedIndex = currentOverflowSectionIndex - 1;
    const selectedDotElement = dotsRef.current?.[selectedIndex];
    if (dotsContainerRef.current) {
      dotsContainerRef.current.scrollTo({
        left:
          dotsContainerRef.current?.scrollLeft +
          selectedDotElement?.getBoundingClientRect().left -
          dotsContainerRef.current?.getBoundingClientRect().left +
          (direction === 'to-right' ? -70 : -45),
        top: 0,
      });
    }
  }, [currentOverflowSectionIndex, overflowSectionCount, direction]);

  if (!overflowSectionCount) {
    return null;
  }
  return (
    <div
      style={{
        width: `${containerWidth}px`,
        ...styles,
        // maskImage: 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 10%)',
      }}
      className="tw-flex tw-justify-center tw-gap-[6px]"
    >
      <div
        ref={dotsContainerRef}
        className="no-scrollbar tw-flex tw-items-center tw-gap-[6px] tw-overflow-x-auto"
      >
        {Array.from(Array(overflowSectionCount).keys()).map((_, index) => {
          const isSelected = currentOverflowSectionIndex === index + 1;
          return (
            <div
              ref={(node) => (dotsRef.current[index] = node)}
              key={index}
              className={classNames(
                'tw-h-[5px] tw-shrink-0 tw-rounded-[3px]  tw-transition-all',
                dimensions.height,
                !isSelected
                  ? dimensions.width
                  : dimensions.widthOfSelected || dimensions.width,
                !isSelected && 'tw-bg-[#111C36]/[0.25]'
              )}
              style={
                isSelected
                  ? {
                      background: progressIndicatorColor,
                    }
                  : {}
              }
            />
          );
        })}
      </div>
    </div>
  );
}

export function useGetChevronComponent({
  type,
  onNextArrowClick,
  onPrevArrowClick,
  scrollPercentage,
  isMobile,
  colorConfig,
  dimensions = {
    width: {
      mobile: '28px',
      desktop: '36px',
    },
    height: {
      mobile: '28px',
      desktop: '36px',
    },
  },
}) {
  const styles = (() => {
    const styles = {
      border: '',
      borderRadius: '0',
      background: colorConfig?.shapeColor || '',
    };
    switch (type) {
      case 'circular':
        styles.border = '0.75px solid #111C361F';
        styles.borderRadius = '20';
        break;
      case 'rounded':
        styles.border = '0.75px solid #111C361F';
        styles.borderRadius = '5';
        break;
      case 'square':
        styles.border = '0.75px solid #111C361F';
        break;
      default:
        styles.background = '';
        break;
    }
    return styles;
  })();

  const removeBorder = type === 'onlyArrow';
  const iconProps = {
    width: dimensions.width[isMobile ? 'mobile' : 'desktop'],
    height: dimensions.height[isMobile ? 'mobile' : 'desktop'],
    removeBorder,
    bgColor: styles.background,
    borderRadius: styles.borderRadius,
  };

  return {
    LeftArrow: ({ isVisible = true, ...props }) =>
      isVisible && (
        <div
          {...props}
          className={classNames(
            // isMobile ? 'tw-h-[28px]' : 'tw-h-[36px]',
            'tw-pointer-events-auto',
            scrollPercentage ? 'tw-cursor-pointer' : ' ',
            props.className
          )}
          style={merge(
            props.style,
            {
              width: dimensions.width[isMobile ? 'mobile' : 'desktop'],
              height: dimensions.height[isMobile ? 'mobile' : 'desktop'],
            },
            !removeBorder
              ? {
                  boxShadow: '0px 0px 1.5px 0px #111C3633',
                  borderRadius: styles.borderRadius + 'px',
                }
              : {}
          )}
          onClick={onPrevArrowClick}
        >
          <RightArrowIcon
            className="tw-rotate-180"
            {...iconProps}
            arrowColor={
              scrollPercentage <= INITIAL_SCROLL_PERCENTAGE
                ? hexToRgba(colorConfig.arrowColor, 0.5)
                : colorConfig.arrowColor
            }
          />
        </div>
      ),
    RightArrow: ({ isVisible = true, ...props }) =>
      isVisible && (
        <div
          {...props}
          className={classNames(
            'tw-pointer-events-auto',
            // isMobile ? 'tw-h-[28px]' : 'tw-h-[36px]',
            scrollPercentage >= 100 ? '' : 'tw-cursor-pointer ',
            props.className
          )}
          style={merge(
            props.style,
            props.style,
            {
              width: dimensions.width[isMobile ? 'mobile' : 'desktop'],
              height: dimensions.height[isMobile ? 'mobile' : 'desktop'],
            },
            !removeBorder
              ? {
                  boxShadow: '0px 0px 1.5px 0px #111C3633',
                  borderRadius: styles.borderRadius + 'px',
                }
              : {}
          )}
          onClick={onNextArrowClick}
        >
          <RightArrowIcon
            {...iconProps}
            arrowColor={
              scrollPercentage >= 100
                ? hexToRgba(colorConfig.arrowColor, 0.5)
                : colorConfig.arrowColor
            }
          />
        </div>
      ),
  };
}
